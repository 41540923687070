import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { AutocompleteItem } from '../../../model/autocomplete'
import { RHFAutocomplete } from '../../../components/shared/rhf/RHFAutocomplete'
import { AutocompleteDto, DriverDto, UserDtoRoleEnum } from '../../../api'
import autocompleteService from '../../../services/AutocompleteService'
import { ConardLabel } from '../../../components/transition/DriverFields'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../../components/ConardButton'
import { useHistory, useParams } from 'react-router-dom'
import driverService from '../../../services/DriverService'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { isAutocompleteItem } from '../../../utils/forms'
import carrierService from '../../../services/CarrierService'
import { useConardAuth } from '../../../hooks/useConardAuth'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
      maxWidth: '600px',
    },
    button: {
      borderRadius: 12,
      height: '3rem',
      fontWeight: 600,
      marginTop: '1rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    backButton: {
      borderRadius: 12,
      height: '3rem',
      fontWeight: 600,
      marginTop: '1rem',
    },
  })
)

type CreateDriverValue = {
  driver: AutocompleteItem | string
  frontLicensePlate: AutocompleteItem | string
  rearLicensePlate: AutocompleteItem | string
  carrierName: string
}

const initialValues: CreateDriverValue = {
  driver: '',
  frontLicensePlate: '',
  rearLicensePlate: '',
  carrierName: '',
}

interface CreateDriverPageProps {
  carrierId: number
}

export const CreateDriverPage: FC<CreateDriverPageProps> = ({ carrierId }) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    driver: yup.mixed().test('is-driver', t('pages.carriers.validations.driver'), function (value) {
      if (!value) {
        return this.createError({ message: t('pages.carriers.validations.driver') })
      }
      return true
    }),
  })

  const formMethods = useForm<CreateDriverValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const { driverId } = useParams<{
    driverId: string
  }>()

  const classes = useStyles()
  const history = useHistory()
  const auth = useConardAuth()
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [data, setData] = useState<DriverDto | undefined>(undefined)

  const [driverOptions, setDriverOptions] = useState<AutocompleteDto[]>([])
  const [frontLicensePlateOptions, setFrontLicensePlateOptions] = useState<AutocompleteDto[]>([])
  const [rearLicensePlateOptions, setRearLicensePlateOptions] = useState<AutocompleteDto[]>([])

  const [isDriverSelected, setIsDriverSelected] = useState(false)

  const isUpdatingDriver = !!driverId

  const [intialLoadEnd, setInitialLoadEnd] = useState<boolean>(isUpdatingDriver ? false : true)

  const driverWatch = useWatch<AutocompleteItem>({
    name: 'driver',
    control: formMethods.control,
  })

  useEffect(() => {
    //this effect is triggered only when creating a driver and choosing his name from autocomplete options
    const loadLicensePlate = async () => {
      const driverId = driverWatch?.id

      if (driverId) {
        const result = await driverService.findById(driverId)
        if (result.data) {
          setData(result.data)
          setIsDriverSelected(true)
        } else {
          setIsDriverSelected(false)
        }
      }
    }

    if (!isUpdatingDriver && intialLoadEnd) {
      loadLicensePlate()
    }
  }, [driverWatch?.id, intialLoadEnd, isUpdatingDriver])

  const onChangeDriver = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'FREE_DRIVER')
      setDriverOptions(response.data)
    }, 400)
  }

  const onChangeFrontLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setFrontLicensePlateOptions(response.data)
    }, 500)
  }

  const onChangeRearLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setRearLicensePlateOptions(response.data)
    }, 500)
  }

  const onSubmit = async (formValue: CreateDriverValue) => {
    const isAutocompleteDriver = isAutocompleteItem(formValue.driver)

    try {
      if (!driverId) {
        if (!isAutocompleteDriver) {
          const result = await driverService.create({
            id: isAutocompleteItem(formValue.driver) ? formValue.driver.id : undefined,
            name: isAutocompleteItem(formValue.driver) ? formValue.driver.name : formValue.driver,
            frontLicensePlate: {
              id: isAutocompleteItem(formValue.frontLicensePlate) ? formValue.frontLicensePlate.id : undefined,
              licensePlate: isAutocompleteItem(formValue.frontLicensePlate)
                ? formValue.frontLicensePlate.name
                : formValue.frontLicensePlate,
            },
            rearLicensePlate: {
              id: isAutocompleteItem(formValue.rearLicensePlate) ? formValue.rearLicensePlate.id : undefined,
              licensePlate: isAutocompleteItem(formValue.rearLicensePlate)
                ? formValue.rearLicensePlate.name
                : formValue.rearLicensePlate,
            },
          })

          const driverId = result.data.id

          if (carrierId && driverId) {
            await carrierService.assignDriver(carrierId, driverId)
          }
        } else {
          const value: AutocompleteItem = formMethods.getValues('driver') as AutocompleteItem
          const driverId = value.id

          if (carrierId && driverId) {
            await carrierService.assignDriver(carrierId, driverId)
          }
        }
      } else {
        await driverService.update(Number(driverId), {
          carrierShort: {
            id: carrierId ?? 0,
            name: formValue.carrierName,
          },
          id: isAutocompleteItem(formValue.driver) ? formValue.driver.id : undefined,
          name: isAutocompleteItem(formValue.driver) ? formValue.driver.name : formValue.driver,
          frontLicensePlate: {
            id: isAutocompleteItem(formValue.frontLicensePlate) ? formValue.frontLicensePlate.id : undefined,
            licensePlate: isAutocompleteItem(formValue.frontLicensePlate)
              ? formValue.frontLicensePlate.name
              : formValue.frontLicensePlate,
          },
          rearLicensePlate: {
            id: isAutocompleteItem(formValue.rearLicensePlate) ? formValue.rearLicensePlate.id : undefined,
            licensePlate: isAutocompleteItem(formValue.rearLicensePlate)
              ? formValue.rearLicensePlate.name
              : formValue.rearLicensePlate,
          },
        })
      }
      history.push(
        auth.getRole() === UserDtoRoleEnum.Carrier ? '/driver' : `/entry-and-parking/carrier/${carrierId}/driver`
      )
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    //this effect is triggered only when updating a driver to load the data
    const fetchData = async () => {
      const response = await driverService.findById(Number(driverId))

      if (response.data) {
        setData(response.data)
        setInitialLoadEnd(true)
      }
    }

    try {
      if (isUpdatingDriver) {
        fetchData()
      }
    } catch (err) {
      setInitialLoadEnd(false)
      console.error(err)
    }
  }, [driverId, isUpdatingDriver])

  useFormInitialValues(mapValues(data), formMethods.reset)

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.carriers.driverDetails')}
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid
            className={classes.container}
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ConardLabel>{t('pages.driverForm.labels.driver')}</ConardLabel>
              <RHFAutocomplete
                name="driver"
                label=""
                options={driverOptions.map((m) => ({
                  id: m.id ?? 0,
                  name: m.name ?? '',
                }))}
                onTextInputChange={onChangeDriver}
                disabled={isUpdatingDriver}
                error={!!formMethods.errors.driver}
                helperText={formMethods.errors.driver ? formMethods.errors.driver?.message : ''}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.driverForm.labels.licensePlate.front')}</ConardLabel>
              <RHFAutocomplete
                name="frontLicensePlate"
                label=""
                options={frontLicensePlateOptions.map((m) => ({
                  id: m.id ?? 0,
                  name: m.name ?? '',
                }))}
                onTextInputChange={onChangeFrontLicensePlate}
                disabled={isDriverSelected && !isUpdatingDriver}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.driverForm.labels.licensePlate.rear')}</ConardLabel>
              <RHFAutocomplete
                name="rearLicensePlate"
                label=""
                options={rearLicensePlateOptions.map((m) => ({
                  id: m.id ?? 0,
                  name: m.name ?? '',
                }))}
                onTextInputChange={onChangeRearLicensePlate}
                disabled={isDriverSelected && !isUpdatingDriver}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardButton conardVariant="dark" type="submit" className={classes.button}>
                {t('any.buttons.save')}
              </ConardButton>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardButton className={classes.backButton} conardVariant="transparent" onClick={() => history.goBack()}>
                {t('any.buttons.back')}
              </ConardButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}

const mapValues = (entity: DriverDto | undefined): CreateDriverValue | undefined => {
  if (!entity) return undefined

  return {
    driver: {
      id: entity.id ?? 0,
      name: entity.name ?? '',
    },
    frontLicensePlate: {
      id: entity.frontLicensePlate?.id ?? 0,
      name: entity.frontLicensePlate?.licensePlate ?? '',
    },
    rearLicensePlate: {
      id: entity.rearLicensePlate?.id ?? 0,
      name: entity.rearLicensePlate?.licensePlate ?? '',
    },
    carrierName: entity.carrierShort?.name ?? '',
  }
}
