import React, { FC, useEffect, useState } from 'react'
import { Grid, Hidden, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { CompanyDto } from '../../../api'
import { useTranslation } from 'react-i18next'
import companyService from '../../../services/CompanyService'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Mode } from '../../../enums/Mode'
import ConardTextField from '../../../components/ConardTextField'
import ConardButton from '../../../components/ConardButton'
import ConardSelect from '../../../components/ConardSelect'
import { Alert } from '@material-ui/lab'
import ConardHide from '../../../components/ConardHide'

interface CompanyFormProps {
  mode: Mode
  company?: CompanyDto
  isParent: boolean
}

const useStyles = makeStyles({
  container: {
    marginTop: '8vh',
    width: '50vw',
    margin: 'auto',
    minWidth: '300px',
  },
  typographyLabel: {
    width: '50vw',
  },
  button: {
    width: '15vw',
    minWidth: '200px',
    textAlign: 'center',
    margin: 'auto',
  },
  alert: {
    margin: 'auto',
    width: '50vw',
    marginTop: '20vh',
  },
})

const CompanyForm: FC<CompanyFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const { company, mode, isParent } = props
  const [parentCompanies, setParentCompanies] = useState<CompanyDto[] | undefined>(undefined)

  const companySchema = yup.object().shape({
    name: yup.string().required(),
    companyIdentificationNumber: yup.string().required(),
    companyTaxNumber: yup.string().required(),
    parentCompanyId: yup.number().when([], {
      is: () => isParent,
      then: yup.number().notRequired(),
      otherwise: yup.number().required(),
    }),
    containerFreeDays: yup.number().when([], {
      is: () => isParent,
      then: yup.number().notRequired(),
      otherwise: yup.number().required(),
    }),
    semitrailerFreeDays: yup.number().when([], {
      is: () => isParent,
      then: yup.number().notRequired(),
      otherwise: yup.number().required(),
    }),
  })

  const { handleSubmit, control, errors } = useForm<CompanyDto>({
    resolver: yupResolver(companySchema),
    reValidateMode: 'onBlur',
  })

  const getParentCompanies = () => {
    companyService.findAllParentCompanies().then((response) => {
      setParentCompanies(response.data.content)
    })
  }

  const onSubmit = (values: CompanyDto) => {
    if (isParent) {
      values = { ...values, containerFreeDays: 0, semitrailerFreeDays: 0 }
    }

    if (mode === 'create') {
      companyService.create(values).then(() => {
        if (isParent) {
          history.push('/customer/parent-company')
        } else {
          history.push('/customer/company')
        }
      })
    }
    if (mode === 'update') {
      companyService.update(company?.id, { ...values }).then(() => {
        if (isParent) {
          history.push('/customer/parent-company')
        } else {
          history.push('/customer/company')
        }
      })
    }
  }

  useEffect(() => {
    if (!isParent) {
      getParentCompanies()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTitle = () => {
    if (isParent) {
      return mode === Mode.Update
        ? t('pages.companyManagement.labels.updateParent')
        : t('pages.companyManagement.labels.createParent')
    }
    return mode === Mode.Update
      ? t('pages.companyManagement.labels.update')
      : t('pages.companyManagement.labels.create')
  }

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {getTitle()}
      </Typography>
      <ConardHide condition={mode === Mode.Create || company !== undefined}>
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      </ConardHide>
      <ConardHide condition={mode === Mode.Update && company === undefined}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            spacing={7}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={6} xs={12}>
              <ConardTextField
                id="name"
                name="name"
                type="text"
                label={isParent ? t('entity.company.fields.parentName') : t('entity.company.fields.name')}
                defaultValue={company?.name}
                error={!!errors.name}
                helperText={errors.name ? t('pages.companyManagement.validation.required.name') : ' '}
                control={control}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <ConardTextField
                id="address"
                name="address"
                type="text"
                label={t('entity.company.fields.address')}
                defaultValue={company?.address}
                error={!!errors.address}
                helperText={errors.address ? t('pages.companyManagement.validation.required.name') : ' '}
                control={control}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <ConardTextField
                id="companyIdentificationNumber"
                name="companyIdentificationNumber"
                type="text"
                label={t('entity.company.fields.companyIdentificationNumber')}
                defaultValue={company?.companyIdentificationNumber}
                error={!!errors.companyIdentificationNumber}
                helperText={
                  errors.companyIdentificationNumber
                    ? t('pages.companyManagement.validation.required.companyIdentificationNumber')
                    : ' '
                }
                control={control}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <ConardTextField
                id="companyTaxNumber"
                name="companyTaxNumber"
                type="text"
                label={t('entity.company.fields.companyTaxNumber')}
                defaultValue={props?.company?.companyTaxNumber}
                error={!!errors.companyTaxNumber}
                helperText={
                  errors.companyTaxNumber ? t('pages.companyManagement.validation.required.companyTaxNumber') : ' '
                }
                control={control}
              />
            </Grid>
            {!isParent && (
              <>
                <Hidden mdDown>
                  <Grid item lg={6} xs={12}></Grid>
                </Hidden>
                <Grid item lg={6} xs={12} alignItems="flex-end">
                  <ConardSelect
                    name="parentCompanyId"
                    defaultValue={company?.parentCompanyId}
                    error={!!errors.parentCompanyId}
                    label={t('entity.company.fields.parentCompanyId')}
                    helperText={
                      errors.parentCompanyId ? t('pages.companyManagement.validation.required.parentCompanyId') : ' '
                    }
                    control={control}
                  >
                    {parentCompanies?.map((parentCompany) => (
                      <MenuItem key={parentCompany.id} value={parentCompany.id}>
                        {parentCompany.name}
                      </MenuItem>
                    ))}
                  </ConardSelect>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ConardTextField
                    id="containerFreeDays"
                    name="containerFreeDays"
                    type="number"
                    label={t('entity.company.fields.containerFreeDays')}
                    defaultValue={company?.containerFreeDays ?? 0}
                    error={!!errors.containerFreeDays}
                    helperText={
                      errors.containerFreeDays ? t('pages.companyManagement.validation.required.freeDays') : ' '
                    }
                    control={control}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ConardTextField
                    id="semitrailerFreeDays"
                    name="semitrailerFreeDays"
                    type="number"
                    label={t('entity.company.fields.semitrailerFreeDays')}
                    defaultValue={company?.semitrailerFreeDays ?? 0}
                    error={!!errors.semitrailerFreeDays}
                    helperText={
                      errors.semitrailerFreeDays ? t('pages.companyManagement.validation.required.freeDays') : ' '
                    }
                    control={control}
                  />
                </Grid>
              </>
            )}

            <Grid item lg={6} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {mode === Mode.Create && t('pages.companyManagement.actions.submit.create')}
                  {mode === Mode.Update && t('pages.companyManagement.actions.submit.update')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('pages.companyManagement.actions.cancel')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </ConardHide>
    </>
  )
}

export default CompanyForm
