import {
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core'

import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import ConardHide from '../../components/ConardHide'
import FilterListIcon from '@material-ui/icons/FilterList'
import EmailIcon from '@material-ui/icons/Email'
import PersonIcon from '@material-ui/icons/Person'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
  PageTransitionDto,
  TransitionCustomsInfoDto,
  TransitionCustomsInfoDtoStateEnum,
  TransitionDto,
  TransitionSearchDto,
} from '../../api'
import transitionService from '../../services/TransitionService'
import { cleanObject } from '../../utils/utils'
import { format } from 'date-fns'
import CustomsFilter from './CustomsFilter'
import CustomsControlButtons from './CustomsControlButtons'
import CustomsFileUploadModal from './CustomsFileUploadModal'
import AttachmentIcon from '@material-ui/icons/Attachment'
import DeleteIcon from '@material-ui/icons/Delete'
import uploadDownloadApi from '../../services/UploadDownloadService'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '10vh',
      marginBottom: '10vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
    tooltip: {
      background: 'rgba(0,0,0,0.87)',
      fontSize: '15px',
      padding: '10px',
      borderRadius: '10px',
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

interface CustomsListPageProps {
  isHistory?: boolean
}

const CustomsListPage: FC<CustomsListPageProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [transitions, setTransitions] = useState<PageTransitionDto | undefined>()

  const isHistory = props.isHistory ?? false

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<string>('transitionDateTime')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<TransitionSearchDto | undefined>(undefined)

  const [selectedTransitions, setSelectedTransitions] = useState<TransitionDto[]>([])
  const [selectedTransition, setSelectedTransition] = useState<TransitionDto | undefined>(undefined)

  const headCells: HeadCell[] = [
    { id: 'container.iluCode', label: t('pages.customs.table.iluCode') },
    { id: 'container.shipowner.name', label: t('pages.customs.table.shipowner') },
    { id: 'transitionCustomsInfo.state', label: t('pages.customs.table.customsState.label') },
    { id: 'transitionCustomsInfo.gateInTrainName', label: t('pages.customs.table.trainName') },
    { id: 'bookingReference', label: t('pages.gateIn.form.bookingReference.label') },
    { id: 'transitionDateTime', label: t('pages.customs.table.transitionDatetime') },
    { id: 'transitionCustomsInfo.note', label: t('pages.customs.table.note') },
    {
      id: 'transitionCustomsInfo.updatedBy.surname,transitionCustomsInfo.updatedBy.firstName',
      label: t('pages.customs.table.updatedBy'),
    },
    { id: 'transitionCustomsInfo.dateOfControl', label: t('pages.customs.table.dateOfControl') },
    { id: 'transitionCustomsInfo.processedFile.fileName', label: t('pages.customs.table.fileName') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleSelectedTransition = (transition: TransitionDto, checked: boolean) => {
    if (checked) {
      setSelectedTransitions([...selectedTransitions, transition])
    } else {
      setSelectedTransitions((selectedTransitions) => selectedTransitions.filter((t) => t.id !== transition.id))
    }
  }

  const handleSelectAll = (checked: boolean) => {
    if (checked && transitions && transitions.content) {
      setSelectedTransitions([...transitions!.content!])
    } else {
      setSelectedTransitions([])
    }
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleDeleteCustomsInfo = () => {
    transitionService
      .deleteCustomsInfos(selectedTransitions.map((t) => t.id ?? -1))
      .catch()
      .finally(() => {
        search(undefined, false)
      })
  }

  const handleDeleteFile = (transition: TransitionDto) => {
    uploadDownloadApi
      .deleteCustomsFile(transition.id ?? -1)
      .then(() => {
        search(undefined, false)
      })
      .catch()
  }

  const handleChangeCustomsInfo = (customInfo: TransitionCustomsInfoDto) => {
    const newCustomsInfo = cleanObject(customInfo)

    const newTransitions: TransitionDto[] = [
      ...selectedTransitions.map((t) => ({
        ...t,
        transitionCustomsInfo: { ...t.transitionCustomsInfo, ...newCustomsInfo },
      })),
    ]

    transitionService
      .changeCustomsInfo(newTransitions, customInfo.state !== undefined)
      .then(() => {
        search(undefined, false)
      })
      .catch()
  }

  const searchFiltered = (data: TransitionSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TransitionSearchDto | undefined, fromFilter: boolean) => {
    setSelectedTransitions([])
    setSearchDto(data)

    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
        shipowner: {
          ...cleanObject(data?.container?.shipowner),
        },
      },
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    if (isHistory) {
      transitionService
        .customsSearchHistory(page, pageSize, currentSort, cleanObject(data))
        .then((response) => {
          setTransitions(response.data)
          if (fromFilter) {
            setPage(0)
          }
        })
        .catch()
    } else {
      transitionService
        .customsSearch(page, pageSize, currentSort, cleanObject(data))
        .then((response) => {
          setTransitions(response.data)
          if (fromFilter) {
            setPage(0)
          }
        })
        .catch()
    }
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {isHistory ? t('mainMenu.pages.history.title') : t('mainMenu.pages.customsSummary.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <ConardHide condition={!showFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomsFilter searchFilter={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {!isHistory && (
                      <TableCell>
                        <Checkbox
                          checked={transitions?.content?.length === selectedTransitions.length}
                          onChange={(event, checked) => handleSelectAll(checked)}
                        />
                      </TableCell>
                    )}
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transitions !== undefined &&
                    transitions.content?.length !== 0 &&
                    transitions?.content?.map((transition) => (
                      <TableRow
                        key={transition.id}
                        className={
                          isHistory ||
                          transition?.transitionCustomsInfo?.state !== TransitionCustomsInfoDtoStateEnum.Stop
                            ? classes.disabledTableRow
                            : ''
                        }
                      >
                        {!isHistory && (
                          <TableCell component="td" scope="row">
                            <Checkbox
                              color="primary"
                              checked={selectedTransitions.includes(transition)}
                              onChange={(event, checked) => handleSelectedTransition(transition, checked)}
                            />
                          </TableCell>
                        )}
                        <TableCell component="td" scope="row">
                          {transition.container.iluCode}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.container.shipowner?.name}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {!!transition.transitionCustomsInfo?.state
                            ? t('enum.transitionCustomsState.' + transition.transitionCustomsInfo?.state)
                            : ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.transitionCustomsInfo?.gateInTrain?.name ?? ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.bookingReference ?? ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.transitionDateTime
                            ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                            : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.transitionCustomsInfo?.note ?? ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.transitionCustomsInfo?.state === TransitionCustomsInfoDtoStateEnum.T1ByEmail && (
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              placement="bottom"
                              enterDelay={0}
                              title={
                                transition.transitionCustomsInfo?.updatedAt
                                  ? format(new Date(transition.transitionCustomsInfo?.updatedAt), 'dd.MM.yyyy HH:mm')
                                  : ''
                              }
                            >
                              <EmailIcon color="primary" />
                            </Tooltip>
                          )}
                          {transition.transitionCustomsInfo?.state !== TransitionCustomsInfoDtoStateEnum.T1ByEmail &&
                            !!transition.transitionCustomsInfo?.updatedBy && (
                              <Tooltip
                                classes={{ tooltip: classes.tooltip }}
                                placement="bottom"
                                enterDelay={0}
                                title={
                                  transition.transitionCustomsInfo?.updatedBy.firstName +
                                  ' ' +
                                  transition.transitionCustomsInfo?.updatedBy.surname +
                                  ', ' +
                                  (transition.transitionCustomsInfo?.updatedAt
                                    ? format(new Date(transition.transitionCustomsInfo?.updatedAt), 'dd.MM.yyyy HH:mm')
                                    : '')
                                }
                              >
                                <PersonIcon color="primary" />
                              </Tooltip>
                            )}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.transitionCustomsInfo?.dateOfControl
                            ? format(new Date(transition.transitionCustomsInfo?.dateOfControl), 'dd.MM.yyyy')
                            : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            enterDelay={0}
                            placement="bottom"
                            title={transition.transitionCustomsInfo?.processedFile?.fileName ?? ''}
                          >
                            <span>
                              <ConardButton
                                startIcon={!!transition.transitionCustomsInfo?.processedFile ? <AttachmentIcon /> : ''}
                                conardVariant="light"
                                onClick={() => setSelectedTransition(transition)}
                              >
                                {transition.transitionCustomsInfo?.processedFile
                                  ? t('any.buttons.overwrite')
                                  : t('any.buttons.load')}
                              </ConardButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <ConardHide condition={!transition.transitionCustomsInfo?.processedFile}>
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              enterDelay={0}
                              placement="bottom"
                              title={t('any.buttons.customsPDF.delete') ?? ''}
                            >
                              <IconButton
                                onClick={() => {
                                  handleDeleteFile(transition)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ConardHide>
                        </TableCell>
                      </TableRow>
                    ))}
                  {transitions?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={10}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!transitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={transitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      {!isHistory && (
        <CustomsControlButtons
          disabled={selectedTransitions.length < 1}
          handleChangeCustomsInfo={handleChangeCustomsInfo}
          handleDeleteCustomsInfo={handleDeleteCustomsInfo}
        />
      )}
      <CustomsFileUploadModal
        transition={selectedTransition}
        onClose={() => setSelectedTransition(undefined)}
        search={() => search(undefined, false)}
      />
    </>
  )
}

export default CustomsListPage
