import React, { FC } from 'react'

interface ConardHideProps {
  condition: boolean
}

const ConardHide: FC<ConardHideProps> = (props) => {
  const { condition } = props

  return <>{condition ? null : props.children}</>
}

export default ConardHide
