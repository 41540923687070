import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { PageShipownerDto, ShipownerDto, ShipownerSearchDto } from '../../../api'
import ConardButton from '../../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AddIcon from '@material-ui/icons/Add'
import ConardHide from '../../../components/ConardHide'
import { Order } from '../../../enums/Order'
import EditIcon from '@material-ui/icons/Edit'
import { cleanObject } from '../../../utils/utils'
import shipownerService from '../../../services/ShipownerService'
import ShipownerFilter from './ShipownerFilter'
import ConardProgressBar from '../../../components/ConardProgressBar'

const useStyles = makeStyles({
  table: {
    minWidth: '80vh',
  },
  gridContainer: {
    marginTop: '8vh',
  },
  typographyLabel: {
    width: '50vw',
  },
  filterButton: {
    textAlign: 'center',
    margin: '20px',
    float: 'left',
  },
  createButton: {
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    textAlign: 'center',
    float: 'right',
    margin: '1vh',
  },
  noData: {
    textAlign: 'center',
  },
  editTable: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof ShipownerDto
  label: string
}

const ShipownerListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('name')
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(0)
  const [filtering, setFiltering] = useState(false)
  const [shipownersPage, setShipownersPage] = useState<PageShipownerDto | undefined>(undefined)
  const [shipownerSearchDto, setShipownerSearchDto] = useState<ShipownerSearchDto | undefined>(undefined)

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.shipowner.fields.name') },
    { id: 'companyId', label: t('entity.shipowner.fields.company') },
  ]

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchFiltered = (data: ShipownerSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: ShipownerSearchDto | undefined, fromFilter: boolean) => {
    setShipownerSearchDto(data)

    shipownerService
      .search(page, pageSize, orderBy, order, cleanObject(data))
      .then((response) => {
        setShipownersPage(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  useEffect(() => {
    search(shipownerSearchDto, false)
  }, [order, orderBy, page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.shipownerManagement.labels.list')}
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={filtering ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={() => setFiltering(!filtering)}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <div className={classes.createButton}>
            <ConardButton
              conardVariant="light"
              onClick={() => history.push('/customer/shipowner/create')}
              startIcon={<AddIcon />}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
        <ConardHide condition={!filtering}>
          <Grid item lg={12} md={11} sm={11} xs={11}>
            <ShipownerFilter onFilterSubmit={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item lg={12} md={11} sm={11} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : Order.Asc}
                          onClick={onSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={shipownersPage?.content === undefined}>
                    {shipownersPage?.content?.map((shipowner) => (
                      <TableRow key={shipowner.id}>
                        <TableCell>{shipowner.name}</TableCell>
                        <TableCell>{shipowner.companyName}</TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => history.push('/customer/shipowner/update/' + shipowner.id)}
                            startIcon={<EditIcon />}
                          >
                            {t('any.buttons.edit')}
                          </ConardButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {shipownersPage?.content?.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.noData}>{t('pages.common.table.noData')}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!shipownersPage} />
            </TableContainer>
            <TablePagination
              component="div"
              count={shipownersPage?.totalElements || 0}
              onPageChange={onPage}
              page={page}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              rowsPerPageOptions={[10, 20, 50, 150]}
              onRowsPerPageChange={onRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item lg={12} md={11} sm={11} xs={11}>
          <div className={classes.createButton}>
            <ConardButton
              conardVariant="light"
              onClick={() => history.push('/customer/shipowner/create')}
              startIcon={<AddIcon />}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ShipownerListPage
