import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'

export interface NotificationRemoveDialogProps {
  showRemoveDialog: boolean
  onClose: () => void
  onRemove: () => void
}

const EmptyCoparnDeleteDialog: FC<NotificationRemoveDialogProps> = ({ showRemoveDialog, onClose, onRemove }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={showRemoveDialog}>
      <DialogTitle>{t('pages.gateOut.coparn.delete.dialog.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('pages.gateOut.coparn.delete.dialog.description')}</Typography>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={onRemove}>
          {t('any.buttons.ok')}
        </ConardButton>
        <ConardButton conardVariant="dark" onClick={onClose}>
          {t('any.buttons.cancel')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default EmptyCoparnDeleteDialog
