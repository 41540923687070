import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import ConardButton from '../../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Order } from '../../../enums/Order'
import { DriverArrivalDto, DriverCarrierDto, DriverCarrierSearchDto, PageDriverCarrierDto } from '../../../api'
import ConardProgressBar from '../../../components/ConardProgressBar'
import driverArrivalService from '../../../services/DriverArrivalService'
import { useHistory } from 'react-router-dom'
import { DeleteAllDialog } from '../../drivers/DeleteAllDialog'
import { CarrierRow } from './CarrierRow'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { CarrierFilter } from './CarrierFilter'
import { cleanObject } from '../../../utils/utils'
import { TrafficOutlined } from '@mui/icons-material'

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      textAlign: 'center',
      marginTop: '20px',
      float: 'left',
    },
    tableHead: {
      textAlign: 'center',
      width: 'auto',
    },
    filter: {
      borderRadius: 12,
    },
    tableHeadLeft: {
      textAlign: 'left',
      width: 'auto',
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

export const CarrierMainPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [data, setData] = useState<PageDriverCarrierDto | null>()
  const [loaded, setLoaded] = useState<boolean>(false)

  const [showDialogAll, setShowDialogAll] = useState(false)
  const [showDialogGateIn, setShowDialogGateIn] = useState(false)
  const [showDialogGateOut, setShowDialogGateOut] = useState(false)
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const [, setSearchDto] = useState<DriverCarrierSearchDto | undefined>(undefined)

  const sortSettings = getSortSettings()

  const [showFilter, setShowFilter] = useState(true)
  const [orderBy, setOrderBy] = useState<string>(sortSettings?.orderBy ?? 'driverArrival.arrivalDateTime')
  const [order, setOrder] = useState<Order>(sortSettings?.order ?? Order.Asc)
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(0)

  const carrierPageSortSettings = (orderBy: string, order: Order) => {
    localStorage.setItem('carrierPageSortSettings', JSON.stringify({ orderBy, order }))
  }

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.driver.fields.driverName') },
    { id: 'driverArrival.arrivalDateTime', label: t('entity.driver.fields.arrivalDateTime') },
    { id: 'semaphore', label: <TrafficOutlined /> },
    { id: 'driverArrival', label: t('entity.driver.fields.driverArrival') },
  ]

  const currentSort: Array<string> | undefined = useMemo(() => {
    if (orderBy) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      return [orderBy + ',' + currentOrder]
    }

    return undefined
  }, [order, orderBy])

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    const newOrder = isAsc ? Order.Desc : Order.Asc
    setOrder(newOrder)
    setOrderBy(property)

    carrierPageSortSettings(property, newOrder)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const search = async (data: DriverCarrierSearchDto | undefined) => {
    setSearchDto(data)

    const response = await driverArrivalService.getCarriersDriverArrival(page, pageSize, currentSort, cleanObject(data))

    if (response.data) {
      setData(response.data)
      setPage(0)
    }
    return
  }

  const searchFiltered = (data: DriverCarrierSearchDto | undefined) => {
    search(data)
  }

  const onEditGateIn = (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    const containerId = driver?.driverArrival?.plannedTransition?.container.id
    const idDriverArrival = driver?.driverArrival?.id

    if (driver?.driverArrival?.plannedTransition) {
      history.push(`/driver-arrival/gate-in/${idDriverArrival}?${containerId}`)
    }
  }

  const onEditGateOut = (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    const idDriverArrival = driver?.driverArrival?.id

    if (
      driver?.driverArrival?.reference ||
      driver?.driverArrival?.iluCode ||
      driver?.driverArrival?.semitrailerLicencePlate
    ) {
      history.push(`/driver-arrival/gate-out/${idDriverArrival}`)
    }
  }

  const onGateIn = (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    const idDriverArrival = driver?.driverArrival?.id

    history.push(`/driver-arrival/check-ilu/${idDriverArrival}`)
  }

  const onGateOut = (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    const idDriverArrival = driver?.driverArrival?.id

    history.push(`/driver-arrival/gate-out/${idDriverArrival}`)
  }

  const updateCarrier = (driverArrival: DriverArrivalDto, driverId: number) => {
    setData((previous) => {
      const carr = previous?.content?.find((x) => x.id === driverId)

      if (!carr) {
        return previous
      }

      const newCarr: DriverCarrierDto = {
        ...carr,
        driverArrival: driverArrival,
      }

      return {
        ...previous,
        content: previous?.content?.reduce((acc: DriverCarrierDto[], curr: DriverCarrierDto) => {
          if (curr.id !== driverId) {
            return [...acc, curr]
          }

          return [...acc, newCarr]
        }, []),
      }
    })
  }

  const changeArrivalDateTime = async (driverId: number, newDateTime: MaterialUiPickersDate | null) => {
    const carrier = data?.content?.find((x) => x.id === driverId)

    if (!carrier) {
      return
    }

    if (!newDateTime) {
      return
    }

    if (!carrier.driverArrival) {
      const result = await driverArrivalService.createDriverArrivalByCarrier(driverId, {
        arrivalDateTime: newDateTime.toISOString(),
        driver: {
          name: carrier.name,
        },
      })

      if (result.data) {
        updateCarrier(result.data, driverId)
        return
      }

      throw new Error('Api to nezvladlo pri creatu..')
    }

    const result2 = await driverArrivalService.update(carrier.driverArrival.id ?? 0, {
      ...carrier.driverArrival,
      arrivalDateTime: newDateTime.toISOString(),
      driver: {
        ...carrier.driverArrival.driver,
        id: carrier.id,
        name: carrier.name,
      },
    })

    if (result2.data) {
      updateCarrier(result2.data, driverId)
      return
    }

    throw new Error('Api to nezvladlo pri updatu...')
  }

  const onGateInNoCargo = async (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    if (driver?.driverArrival) {
      try {
        const result = await driverArrivalService.update(driver.driverArrival?.id ?? 0, {
          ...driver.driverArrival,
          withoutGateIn: true,
        })

        if (result.data) {
          updateCarrier(result.data, driverId)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const onGateOutNoCargo = async (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    if (driver?.driverArrival) {
      try {
        const result = await driverArrivalService.update(driver.driverArrival?.id ?? 0, {
          ...driver.driverArrival,
          withoutGateOut: true,
        })

        if (result.data) {
          updateCarrier(result.data, driverId)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleDeleteAll = (driverId: number) => {
    const driver = data?.content?.find((x) => x.id === driverId)

    if (driver?.driverArrival) {
      setSelectedId(driverId)
      setShowDialogAll(true)
    }
  }

  const handleDialogAll = async () => {
    setShowDialogAll(false)

    if (selectedId) {
      const driver = data?.content?.find((x) => x.id === selectedId)

      if (driver?.driverArrival) {
        try {
          await driverArrivalService.cancel(driver.driverArrival.id ?? 0)
          await fetchPageData()
        } catch (err) {
          console.error(err)
        }
      }
      setSelectedId(undefined)
    }
  }

  const handleDeleteGateIn = async (driverId: number) => {
    const driver = data?.content?.find((d) => d.id === driverId)

    if (driver?.driverArrival?.plannedTransition || driver?.driverArrival?.withoutGateIn) {
      setSelectedId(driverId)
      setShowDialogGateIn(true)
    }
  }

  const handleDialogGateIn = async () => {
    setShowDialogGateIn(false)

    if (selectedId) {
      const driver = data?.content?.find((d) => d.id === selectedId)

      if (driver?.driverArrival?.plannedTransition || driver?.driverArrival?.withoutGateIn)
        try {
          await driverArrivalService.deleteGateInOrOut(driver?.driverArrival.id ?? 0, 'IN')
          await fetchPageData()
        } catch (err) {
          console.error(err)
        }
      setSelectedId(undefined)
    }
  }

  const handleDeleteGateOut = async (driverId: number) => {
    const driver = data?.content?.find((d) => d.id === driverId)
    const validGateOut =
      driver?.driverArrival?.iluCode ||
      driver?.driverArrival?.reference ||
      driver?.driverArrival?.semitrailerLicencePlate

    if (validGateOut || driver?.driverArrival?.withoutGateOut) {
      setSelectedId(driverId)
      setShowDialogGateOut(true)
    }
  }

  const handleDialogGateOut = async () => {
    setShowDialogGateOut(false)

    if (selectedId) {
      const driver = data?.content?.find((d) => d.id === selectedId)
      const validGateOut =
        driver?.driverArrival?.iluCode ||
        driver?.driverArrival?.reference ||
        driver?.driverArrival?.semitrailerLicencePlate

      if (validGateOut || driver?.driverArrival?.withoutGateOut) {
        try {
          await driverArrivalService.deleteGateInOrOut(driver?.driverArrival?.id ?? 0, 'OUT')
          await fetchPageData()
        } catch (err) {
          console.error(err)
        }
      }
      setSelectedId(undefined)
    }
  }

  const fetchPageData = useCallback(async () => {
    const response = await driverArrivalService.getCarriersDriverArrival(page, pageSize, currentSort)

    if (response.data) {
      setData(response.data)
    }

    setLoaded(true)
  }, [currentSort, page, pageSize])

  useEffect(() => {
    try {
      fetchPageData()
    } catch (err) {
      console.error(err)
    }
  }, [fetchPageData])

  return (
    <div style={{ display: !loaded ? 'none' : undefined }}>
      <Typography variant="h4" color="primary">
        {t('mainMenu.pages.driverArrivals.title')}
      </Typography>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
              className={classes.filter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>

        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CarrierFilter carrierFilter={searchFiltered} />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell, index) => (
                      <TableCell
                        className={index === 0 ? classes.tableHeadLeft : classes.tableHead}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.id === 'name' || headCell.id === 'driverArrival.arrivalDateTime' ? (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          <span>{headCell.label}</span>
                        )}
                      </TableCell>
                    ))}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.content?.map((driver) => (
                    <CarrierRow
                      key={driver.id}
                      driverId={driver.id ?? 0}
                      driverName={driver.name}
                      onDeleteAll={() => handleDeleteAll(driver.id ?? 0)}
                      onGateInNoCargo={() => onGateInNoCargo(driver.id ?? 0)}
                      onGateOutNoCargo={() => onGateOutNoCargo(driver.id ?? 0)}
                      onGateIn={() => onGateIn(driver.id ?? 0)}
                      onGateOut={() => onGateOut(driver?.id ?? 0)}
                      onEditGateIn={() => onEditGateIn(driver.id ?? 0)}
                      onEditGateOut={() => onEditGateOut(driver.id ?? 0)}
                      data={driver.driverArrival}
                      onChangeArrivalDateTime={(newDateTime) => changeArrivalDateTime(driver.id ?? 0, newDateTime)}
                      onDeleteGateIn={() => handleDeleteGateIn(driver.id ?? 0)}
                      onDeleteGateOut={() => handleDeleteGateOut(driver.id ?? 0)}
                    />
                  ))}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!data} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={data?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <DeleteAllDialog showDialog={showDialogAll} onClose={() => setShowDialogAll(false)} onYes={handleDialogAll} />
          <DeleteAllDialog
            showDialog={showDialogGateIn}
            onClose={() => setShowDialogGateIn(false)}
            onYes={handleDialogGateIn}
          />
          <DeleteAllDialog
            showDialog={showDialogGateOut}
            onClose={() => setShowDialogGateOut(false)}
            onYes={handleDialogGateOut}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const getSortSettings = () => {
  const sortSettings = localStorage.getItem('carrierPageSortSettings')

  if (sortSettings) {
    return JSON.parse(sortSettings) as SortSettings
  }

  return null
}

type SortSettings = {
  orderBy: string
  order: Order
}
