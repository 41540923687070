import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import {
  OperatorTransitionDto,
  OperatorTransitionDtoReceiptMethodEnum,
  OperatorTransitionSearchDto,
  OperatorTransitionSearchDtoReceiptMethodEnum,
  PageOperatorTransitionDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import { Order } from '../../enums/Order'
import { cleanObject } from '../../utils/utils'
import transitionService from '../../services/TransitionService'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LowPriorityIcon from '@material-ui/icons/LowPriority'
import { LocalShipping, LowPriorityOutlined, Train } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import OperatorFilter from './OperatorFilter'
import OperatorClickableRow from './OperatorClickableRow'
import { RootState, useAppDispatch } from '../../redux/store'
import { setSearchOperatorTransition } from '../../redux/operator/OperatorTransitionSearchSlice'
import { useSelector } from 'react-redux'
import { ConardCoordinates } from '../../components/ConardCoordinates'
import { ConfigContext } from '../../context/ConfigContext'
import { DoNotDisturbAlt, LocalShippingOutlined, ScaleOutlined, TrainOutlined } from '@mui/icons-material'
import ConardTooltip from '../../components/ConardTooltip'
import OperatorContainerQuality from '../../components/operator/OperatorContainerQuality'
import OperatorContainerIcon from '../../components/operator/OperatorContainerIcon'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '4vh',
      position: 'absolute',
      width: '99vw',
      left: '1vw',
    },
    buttonCell: {
      minHeight: '50px',
      textAlign: 'center',
    },
    trainTableRowLight: {
      backgroundColor: theme.palette.info.light,
    },
    trainTableRowDark: {
      backgroundColor: theme.palette.info.main,
    },
    containerQualityError: {
      align: 'center',
      color: theme.palette.error.main,
    },
    outlinedIcon: {
      color: theme.palette.primary.main,
    },
    containerType: {
      display: 'flex',
    },
    tableCell: {
      fontSize: '20px',
      padding: '4px',
    },
    tableHeadeCell: {
      fontSize: '20px',
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

const OperatorListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const configContext = useContext(ConfigContext)

  const [order, setOrder] = useState(configContext.operatorView.orderAsc ? Order.Asc : Order.Desc)
  const [orderBy, setOrderBy] = useState('dateTime')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [searchDto, setSearchDto] = useState<OperatorTransitionSearchDto | undefined>()
  const [showFilter, setShowFilter] = useState(false)
  const [enableIntervalSearch, setEnableIntervalSearch] = useState(true)

  const dispatch = useAppDispatch()
  const operatorTransitions = useSelector<RootState, OperatorTransitionSearchDto | undefined>(
    (state) => state.operatorTransitionSearch.operatorTransitionSearch
  )

  let lastTrainId = -1
  let lastTrainStyle: 'trainTableRowLight' | 'trainTableRowDark' = 'trainTableRowLight'

  const [pageOperatorTransitions, setPageOperatorTransitions] = useState<PageOperatorTransitionDto | null>()

  const headCells: HeadCell[] = [
    { id: 'direction', label: t('pages.operator.table.actions') },
    {
      id: 'receiptMethod',
      label: (
        <>
          <Train fontSize="small" />
          <Typography variant="h5">/</Typography>
          <LocalShipping fontSize="small" />
        </>
      ),
    },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'containerType', label: t('pages.gateIn.form.shippingContainerType.label') },
    { id: 'qualityType', label: t('pages.gateIn.form.qualityType.label') },
    { id: 'shipowner', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'heavyTestedValue', label: t('pages.operator.table.heavyTested') },
    { id: 'importExportRepleted', label: t('pages.operator.table.importExportRepleted') },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'trainName', label: t('pages.operator.table.trainName') },
    {
      id: 'medlogReference,note',
      label:
        (configContext.generalCarrierReference
          ? t('pages.gateOut.coparn.carrierRef')
          : t('pages.gateOut.coparn.medlogRef')) +
        ' / ' +
        t('pages.gateIn.form.note.label'),
    },
    {
      id: 'weighted',
      label: (
        <>
          <ConardTooltip title={t('pages.gateIn.form.weighted.label')}>
            <ScaleOutlined fontSize="small" />
          </ConardTooltip>
        </>
      ),
    },
    { id: 'importExport', label: t('pages.operator.table.importExport') },
    { id: 'sectorLetter,sectorColumn,sectorRow,sectorFloor', label: t('pages.gateIn.form.location.label') },
    {
      id: 'lockdown',
      label: t('pages.operator.table.lockdownTitle'),
    },
  ]

  const getHeadCells = () => {
    if (!configContext.operatorView.shipowner) {
      const index: number = headCells.findIndex((cell) => cell.id === 'shipowner')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.trainName) {
      const index: number = headCells.findIndex((cell) => cell.id === 'trainName')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.lockdown) {
      const index: number = headCells.findIndex((cell) => cell.id === 'lockdown')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.weighted) {
      const index: number = headCells.findIndex((cell) => cell.id === 'weighted')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.payload) {
      const index: number = headCells.findIndex((cell) => cell.id === 'heavyTestedValue')
      headCells.splice(index, 1)
    }

    if (configContext.operatorView.importExportRepletedColumn) {
      const index: number = headCells.findIndex((cell) => cell.id === 'importExport')
      headCells.splice(index, 1)
    } else {
      const index: number = headCells.findIndex((cell) => cell.id === 'importExportRepleted')
      headCells.splice(index, 1)
    }

    return headCells
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeReceiptMethod = (newReceiptMethod: OperatorTransitionSearchDtoReceiptMethodEnum | undefined) => {
    dispatch(setSearchOperatorTransition({ receiptMethod: newReceiptMethod }))
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: OperatorTransitionSearchDto | undefined) => {
    search(data, true)
  }

  const handleTrainStyle = (operatorTransition: OperatorTransitionDto) => {
    if (operatorTransition.trainId !== lastTrainId) {
      if (lastTrainStyle === 'trainTableRowLight') {
        lastTrainStyle = 'trainTableRowDark'
      } else {
        lastTrainStyle = 'trainTableRowLight'
      }
    }

    lastTrainId = operatorTransition.trainId ?? -1

    if (lastTrainStyle === 'trainTableRowLight') {
      return classes.trainTableRowLight
    }
    return classes.trainTableRowDark
  }

  const search = (data: OperatorTransitionSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    data = {
      ...data,
      receiptMethod: operatorTransitions?.receiptMethod,
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    transitionService
      .operatorSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageOperatorTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  const renderRecieptMethodIcon = (operatorTransition: OperatorTransitionDto) => {
    if (operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road) {
      if (operatorTransition.repleted) {
        return <LocalShipping color="primary" />
      }
      return <LocalShippingOutlined className={classes.outlinedIcon} />
    }
    if (operatorTransition.repleted) {
      return <Train color="primary" />
    }
    return <TrainOutlined className={classes.outlinedIcon} />
  }

  useEffect(() => {
    search(searchDto, false)

    const intervalId = setInterval(() => {
      if (enableIntervalSearch) {
        search(searchDto, false)
      }
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [page, pageSize, orderBy, order, operatorTransitions, searchDto, enableIntervalSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <ConardButton
            conardVariant={operatorTransitions?.receiptMethod === undefined ? 'light' : 'transparent'}
            className={classes.buttonCell}
            onClick={() => {
              handleChangeReceiptMethod(undefined)
            }}
          >
            {t('pages.common.select.all')}
          </ConardButton>
        </Grid>
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <ConardButton
            conardVariant={
              operatorTransitions?.receiptMethod === OperatorTransitionSearchDtoReceiptMethodEnum.Road
                ? 'light'
                : 'transparent'
            }
            className={classes.buttonCell}
            onClick={() => {
              handleChangeReceiptMethod(OperatorTransitionSearchDtoReceiptMethodEnum.Road)
            }}
          >
            {t('pages.gateIn.form.receiptMethod.unchecked')}
          </ConardButton>
        </Grid>
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <ConardButton
            conardVariant={
              operatorTransitions?.receiptMethod === OperatorTransitionSearchDtoReceiptMethodEnum.Train
                ? 'light'
                : 'transparent'
            }
            className={classes.buttonCell}
            onClick={() => {
              handleChangeReceiptMethod(OperatorTransitionSearchDtoReceiptMethodEnum.Train)
            }}
          >
            {t('pages.gateIn.form.receiptMethod.checked')}
          </ConardButton>
        </Grid>
        <Hidden mdUp xsDown>
          <Grid item sm={6} />
        </Hidden>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <ConardButton
            conardVariant="transparent"
            className={classes.buttonCell}
            startIcon={<FilterListIcon />}
            endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={handleFilter}
          >
            {t('any.buttons.filter')}
          </ConardButton>
        </Grid>
        <Hidden mdDown>
          <Grid item xl={5} lg={2} />
        </Hidden>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <ConardButton
            conardVariant="light"
            className={classes.buttonCell}
            startIcon={<LowPriorityIcon fontSize="medium" color="secondary" />}
            onClick={() => {
              history.push('/operator/positioning')
            }}
          >
            {t('pages.operator.buttons.positioning')}
          </ConardButton>
        </Grid>
        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <OperatorFilter searchFilter={searchFiltered} />
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {getHeadCells().map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeadeCell}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageOperatorTransitions &&
                    pageOperatorTransitions?.content?.map((operatorTransition) => (
                      <OperatorClickableRow
                        key={operatorTransition.id}
                        operatorTransition={operatorTransition}
                        handleTrainStyle={handleTrainStyle}
                        search={search}
                        setEnableIntervalSearch={setEnableIntervalSearch}
                      >
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {renderRecieptMethodIcon(operatorTransition)}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <b>
                            <i>{operatorTransition.iluCode}</i>
                          </b>
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <div className={classes.containerType}>
                            <OperatorContainerIcon transition={operatorTransition} />
                          </div>
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <OperatorContainerQuality transition={operatorTransition} />
                        </TableCell>
                        {configContext.operatorView.shipowner && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.shipownerName}
                          </TableCell>
                        )}
                        {configContext.operatorView.payload && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.heavyTestedValue &&
                            operatorTransition.heavyTestedValue.toString().length >= 4 ? (
                              operatorTransition.heavyTestedValue.toString().slice(0, -3) === '26' ? (
                                <span style={{ color: 'red', fontWeight: 'bold' }}>
                                  {operatorTransition.heavyTestedValue.toString().slice(0, -3) + ' t'}
                                </span>
                              ) : (
                                operatorTransition.heavyTestedValue.toString().slice(0, -3) + ' t'
                              )
                            ) : (
                              ''
                            )}
                          </TableCell>
                        )}
                        {configContext.operatorView.importExportRepletedColumn && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.coparnTruckId === null ? (operatorTransition.repleted ? 'L' : 'P') : ''}
                            {operatorTransition.coparnTruckId === null && !!operatorTransition.importExport
                              ? ' - '
                              : ''}
                            {operatorTransition.importExport
                              ? t('pages.gateIn.form.importExport.' + operatorTransition.importExport?.toLowerCase())
                              : ''}
                          </TableCell>
                        )}
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <b>
                            <i>
                              {operatorTransition.truckLicencePlate ? operatorTransition.truckLicencePlate + ' ' : ''}
                              {operatorTransition.driver ?? ''}
                            </i>
                          </b>
                        </TableCell>
                        {configContext.operatorView.trainName && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.trainName ?? ''}
                          </TableCell>
                        )}
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {operatorTransition.medlogReference ?? operatorTransition.note ?? ' '}
                        </TableCell>
                        {configContext.operatorView.weighted && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.weighted && (
                              <ConardTooltip title={t('pages.gateIn.form.weighted.label')}>
                                <ScaleOutlined color="warning" fontSize="small" />
                              </ConardTooltip>
                            )}
                          </TableCell>
                        )}
                        {!configContext.operatorView.importExportRepletedColumn && (
                          <TableCell component="td" scope="row" className={classes.tableCell}>
                            {operatorTransition.importExport
                              ? t('pages.gateIn.form.importExport.' + operatorTransition.importExport?.toLowerCase())
                              : ''}
                          </TableCell>
                        )}
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <TableRow component="td" scope="row">
                            <ConardCoordinates transition={operatorTransition} />
                          </TableRow>
                          {operatorTransition?.manipulationRequestId && (
                            <TableRow component="td" scope="row">
                              <LowPriorityOutlined color="primary" />
                            </TableRow>
                          )}
                          {operatorTransition?.manipulationRequestId && (
                            <TableRow component="td" scope="row">
                              {(configContext.coordinates.sectorLetterCount === 0
                                ? ''
                                : (operatorTransition?.sectorLetterForManipulation || '-') + ' | ') +
                                (configContext.coordinates.maxSectorColumn === 0
                                  ? ''
                                  : (operatorTransition?.sectorColumnForManipulation || '-') + ' | ') +
                                (configContext.coordinates.maxSectorRow === 0
                                  ? ''
                                  : (operatorTransition?.sectorRowForManipulation || '-') + ' | ') +
                                (configContext.coordinates.maxSectorFloor === 0
                                  ? ''
                                  : operatorTransition?.sectorFloorForManipulation || '-')}
                            </TableRow>
                          )}
                        </TableCell>
                        {configContext.operatorView.lockdown && (
                          <TableCell component="td" scope="row">
                            {operatorTransition.lockdown && (
                              <ConardTooltip title={t('pages.operator.table.lockdown')}>
                                <DoNotDisturbAlt fontSize="small" color="error" />
                              </ConardTooltip>
                            )}
                          </TableCell>
                        )}
                      </OperatorClickableRow>
                    ))}
                  {!!pageOperatorTransitions?.content && pageOperatorTransitions.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" align="center" colSpan={12} className={classes.tableCell}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageOperatorTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default OperatorListPage
