import React, { FC } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { AvailableContainerDto, AvailableContainerDtoCoparnContainerQualityEnum, CoparnContainerDto } from '../../api'
import { useForm } from 'react-hook-form'
import ConardButton from '../../components/ConardButton'
import ConardSelect from '../ConardSelect'

interface AvailableContainerFilterProps {
  coparnContainer: CoparnContainerDto
  containersFilter: (data: AvailableContainerDto) => void
}

const AvailableContainerSelectionFilter: FC<AvailableContainerFilterProps> = ({
  coparnContainer,
  containersFilter,
}) => {
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<AvailableContainerDto>({
    defaultValues: {
      iluCode: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      coparnContainerQuality: coparnContainer.coparnContainerQuality as any,
    },
  })

  return (
    <form onSubmit={handleSubmit(containersFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <ConardTextField
            id="iluCode"
            name="iluCode"
            type="text"
            label={t('pages.gateOut.coparn.modal.iluCode')}
            control={control}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <ConardSelect
            label={t('pages.gateOut.coparn.modal.quality')}
            name="coparnContainerQuality"
            defaultValue={coparnContainer.coparnContainerQuality}
            control={control}
          >
            <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.All}>
              {t('pages.common.select.all')}
            </MenuItem>
            <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.A}>
              {AvailableContainerDtoCoparnContainerQualityEnum.A}
            </MenuItem>
            <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.B}>
              {AvailableContainerDtoCoparnContainerQualityEnum.B}
            </MenuItem>
            <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.C}>
              {AvailableContainerDtoCoparnContainerQualityEnum.C}
            </MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default AvailableContainerSelectionFilter
