import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { AsparkingCardDto } from '../../api'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  createStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import ConardHide from '../../components/ConardHide'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import cardService from '../../services/CardService'
import ConardButton from '../../components/ConardButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    buttonBox: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      padding: 24,
    },
  })
)

interface UrlParamTypes {
  cardId?: string
}

const CardHistoryPage: FC = () => {
  const { t } = useTranslation()
  const { cardId } = useParams<UrlParamTypes>()

  const history = useHistory()
  const classes = useStyles()

  const [card, setCard] = useState<AsparkingCardDto>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  useEffect(() => {
    if (cardId !== undefined) {
      cardService
        .cardFindById(Number.parseInt(cardId))
        .then((response) => {
          setCard(response.data)
        })
        .catch()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Paper variant="outlined">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell key="message">{t('pages.history.header.message')}</TableCell>
                  <TableCell key="createdAt">{t('pages.history.header.createdAt')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <ConardHide condition={card?.cardLogs === undefined}>
                  {card?.cardLogs?.map((cardLog) => (
                    <TableRow key={cardLog.id}>
                      <TableCell component="th" scope="row">
                        {t(`pages.cardLog.messages.${cardLog.cardLogType}`, {
                          licensePlate: card?.licensePlate,
                          createdAt: format(new Date(cardLog.createdAt), 'HH:mm, dd.MM.yyyy'),
                          cardNumber: card?.cardNumber,
                        })}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {format(new Date(cardLog.createdAt ?? new Date()), 'dd.MM.yyyy HH:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </ConardHide>
                <ConardHide condition={card?.cardLogs?.length !== 0}>
                  <TableRow key="noData">
                    <TableCell component="th" scope="row" colSpan={2}>
                      {t('pages.common.table.noData')}
                    </TableCell>
                  </TableRow>
                </ConardHide>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 150]}
            component="div"
            count={card?.cardLogs?.length ?? 0}
            rowsPerPage={pageSize}
            labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <Box className={classes.buttonBox}>
        <div className={classes.button}>
          <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
            {t('any.buttons.back')}
          </ConardButton>
        </div>
      </Box>
    </>
  )
}

export default CardHistoryPage
