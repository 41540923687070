import { makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { DriverArrivalDtoDriverPassCauseGateOutEnum } from '../../../api'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

const useStyles = makeStyles({
  typography: {
    marginTop: '10px',
    textAlign: 'center',
  },
  typographyTime: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 600,
  },
})

interface GateOutInfoBoxProps {
  driverPassCauseGateOut: DriverArrivalDtoDriverPassCauseGateOutEnum | undefined
  incomingTrainPlannedTime: string | undefined
  unloadingDateTime: string | undefined
}

export const GateOutInfoBox: FC<GateOutInfoBoxProps> = ({
  driverPassCauseGateOut,
  incomingTrainPlannedTime,
  unloadingDateTime,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  let infoText = ''
  let dateTime = ''
  let dateTimeLabel = t('driverApp.driverArrival.gateOutInfo.arrivalDateTime')

  switch (driverPassCauseGateOut) {
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutTrainStillOnTheWay:
      infoText = t('driverApp.driverArrival.gateOutInfo.trainOnTheWay')
      dateTime = format(new Date(incomingTrainPlannedTime ?? ''), 'dd.MM.yyyy HH:mm')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutTrainStillCustoms:
      infoText = t('driverApp.driverArrival.gateOutInfo.trainInCustomsStop')
      dateTime = format(new Date(incomingTrainPlannedTime ?? ''), 'dd.MM.yyyy HH:mm')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutTrainStillProcessing:
      infoText = t('driverApp.driverArrival.gateOutInfo.trainIsProcessing')
      dateTime = format(new Date(incomingTrainPlannedTime ?? ''), 'dd.MM.yyyy HH:mm')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutTrainStillUnloading:
      infoText = t('driverApp.driverArrival.gateOutInfo.trainIsUnloading')
      dateTime = format(new Date(unloadingDateTime ?? ''), 'dd.MM.yyyy HH:mm')
      dateTimeLabel = t('driverApp.driverArrival.gateOutInfo.unloadingDateTime')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutNotFound:
      infoText = t('driverApp.driverArrival.gateOutInfo.notFound')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutNotFilled:
      infoText = t('driverApp.driverArrival.gateOutInfo.notFilled')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutMoreThanOneFound:
      infoText = t('driverApp.driverArrival.gateOutInfo.moreThanOneFound')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutCoparnNotFound:
      infoText = t('driverApp.driverArrival.gateOutInfo.coparnNotFound')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutCoparnMoreThanOneFound:
      infoText = t('driverApp.driverArrival.gateOutInfo.coparnMoreThanOneFound')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutCoparnAlreadyProcessed:
      infoText = t('driverApp.driverArrival.gateOutInfo.coparnAlreadyProcessed')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutCustomsStop:
      infoText = t('driverApp.driverArrival.gateOutInfo.customsStop')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutLockdown:
      infoText = t('driverApp.driverArrival.gateOutInfo.lockdown')
      break
    case DriverArrivalDtoDriverPassCauseGateOutEnum.GateOutSemitrailer:
      infoText = t('driverApp.driverArrival.gateOutInfo.semitrailer')
      break
    default:
      break
  }

  return (
    <>
      {infoText && (
        <>
          <Typography className={classes.typography}>{infoText}</Typography>
          {dateTime && (
            <Typography className={classes.typographyTime}>
              {dateTimeLabel} <span className={classes.boldText}>{dateTime}</span>
            </Typography>
          )}
        </>
      )}
    </>
  )
}
