import React, { FC } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CdCargoTemplateDto } from '../../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../../enums/Mode'
import ConardTextField from '../../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../../components/ConardButton'
import { Alert } from '@material-ui/lab'
import ConardHide from '../../../components/ConardHide'
import { cleanObject } from '../../../utils/utils'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import cdCargoExportConfigurationService from '../../../services/CdCargoExportConfigurationService'

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface CdCargoExportConfigurationEditFormProps {
  mode: Mode
  exportConfig?: CdCargoTemplateDto
}

const CdCargoExportConfigurationEditForm: FC<CdCargoExportConfigurationEditFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { exportConfig, mode } = props

  const yupSchema = yup.object().shape({
    name: yup.string().required(t('pages.cdCargoExportConfig.validation.name')),
    template: yup.string().required(t('pages.cdCargoExportConfig.validation.xml')),
  })

  const { handleSubmit, control, errors } = useForm<CdCargoTemplateDto>({
    resolver: yupResolver(yupSchema),
  })

  const save = (data: CdCargoTemplateDto) => {
    if (mode === Mode.Create) {
      cdCargoExportConfigurationService
        .create(cleanObject(data))
        .then(() => {
          history.push('/export-config/cd-cargo')
        })
        .catch()
    } else {
      cdCargoExportConfigurationService
        .update(exportConfig?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/export-config/cd-cargo')
        })
        .catch()
    }
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create
          ? t('pages.cdCargoExportConfig.create.title')
          : t('pages.cdCargoExportConfig.update.title')}
      </Typography>
      <ConardHide condition={mode === Mode.Create || exportConfig !== undefined}>
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      </ConardHide>
      <ConardHide condition={mode === Mode.Update && exportConfig === undefined}>
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="name"
                name="name"
                type="text"
                label={t('pages.cdCargoExportConfig.form.name')}
                defaultValue={exportConfig?.name}
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" color="primary" align="center">
                {t('pages.cdCargoExportConfig.form.xml')}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ConardTextField
                id="template"
                name="template"
                type="text"
                label={t('pages.cdCargoExportConfig.form.xml')}
                defaultValue={exportConfig?.template ?? ''}
                multiline
                fullWidth
                error={!!errors.template}
                helperText={errors.template ? errors.template.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </ConardHide>
    </>
  )
}

export default CdCargoExportConfigurationEditForm
