import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { CompanyDto, InvoiceDataRequestDto, InvoiceDataRequestDtoInvoiceTypeEnum } from '../../../api'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import ConardSelect from '../../../components/ConardSelect'
import ConardButton from '../../../components/ConardButton'
import companyService from '../../../services/CompanyService'
import invoiceService from '../../../services/InvoiceService'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: '8vh',
      width: '70vw',
      margin: 'auto',
      minWidth: '300px',
    },
    typographyLabel: {
      width: '50vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

const InvoicePage: FC = () => {
  const { t } = useTranslation()
  const prefix = process.env.REACT_APP_CONARD_TERMINAL
  const classes = useStyles()

  const invoiceSchema = yup.object().shape({
    company: yup.string().not(['NOT-SELECTED'], t('pages.invoice.validations.company')),
    parentCompany: yup.string().not(['NOT-SELECTED'], t('pages.invoice.validations.parentCompany')),
    invoiceType: yup.string().not(['NOT-SELECTED'], t('pages.invoice.validations.invoiceType')),
  })

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(invoiceSchema),
    reValidateMode: 'onBlur',
  })

  const [parentCompanies, setParentCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [allCompanies, setAllCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [invoice, setInvoice] = useState({
    invoiceType: 'NOT-SELECTED',
    company: 'NOT-SELECTED',
    parentCompany: 'NOT-SELECTED',
    dateTimeFrom: new Date(),
    dateTimeTo: new Date(),
  })

  const getParentCompanies = () => {
    companyService.findAllParentCompanies().then((response) => {
      setParentCompanies(response.data.content)
    })
  }

  const getAllCompanies = () => {
    companyService.findAll(undefined, undefined, '', '').then((response) => {
      setCompanies(response.data.content)
      setAllCompanies(response.data.content)
    })
  }

  const onChangeCompany = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isNaN(parseInt(event.target.value))) {
      setCompanies(allCompanies)
    }
    control.setValue(
      'parentCompany',
      companies?.find((company) => company.id === parseInt(event.target.value))?.parentCompanyId ?? 'NOT-SELECTED'
    )
    setInvoice({
      ...invoice,
      parentCompany:
        companies?.find((company) => company.id === parseInt(event.target.value))?.parentCompanyId?.toString() ??
        'NOT-SELECTED',
      company: event.target.value,
    })
  }

  const onChangeParentCompany = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    control.setValue('company', 'NOT-SELECTED')
    if (isNaN(parseInt(event.target.value))) {
      setCompanies(allCompanies)
    } else {
      setCompanies(allCompanies?.filter((company) => company.parentCompanyId === parseInt(event.target.value)))
    }
    setInvoice({
      ...invoice,
      company: 'NOT-SELECTED',
      parentCompany: event.target.value,
    })
  }

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setInvoice({
      ...invoice,
      dateTimeFrom: date || new Date(),
    })
    control.setValue('dateTimeFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setInvoice({
      ...invoice,
      dateTimeTo: date || new Date(),
    })
    control.setValue('dateTimeTo', date)
  }

  const onSubmit = (values: InvoiceDataRequestDto) => {
    const dateFrom: Date | undefined = values?.dateTimeFrom ? new Date(values?.dateTimeFrom) : undefined
    dateFrom?.setHours(0, 0, 0)

    const dateTo: Date | undefined = values?.dateTimeTo ? new Date(values?.dateTimeTo) : undefined
    dateTo?.setHours(23, 59, 59)

    values = {
      ...values,
      dateTimeFrom: dateFrom?.toISOString(),
      dateTimeTo: dateTo?.toISOString(),
      company: {
        id: parseInt(invoice.company),
        parentCompanyId: parseInt(invoice.parentCompany),
        name: '',
        companyIdentificationNumber: '',
        companyTaxNumber: '',
      },
    }
    invoiceService.generateInvoice(values, { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = response.headers.filename
      a.click()
    })
  }

  useEffect(() => {
    getParentCompanies()
    getAllCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.invoice.labels.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          className={classes.container}
          direction="row"
          spacing={7}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <ConardSelect
              id="invoiceType"
              name="invoiceType"
              label={t('pages.invoice.form.invoiceType')}
              defaultValue="NOT-SELECTED"
              control={control}
              error={!!errors.invoiceType}
              helperText={errors.invoiceType ? errors.invoiceType.message : ' '}
            >
              <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
              {Object.values(InvoiceDataRequestDtoInvoiceTypeEnum)
                .filter((invoiceType) => invoiceType.startsWith(prefix ?? ''))
                .map((invoiceType) => (
                  <MenuItem key={invoiceType} value={invoiceType}>
                    {t(`enum.invoiceType.${invoiceType}`)}
                  </MenuItem>
                ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <ConardSelect
              id="parentCompany"
              name="parentCompany"
              label={t('pages.invoice.form.parentCompany')}
              defaultValue="NOT-SELECTED"
              value={invoice?.parentCompany}
              control={control}
              error={!!errors.parentCompany}
              helperText={errors.parentCompany ? errors.parentCompany.message : ' '}
              onChange={(event) => onChangeParentCompany(event)}
            >
              <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
              {parentCompanies?.map((parentCompany) => (
                <MenuItem key={parentCompany.id} value={parentCompany.id}>
                  {parentCompany.name}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <ConardSelect
              id="company"
              name="company"
              label={t('pages.invoice.form.company')}
              defaultValue="NOT-SELECTED"
              control={control}
              value={invoice.company}
              error={!!errors.company}
              helperText={errors.company ? errors.company.message : ' '}
              onChange={(event) => onChangeCompany(event)}
            >
              <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
              {companies?.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Controller
              name="dateTimeFrom"
              control={control}
              defaultValue={invoice.dateTimeFrom}
              render={() => (
                <DatePicker
                  format="dd.MM.yyyy"
                  value={invoice.dateTimeFrom}
                  onChange={(value) => {
                    dateFromOnChange(value)
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
            <Controller
              name="dateTimeTo"
              control={control}
              defaultValue={invoice.dateTimeTo}
              render={() => (
                <DatePicker
                  format="dd.MM.yyyy"
                  value={invoice.dateTimeTo}
                  onChange={(value) => {
                    dateToOnChange(value)
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                />
              )}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className={classes.button}>
              <ConardButton conardVariant="dark" type="submit">
                {t('pages.invoice.form.generate')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default InvoicePage
