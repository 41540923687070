import { Grid, MenuItem } from '@material-ui/core'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CoparnSearchDto, CoparnSearchDtoCoparnStatusEnum, ShipownerDto } from '../../api'
import ConardSelect from '../../components/ConardSelect'
import shipownerService from '../../services/ShipownerService'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import ConardTextField from '../../components/ConardTextField'
import { ConfigContext } from '../../context/ConfigContext'

interface CoparnFilterProps {
  coparnFilter: (data: CoparnSearchDto) => void
}

export const GateOutCoparnFilter: FC<CoparnFilterProps> = ({ coparnFilter }) => {
  const { t } = useTranslation()
  const { generalCarrierReference } = useContext(ConfigContext)
  const [shipowners, setShipowners] = useState<ShipownerDto[]>([])

  const { handleSubmit, control } = useForm<CoparnSearchDto>({})

  const getShipowners = () => {
    shipownerService.findAll(0, 100, undefined).then((response) => {
      setShipowners(response.data.content ?? [])
    })
  }

  useEffect(() => {
    getShipowners()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(coparnFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <ConardTextField
            id="medlogReference"
            name="medlogReference"
            label={generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef')}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <ConardSelect
            id="shipowner.id"
            name="shipowner.id"
            label={t('entity.shipowner.fields.name')}
            defaultValue="NOT-SELECTED"
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {shipowners.map((shipowner) => (
              <MenuItem key={shipowner.id} value={shipowner.id}>
                {shipowner.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
          <ConardSelect
            id="coparnStatus"
            name="coparnStatus"
            label={t('pages.gateOut.coparn.status.label')}
            defaultValue="NOT-SELECTED"
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {Object.values(CoparnSearchDtoCoparnStatusEnum).map((coparnStatus) => (
              <MenuItem key={coparnStatus} value={coparnStatus}>
                {t('enum.coparnStatus.' + coparnStatus)}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
