import React, { FC, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TruckMonitoringLogSearchDto, TruckMonitoringLogSearchDtoCheckpointEnum } from '../../api'
import { Controller, useForm } from 'react-hook-form'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import ConardSelect from '../../components/ConardSelect'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '95%',
    },
    searchButton: {
      width: '100%',
      height: '100%',
      minHeight: '55px',
      textAlign: 'center',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface SearchFilterProps {
  truckMonitoringLogFilter: (data: TruckMonitoringLogSearchDto | undefined) => void
}

const TruckMonitoringLogSearchFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { truckMonitoringLogFilter } = props

  const { handleSubmit, control, setValue } = useForm<TruckMonitoringLogSearchDto>({})

  const [arrivedAtDate, setArrivedAtDate] = useState<MaterialUiPickersDate | null>(null)
  const [leftAtDate, setLeftAtDate] = useState<MaterialUiPickersDate | null>(null)

  const dateOnChangeSwitch = (date: MaterialUiPickersDate | null, value: string) => {
    switch (value) {
      case 'arrivedAt':
        setArrivedAtDate(date || new Date())
        setValue('arrivedAt', date)
        break
      case 'leftAt':
        setLeftAtDate(date || new Date())
        setValue('leftAt', date)
        break
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(truckMonitoringLogFilter)}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardTextField
              id="licensePlate"
              name="licensePlate"
              label={t('entity.truckMonitoringLog.fields.licensePlate')}
              control={control}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              id="checkpoint"
              name="checkpoint"
              label={t('entity.truckMonitoringLog.fields.checkpoint')}
              defaultValue="NOT-SELECTED"
              control={control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              {Object.values(TruckMonitoringLogSearchDtoCheckpointEnum).map((checkpoint) => (
                <MenuItem key={checkpoint} value={checkpoint}>
                  {t(`pages.truckMonitoring.table.${checkpoint}`)}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              id="finished"
              name="finished"
              label={t('entity.truckMonitoringLog.fields.status')}
              defaultValue="NOT-SELECTED"
              control={control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('entity.truckMonitoringLog.fields.finished')}</MenuItem>
              <MenuItem value="false">{t('entity.truckMonitoringLog.fields.unfinished')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <Controller
              name="arrivedAt"
              control={control}
              render={() => (
                <DatePicker
                  emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
                  format="dd.MM.yyyy"
                  value={arrivedAtDate}
                  onChange={(value) => {
                    dateOnChangeSwitch(value, 'arrivedAt')
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                />
              )}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <Controller
              name="leftAt"
              control={control}
              render={() => (
                <DatePicker
                  emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
                  format="dd.MM.yyyy"
                  value={leftAtDate}
                  onChange={(value) => {
                    dateOnChangeSwitch(value, 'leftAt')
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                />
              )}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardButton className={classes.searchButton} conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default TruckMonitoringLogSearchFilter
