import React, { FC } from 'react'
import {
  ContainerDtoContainerTypeEnum,
  NotificationDto,
  NotificationDtoNotificationTypeEnum,
  TransitionDto,
  TransitionDtoContainerStateEnum,
  UserDtoRoleEnum,
} from '../../api'
import { Grid, makeStyles, createStyles } from '@material-ui/core'
import ConardButton from '../ConardButton'
import { useTranslation } from 'react-i18next'
import { TransitionMode } from '../../enums/TransitionMode'
import { useConardAuth } from '../../hooks/useConardAuth'

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      width: '14vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    submitAndStore: {
      whiteSpace: 'nowrap',
    },
  })
)

interface ButtonsForTransitionFormProps {
  gateOutMosnov: boolean
  gateInMosnov: boolean
  gateInBrno: boolean
  gateOutBrno: boolean
  isPlannedTransitionNotification: boolean
  parkingDisabled: boolean
  transitionSaveDisabled: boolean
  transitionFormDisabled: boolean
  transitionPositionDisabled: boolean
  normalAndRoadDispatcher: Set<UserDtoRoleEnum>

  transition: TransitionDto
  transitionMode: TransitionMode
  notification: NotificationDto | undefined

  onTakeOutGateOut: () => void
  onStoreTrue: () => void
  onProcessFalse: () => void
  onHandleParkingLot: () => void
  onStoreFalse: () => void
  onClickEdit: () => void
  onProcessTrue: () => void
  onClickBack: () => void
  onCancel?: () => void
  onFinishGo: () => void
  onShowDeleteDialog: () => void
}

export const ButtonsForTransitionForm: FC<ButtonsForTransitionFormProps> = ({
  transition,
  gateOutMosnov,
  onTakeOutGateOut,
  gateInMosnov,
  gateInBrno,
  onStoreTrue,
  gateOutBrno,
  isPlannedTransitionNotification,
  onProcessFalse,
  transitionMode,
  parkingDisabled,
  notification,
  onHandleParkingLot,
  transitionSaveDisabled,
  transitionFormDisabled,
  transitionPositionDisabled,
  onStoreFalse,
  onClickEdit,
  onProcessTrue,
  onClickBack,
  onCancel,
  normalAndRoadDispatcher,
  onFinishGo,
  onShowDeleteDialog,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const auth = useConardAuth()

  const onStoreFalseButtonShow =
    !transitionSaveDisabled &&
    transitionMode !== TransitionMode.NOTIFICATION &&
    !(
      !!notification &&
      notification.notificationType !== NotificationDtoNotificationTypeEnum.GiCheckpoint2WithoutIluCode
    ) &&
    !(
      transitionMode === TransitionMode.GATE_OUT &&
      transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer &&
      transition.containerState === TransitionDtoContainerStateEnum.FinishedGateIn
    ) &&
    !(
      transitionMode === TransitionMode.GATE_IN &&
      transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer
    ) &&
    !gateInMosnov &&
    !gateOutMosnov &&
    !gateInBrno &&
    !gateOutBrno
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {transitionMode === TransitionMode.TRAIN ? (
        <>
          <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" type="submit">
                {t('any.buttons.save')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={onCancel}>
                {t('any.buttons.back')}
              </ConardButton>
            </div>
          </Grid>
        </>
      ) : (
        <>
          {gateOutMosnov && (
            <>
              {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit" onClick={onStoreFalse}>
                      {t('any.buttons.save')}
                    </ConardButton>
                  </div>
                </Grid>
              ) : (
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit" onClick={onTakeOutGateOut}>
                      {t('pages.gateOut.buttons.submitAndThirdCheckpoint')}
                    </ConardButton>
                  </div>
                </Grid>
              )}
            </>
          )}

          {(gateInMosnov || gateInBrno) && (
            <>
              {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
                <>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton conardVariant="dark" type="submit">
                        {t('any.buttons.save')}
                      </ConardButton>
                    </div>
                  </Grid>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={`${classes.submitButton} ${classes.submitAndStore}`}>
                      <ConardButton conardVariant="dark" type="submit" onClick={onStoreTrue}>
                        {t('pages.gateIn.buttons.submitAndStore')}
                      </ConardButton>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={`${classes.submitButton} ${classes.submitAndStore}`}>
                      <ConardButton conardVariant="dark" type="submit" onClick={onStoreTrue}>
                        {t('pages.gateIn.buttons.submitAndStore')}
                      </ConardButton>
                    </div>
                  </Grid>
                </>
              )}
            </>
          )}

          {gateOutBrno && (
            <>
              {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
                <>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton conardVariant="dark" type="submit">
                        {t('any.buttons.save')}
                      </ConardButton>
                    </div>
                  </Grid>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={`${classes.submitButton} ${classes.submitAndStore}`}>
                      <ConardButton conardVariant="dark" type="submit" onClick={onTakeOutGateOut}>
                        {t('pages.gateOut.buttons.submitAndTakeout')}
                      </ConardButton>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                    <div className={`${classes.submitButton} ${classes.submitAndStore}`}>
                      <ConardButton conardVariant="dark" type="submit" onClick={onTakeOutGateOut}>
                        {t('pages.gateOut.buttons.submitAndTakeout')}
                      </ConardButton>
                    </div>
                  </Grid>
                </>
              )}
            </>
          )}

          {transitionMode === TransitionMode.NOTIFICATION && !isPlannedTransitionNotification && (
            <>
              {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit">
                      {t('any.buttons.save')}
                    </ConardButton>
                  </div>
                </Grid>
              )}
              <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                <div className={`${classes.submitButton} ${classes.submitAndStore}`}>
                  <ConardButton conardVariant="dark" type="submit" onClick={onStoreTrue}>
                    {t('pages.gateIn.buttons.submitAndStore')}
                  </ConardButton>
                </div>
              </Grid>
            </>
          )}

          {parkingDisabled &&
            !!notification &&
            (notification.notificationType === NotificationDtoNotificationTypeEnum.GiCheckpoint2 ||
              notification.notificationType === NotificationDtoNotificationTypeEnum.GiCheckpoint2WithoutIluCode) &&
            transitionMode !== TransitionMode.GATE_OUT && (
              <>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" onClick={onHandleParkingLot}>
                      {t('pages.notifications.buttons.parkingLot')}
                    </ConardButton>
                  </div>
                </Grid>
              </>
            )}

          {!!notification &&
            (notification.notificationType === NotificationDtoNotificationTypeEnum.GoCheckpoint2 ||
              notification.notificationType === NotificationDtoNotificationTypeEnum.GateOutCoparn) && (
              <>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit">
                      {t('any.buttons.saveGateOut')}
                    </ConardButton>
                  </div>
                </Grid>
              </>
            )}

          {!!notification &&
            (notification?.notificationType === NotificationDtoNotificationTypeEnum.GiCheckpoint2 ||
              notification?.notificationType === NotificationDtoNotificationTypeEnum.IluReadError) &&
            transitionMode === TransitionMode.GATE_OUT &&
            transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
              <>
                <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit">
                      {t('any.buttons.save')}
                    </ConardButton>
                  </div>
                </Grid>
              </>
            )}

          {onStoreFalseButtonShow && (
            <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" type="submit" onClick={onStoreFalse}>
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
          )}

          {transitionFormDisabled &&
            transitionPositionDisabled &&
            !(
              TransitionDtoContainerStateEnum.FinishedGateIn !== transition.containerState &&
              UserDtoRoleEnum.Operator === auth.getRole()
            ) && (
              <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={onClickEdit}>
                    {t('any.buttons.edit')}
                  </ConardButton>
                </div>
              </Grid>
            )}

          {transition.containerState === TransitionDtoContainerStateEnum.PlannedGateIn &&
            auth.getRole() !== UserDtoRoleEnum.Manager && (
              <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={onProcessFalse}>
                    {t('any.buttons.saveAndProcess')}
                  </ConardButton>
                </div>
              </Grid>
            )}

          {transition.containerState === TransitionDtoContainerStateEnum.PlannedGateIn &&
            auth.getRole() !== UserDtoRoleEnum.Manager && (
              <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={onProcessTrue}>
                    {t(
                      `any.buttons.${
                        TransitionDtoContainerStateEnum.PlannedGateIn === transition.containerState
                          ? 'processAndStore'
                          : 'processAndTakeout'
                      }`
                    )}
                  </ConardButton>
                </div>
              </Grid>
            )}

          <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={onClickBack}>
                {t('any.buttons.back')}
              </ConardButton>
            </div>
          </Grid>

          {auth.hasOneOfRoles(normalAndRoadDispatcher) &&
            (TransitionDtoContainerStateEnum.Before2NdCheckpointGo === transition.containerState ||
              TransitionDtoContainerStateEnum.Before3RdCheckpointGo === transition.containerState) && (
              <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={onFinishGo}>
                    {t('pages.gateOut.buttons.checked')}
                  </ConardButton>
                </div>
              </Grid>
            )}

          {auth.getRole() === UserDtoRoleEnum.Manager && (
            <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" onClick={onShowDeleteDialog}>
                  {t('any.buttons.delete')}
                </ConardButton>
              </div>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
