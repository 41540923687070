import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PageTruckMonitoringLogDto, TruckMonitoringLogDto, TruckMonitoringLogSearchDto } from '../../api'
import { Order } from '../../enums/Order'
import { cleanObject } from '../../utils/utils'
import truckMonitoringService from '../../services/TruckMonitoringService'
import { format } from 'date-fns'
import ConardButton from '../../components/ConardButton'
import ConardHide from '../../components/ConardHide'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TruckMonitoringLogSearchFilter from './TruckMonitoringLogSearchFilter'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ConardProgressBar from '../../components/ConardProgressBar'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof TruckMonitoringLogDto
  label: string
}

const TruckMonitoringLogSearchListPage: FC = () => {
  const { t } = useTranslation()

  const [pageTruckMonitoringLogs, setPageTruckMonitoringLogs] = useState<PageTruckMonitoringLogDto | null>()
  const [truckMonitoringLogs, setTruckMonitoringLogs] = useState<TruckMonitoringLogSearchDto | undefined>(undefined)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof TruckMonitoringLogDto>('arrivedAt')
  const [showFilter, setShowFilter] = useState(true)
  const history = useHistory()

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'licensePlate', label: t('entity.truckMonitoringLog.fields.licensePlate') },
    { id: 'checkpoint', label: t('entity.truckMonitoringLog.fields.checkpoint') },
    { id: 'arrivedAt', label: t('entity.truckMonitoringLog.fields.arrivedAt') },
    { id: 'leftAt', label: t('entity.truckMonitoringLog.fields.leftAt') },
  ]

  useEffect(() => {
    search(truckMonitoringLogs, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof TruckMonitoringLogDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: TruckMonitoringLogSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TruckMonitoringLogSearchDto | undefined, fromFilter: boolean) => {
    setTruckMonitoringLogs(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    truckMonitoringService
      .search(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTruckMonitoringLogs(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.monitoring.history.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={9} lg={10} md={12} sm={12} xs={12}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
              <ConardButton
                conardVariant="light"
                startIcon={<ArrowBackIcon fontSize="small" color="secondary" />}
                onClick={() => {
                  history.push('/entry-and-parking/monitoring')
                }}
              >
                {t('any.buttons.back')}
              </ConardButton>
            </Grid>
            <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
              <ConardButton
                conardVariant="transparent"
                startIcon={<FilterListIcon />}
                endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                onClick={handleFilter}
              >
                {t('any.buttons.filter')}
              </ConardButton>
            </Grid>
          </Grid>
        </Grid>
        <ConardHide condition={!showFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TruckMonitoringLogSearchFilter truckMonitoringLogFilter={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={pageTruckMonitoringLogs === undefined}>
                    {pageTruckMonitoringLogs?.content?.map((log) => (
                      <TableRow className={log?.leftAt !== null ? classes.disabledTableRow : ''} key={log.id}>
                        <TableCell component="th" scope="row">
                          {log.licensePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t(`pages.truckMonitoring.table.${log.checkpoint}`)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {log.arrivedAt ? format(new Date(log.arrivedAt ?? new Date()), 'dd.MM.yyyy, HH:mm') : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {log.leftAt ? format(new Date(log.leftAt ?? new Date()), 'dd.MM.yyyy, HH:mm') : ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {pageTruckMonitoringLogs?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTruckMonitoringLogs} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTruckMonitoringLogs?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
export default TruckMonitoringLogSearchListPage
