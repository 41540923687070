import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { CarrierDto, CarrierSearchDto, PageCarrierDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import ConardHide from '../../components/ConardHide'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { cleanObject } from '../../utils/utils'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import carrierService from '../../services/CarrierService'
import { format } from 'date-fns'
import CarrierFilter from './CarrierFilter'
import ConardProgressBar from '../../components/ConardProgressBar'
import moment from 'moment'
import { Group } from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newCardButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof CarrierDto
  label: string
}

const CarrierListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [carriers, setCarriers] = useState<PageCarrierDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof CarrierDto>('name')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<CarrierSearchDto | undefined>(undefined)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.carrier.fields.name') },
    { id: 'contractType', label: t('entity.carrier.fields.contractType') },
    { id: 'contractBeginning', label: t('entity.carrier.fields.contractBeginning') },
    { id: 'contractEnd', label: t('entity.carrier.fields.contractEnd') },
    { id: 'phone', label: t('entity.carrier.fields.phone') },
    { id: 'company', label: t('entity.carrier.fields.company') },
    { id: 'id', label: '' },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof CarrierDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: CarrierSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: CarrierSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    carrierService
      .carrierSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setCarriers(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.carrier.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={10} lg={9} md={6} sm={6} xs={6} style={{ float: 'right' }}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/carrier/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
        <ConardHide condition={!showFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CarrierFilter carrierFilter={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell colSpan={4} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={carriers === undefined}>
                    {carriers?.content?.map((carrier) => (
                      <TableRow
                        className={
                          moment().isAfter(carrier?.contractEnd ?? new Date(), 'day') ||
                          moment().isBefore(carrier?.contractBeginning ?? new Date(), 'day')
                            ? classes.disabledTableRow
                            : ''
                        }
                        key={carrier.id}
                      >
                        <TableCell component="th" scope="row">
                          {carrier.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {carrier.contractType ? t(`enum.contractType.${carrier.contractType}`) : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {carrier.contractBeginning ? format(new Date(carrier.contractBeginning), 'dd.MM.yyyy') : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {carrier.contractEnd ? format(new Date(carrier.contractEnd), 'dd.MM.yyyy') : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {carrier?.phone}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {carrier.company?.name}
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<Group />}
                            onClick={() => history.push('/entry-and-parking/carrier/' + carrier.id + '/driver')}
                          >
                            {t('pages.entryAndParking.drivers.title')}
                          </ConardButton>
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<EditIcon />}
                            onClick={() => history.push('/entry-and-parking/carrier/' + carrier.id + '/license-plate')}
                          >
                            {t('pages.entryAndParking.licensePlate.title')}
                          </ConardButton>
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<EditIcon />}
                            onClick={() => history.push('/entry-and-parking/carrier/' + carrier.id + '/card')}
                          >
                            {t('pages.entryAndParking.card.title')}
                          </ConardButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => history.push('/entry-and-parking/carrier/update/' + carrier.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {carriers?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={9}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!carriers} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={carriers?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/carrier/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CarrierListPage
