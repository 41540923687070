import React, { FC, useEffect, useState } from 'react'
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import { RootState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { setShowNotifications } from '../../redux/notification/notificationSlice'
import { PageNotificationDto, UserDtoRoleEnum } from '../../api'
import { useConardAuth } from '../../hooks/useConardAuth'

const useStyles = makeStyles({
  userAreaGrid: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userAvatarGridItem: {
    width: '100%',
    textAlign: 'center',
  },
  userAvatarWrapper: {
    marginTop: '2vh',
  },
  userInfoTextWrapper: {
    textAlign: 'center',
    marginTop: '2vh',
    marginBottom: '2vh',
  },
  logoutButtonWrapper: {
    marginBottom: '2vh',
  },
  notificationsIcon: {
    cursor: 'pointer',
  },
  notificationListItem: {
    justifyContent: 'center',
    width: '100%',
    paddingTop: '2vh',
    paddingBottom: '2vh',
  },
})

const MainMenuUserArea: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const showNotifications = useSelector<RootState, boolean>((state) => state.notification.showNotifications)
  const notifications = useSelector<RootState, PageNotificationDto | undefined>(
    (state) => state.notification.notifications
  )
  const notificationsDisabled = useSelector<RootState, boolean>((state) => state.notification.notificationsDisabled)
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
  const [isDispatcher, setIsDispatcher] = useState<boolean>(false)

  const classes = useStyles()

  const auth = useConardAuth()

  const logout = () => {
    auth?.clearAuthentication()
  }

  const getLocalizedRole = (): string => {
    const role = auth.getRole()
    if (role === null) {
      return 'Unknown'
    }
    return t(`enum.role.${role}`)
  }

  const handleShowNotifications = () => {
    dispatch(setShowNotifications(!showNotifications))
  }

  useEffect(() => {
    const role = auth.getRole()
    setIsDispatcher(
      new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad, UserDtoRoleEnum.DispatcherTrain]).has(role)
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container className={classes.userAreaGrid}>
        <Grid item className={classes.userAvatarGridItem}>
          <Divider light />
          {isDispatcher && (
            <ListItem button className={classes.notificationListItem} onClick={handleShowNotifications}>
              {notificationsDisabled ? (
                <NotificationsIcon className={classes.notificationsIcon} color="disabled" fontSize="large" />
              ) : (
                <Badge
                  badgeContent={notifications?.totalElements}
                  color="error"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <NotificationsIcon className={classes.notificationsIcon} color="secondary" fontSize="large" />
                </Badge>
              )}
            </ListItem>
          )}
          {!isDispatcher && <AccountBoxIcon fontSize="large" />}
        </Grid>

        <Grid item>
          <div className={classes.userInfoTextWrapper}>
            <Typography display="block" variant="caption" color="secondary">
              {auth.getFirstName()} {auth.getSurname()}
            </Typography>
            <Typography display="block" variant="caption" color="secondary">
              {getLocalizedRole()}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.logoutButtonWrapper}>
            <ConardButton conardVariant="white" onClick={() => setLogoutDialogOpen(true)}>
              {t('logout.title')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
      <Dialog maxWidth="xs" open={logoutDialogOpen} onClose={() => setLogoutDialogOpen(false)}>
        <DialogTitle id="confirmation-dialog-title">{t('logout.confirmation.title')}</DialogTitle>
        <DialogContent dividers>{t('logout.confirmation.message')}</DialogContent>
        <DialogActions>
          <ConardButton conardVariant="transparent" onClick={() => setLogoutDialogOpen(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" onClick={logout} autoFocus>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MainMenuUserArea
