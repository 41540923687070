import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import {
  CarrierShortDto,
  CompanyDto,
  DriverShortDto,
  LicensePlateDto,
  NotificationDto,
  PageTransitionSearchListDto,
  ShipownerDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionSearchListSearchDto,
} from '../../api'
import gateOutService from '../../services/GateOutService'
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { setTransition, transitionDefaults } from '../../redux/transition/transitionSlice'
import { useAppDispatch } from '../../redux/store'
import companyService from '../../services/CompanyService'
import shipownerService from '../../services/ShipownerService'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import WarningAmberIcon from '@material-ui/icons/Warning'
import ConardProgressBar from '../ConardProgressBar'
import { OutputOutlined } from '@mui/icons-material'
import ConardTransitionSearchIcon from '../ConardTransitionSearchIcon'
import { GateOutFilter } from './GateOutFilter'
import { GateOutCoparnDriverForm } from './GateOutCoparnDriverForm'
import { FormProvider, useForm } from 'react-hook-form'
import ConardTooltip from '../ConardTooltip'

interface HeadCell {
  id: string
  label: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      minWidth: '80vh',
    },
    gridContainer: {
      marginTop: '2vh',
    },
    filterContainer: {
      marginTop: '10vh',
    },
    typographyLabel: {
      width: '50vw',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px 0',
      float: 'left',
    },
    gateOutIcon: {
      padding: '1vh 2vw 1vh 0vw',
    },
    noData: {
      textAlign: 'center',
    },
    buttonCell: {
      width: '7vw',
    },
    temporaryCustomer: {
      color: theme.palette.warning.main,
    },
  })
)

interface GateOutProps {
  notification?: NotificationDto
  driver?: DriverShortDto
  carrier?: CarrierShortDto
  frontLicensePlate?: LicensePlateDto
  rearLicensePlate?: LicensePlateDto
}

const GateOutSimplePrepareList: FC<GateOutProps> = (props) => {
  const { driver, carrier, frontLicensePlate, rearLicensePlate, notification } = props

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)
  const [showFilter, setShowFilter] = useState(true)

  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('iluCode')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [blockSearch, setBlockSearch] = useState(false)

  const [transitionPage, setTransitionPage] = useState<PageTransitionSearchListDto | null>(null)
  const [iluCode, setIluCode] = useState<string | undefined>()

  const [companyId, setCompanyId] = useState<number | undefined>(undefined)
  const [shipownerId, setShipownerId] = useState<number | undefined>(undefined)
  const [repleted, setRepleted] = useState<boolean | undefined>(undefined)

  const headCells: HeadCell[] = [
    { id: 'container.iluCode', label: t('pages.gateOut.form.iluCode.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'container.company.name', label: t('pages.gateOut.form.company.label') },
  ]

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const getAllShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
    })
  }

  const loadData = () => {
    setBlockSearch(true)
    setTransitionPage(null)
    const searchDto: TransitionSearchListSearchDto = {
      repleted: repleted,
      container: {
        iluCode: iluCode,
        shipowner: {
          id: shipownerId,
        },
        company: {
          id: companyId,
        },
      },
    }
    gateOutService
      .simpleTransitionList(page, pageSize, [orderBy + ',' + order], searchDto)
      .then((response) => {
        setTransitionPage(response.data)
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const goToSingleGateOut = (id: number) => {
    dispatch(setTransition(transitionDefaults.shippingContainer))
    history.push('/gate-out/single-container/' + id, {
      notification: props.notification,
      driver: driver,
      carrier: carrier,
      frontLicensePlate: frontLicensePlate,
      rearLicensePlate: rearLicensePlate,
    })
  }

  const onChangeIluCode = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIluCode((event.target.value as unknown as string) ?? undefined)
  }

  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompanyId((event.target.value as unknown as number) ?? undefined)
  }

  const onChangeShipowner = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShipownerId((event.target.value as unknown as number) ?? undefined)
  }

  const onChangeRepleted = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRepleted((event.target.value as unknown as number) === undefined ? undefined : event.target.value === 'true')
  }

  useEffect(() => {
    getAllCompanies()
    getAllShipowners()
    loadData()
  }, [order, orderBy, page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  const formMethods = useForm()

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.gateOut.headers.singleContainerList')}
      </Typography>
      <Grid container direction="row" alignItems="center" spacing={2} className={classes.filterContainer}>
        {!!props.driver && (
          <Grid item xs={12}>
            <FormProvider {...formMethods}>
              <form>
                <GateOutCoparnDriverForm
                  notification={notification}
                  locationDriverShort={driver}
                  locationCarrierShort={carrier}
                  locationFrontLicensePlate={frontLicensePlate}
                  locationRearLicensePlate={rearLicensePlate}
                />
              </form>
            </FormProvider>
          </Grid>
        )}
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={() => setShowFilter(!showFilter)}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        {showFilter && (
          <GateOutFilter
            companies={companies}
            companyId={companyId}
            disabled={blockSearch}
            iluCode={iluCode}
            shipownerId={shipownerId}
            shipowners={shipowners}
            onChangeCompanyId={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeCompany(event)
            }
            onChangeIluCode={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeIluCode(event)
            }
            onChangeRepleted={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeRepleted(event)
            }
            onChangeShipOwner={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              onChangeShipowner(event)
            }
            onLoadData={loadData}
          />
        )}
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item xl={12} lg={12} md={11} sm={11} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : Order.Asc}
                          onClick={onSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell colSpan={2} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transitionPage?.content?.map((transition) => (
                    <TableRow key={transition.id}>
                      <TableCell>{transition.iluCode}</TableCell>
                      <TableCell>
                        {transition.repleted
                          ? t('pages.gateIn.form.repleted.checked')
                          : t('pages.gateIn.form.repleted.unchecked')}
                      </TableCell>
                      <TableCell className={transition.customerName ? classes.temporaryCustomer : ''}>
                        {transition.customerName ? (
                          <ConardTooltip title={t('pages.gateIn.form.temporaryCustomer.tooltip')}>
                            {transition.customerName}
                          </ConardTooltip>
                        ) : (
                          transition.companyName
                        )}
                      </TableCell>
                      <TableCell>
                        <ConardTransitionSearchIcon
                          transitionSearchListDto={{
                            containerType: transition.containerType,
                            semitrailerType: transition.semitrailerType,
                            shippingContainerType: transition.shippingContainerType,
                          }}
                          damaged={transition.damaged ?? false}
                          containerType={transition.containerType}
                        />
                      </TableCell>
                      <TableCell className={classes.buttonCell}>
                        {transition.transitionCustomsInfo?.state !== TransitionCustomsInfoSearchListDtoStateEnum.Stop &&
                          !transition.lockdown && (
                            <div>
                              <ConardButton
                                conardVariant="light"
                                startIcon={<OutputOutlined />}
                                onClick={() => goToSingleGateOut(transition.id ?? -1)}
                              >
                                {t('pages.gateOut.buttons.gateOut')}
                              </ConardButton>
                            </div>
                          )}

                        {transition.transitionCustomsInfo?.state ===
                          TransitionCustomsInfoSearchListDtoStateEnum.Stop && (
                          <ConardButton conardVariant="red" startIcon={<WarningAmberIcon />} disabled>
                            {t('pages.gateOut.buttons.customsStop')}
                          </ConardButton>
                        )}

                        {transition.transitionCustomsInfo?.state !== TransitionCustomsInfoSearchListDtoStateEnum.Stop &&
                          transition.lockdown && (
                            <ConardButton conardVariant="red" startIcon={<WarningAmberIcon />} disabled>
                              {t('pages.gateOut.buttons.lockdown')}
                            </ConardButton>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {!!transitionPage && (transitionPage?.totalElements === 0 || !transitionPage?.content) && (
                    <TableRow>
                      <TableCell className={classes.noData} colSpan={5}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!transitionPage} />
            </TableContainer>
            <TablePagination
              component="div"
              count={transitionPage?.totalElements || 0}
              onPageChange={onPage}
              page={page}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              rowsPerPageOptions={[10, 20, 50, 150]}
              onRowsPerPageChange={onRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default GateOutSimplePrepareList
