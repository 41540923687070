import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { AsparkingCardSearchDto, PageAsparkingCardDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import ConardHide from '../../components/ConardHide'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { cleanObject } from '../../utils/utils'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import cardService from '../../services/CardService'
import { format } from 'date-fns'
import CardFilter from './CardFilter'
import HistoryIcon from '@material-ui/icons/History'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newCardButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface UrlParamTypes {
  id?: string
}

interface HeadCell {
  id: string
  label: string
}

const CardListPage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const history = useHistory()

  const [cards, setCards] = useState<PageAsparkingCardDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<string>('cardNumber')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<AsparkingCardSearchDto | undefined>(undefined)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'cardNumber', label: t('entity.card.fields.cardNumber') },
    { id: 'licensePlate', label: t('entity.card.fields.licensePlate') },
    { id: 'licensePlate.firstName', label: t('entity.licensePlate.fields.firstName') },
    { id: 'licensePlate.surname', label: t('entity.licensePlate.fields.surname') },
    { id: 'validFrom', label: t('entity.card.fields.validFrom') },
    { id: 'validTo', label: t('entity.card.fields.validTo') },
    { id: 'disabled', label: t('entity.card.fields.disabled') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: AsparkingCardSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: AsparkingCardSearchDto | undefined, fromFilter: boolean) => {
    if (id !== undefined) {
      data = {
        ...data,
        carrierId: Number.parseInt(id),
      }
    }
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    cardService
      .cardSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setCards(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.card.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={10} lg={9} md={6} sm={6} xs={6} style={{ float: 'right' }}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/carrier/' + id + '/card/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<ArrowBackIcon fontSize="small" color="secondary" />}
              onClick={() => {
                history.push('/entry-and-parking/carrier')
              }}
            >
              {t('any.buttons.back')}
            </ConardButton>
          </div>
        </Grid>
        <ConardHide condition={!showFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CardFilter cardsFilter={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={cards === undefined}>
                    {cards?.content?.map((card) => (
                      <TableRow className={card?.disabled ? classes.disabledTableRow : ''} key={card.id}>
                        <TableCell component="th" scope="row">
                          {card.cardNumber}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {card.licensePlate?.licensePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {card.licensePlate?.firstName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {card.licensePlate?.surname}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {format(new Date(card.validFrom ?? new Date()), 'dd.MM.yyyy')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {format(new Date(card.validTo ?? new Date()), 'dd.MM.yyyy')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {card.disabled ? t('pages.card.table.disabled') : t('pages.card.table.active')}
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<EditIcon />}
                            onClick={() => history.push('/entry-and-parking/carrier/' + id + '/card/update/' + card.id)}
                          >
                            {t('any.buttons.edit')}
                          </ConardButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              history.push('/entry-and-parking/carrier/' + id + '/card/history/' + card.id)
                            }
                          >
                            <HistoryIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {cards?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={10}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!cards} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={cards?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/carrier/' + id + '/card/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CardListPage
