import { makeStyles, createStyles, InputAdornment, Hidden } from '@material-ui/core'
import { DateTimePicker, DateTimePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { FC } from 'react'
import clsx from 'clsx'
import { Event } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { cs, enGB } from 'date-fns/locale'
import { useConardAuth } from '../../hooks/useConardAuth'

interface ConardDateTimePickerAdditionalProps {
  hideAdornmentDown?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

export type ConardDateTimePickerProps = DateTimePickerProps & ConardDateTimePickerAdditionalProps

export const ConardDateTimePicker: FC<ConardDateTimePickerProps> = (props) => {
  const classes = useStyles()
  const auth = useConardAuth()

  const getLanguage = () => {
    switch (auth.getLanguage()) {
      case 'en':
        return enGB
      default:
        return cs
    }
  }

  const getAdornment = () => {
    const adornment = (
      <InputAdornment position="start">
        <Event />
      </InputAdornment>
    )
    switch (props.hideAdornmentDown) {
      case 'xl':
        return <Hidden xlDown>{adornment}</Hidden>
      case 'lg':
        return <Hidden lgDown>{adornment}</Hidden>
      case 'md':
        return <Hidden mdDown>{adornment}</Hidden>
      case 'sm':
        return <Hidden smDown>{adornment}</Hidden>
      case 'xs':
        return <Hidden xsDown>{adornment}</Hidden>
      default:
        return adornment
    }
  }

  return (
    <MuiPickersUtilsProvider locale={getLanguage()} utils={DateFnsUtils}>
      <DateTimePicker
        format="dd.MM.yyyy HH:mm"
        value={props.value}
        disabled={props.disabled}
        placeholder={props.placeholder}
        disablePast={props.disablePast}
        ampm={props.ampm}
        onChange={props.onChange}
        minDateMessage=""
        InputProps={{
          disableUnderline: true,
          startAdornment: getAdornment(),
          classes: {
            input: classes.datePickerInputInput,
            root: classes.datePickerInputRoot,
          },
        }}
        className={clsx(classes.datePickerRoot, props.className)}
      />
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    datePickerInputRoot: {
      width: '60%',
      height: '56px',
      margin: 'auto',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: 12,
      '&:hover': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1.5px',
      },
    },
  })
)
