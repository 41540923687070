import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { UserDtoRoleEnum } from '../../../api'
import ConardButton from '../../../components/ConardButton'
import { makeStyles } from '@material-ui/core/styles'
import ConardHide from '../../../components/ConardHide'
import { Alert } from '@material-ui/lab'
import { LetterDial } from './LetterDial'
import { ColumnDial } from './ColumnDial'
import { RowDial } from './RowDial'
import { FloorDial } from './FloorDial'
import { Coordinate } from './Coordinate'
import { ConfigContext } from '../../../context/ConfigContext'
import { useConardAuth } from '../../../hooks/useConardAuth'

interface StoreFormProps {
  title: string
  name: string
  onStore: (storeInputParam: StoreInputParams) => void
}

export interface StoreInputParams {
  sectorLetter?: string
  sectorColumn?: number
  sectorRow?: number
  sectorFloor?: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    errorBorder: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    controls: {
      position: 'fixed',
      bottom: 0,
      left: '10vw',
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    controlsOperator: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    validationAlert: {
      marginBottom: '1vh',
    },
    coordinates: {
      marginTop: '5vh',
      marginBottom: '5vh',
    },
    submitButtonBig: {
      borderRadius: 20,
      width: '100%',
      height: '100%',
      fontSize: 'x-large',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iluTitle: {
      textAlign: 'center',
    },
  })
)

const showsDialsInitial = {
  letterDial: false,
  columnDial: false,
  rowDial: false,
  floorDial: false,
  submit: false,
}

const StoreForm: FC<StoreFormProps> = (props) => {
  const { title, name, onStore } = props

  const { t } = useTranslation()
  const history = useHistory()
  const { coordinates } = useContext(ConfigContext)

  const [storeInputParam, setStoreInputParam] = useState<StoreInputParams>({})
  const [validationError, setValidationError] = useState(false)
  const [showDials, setShowDials] = useState(showsDialsInitial)

  const classes = useStyles()
  const auth = useConardAuth()

  const switchDials = (to: string) => {
    setShowDials({
      letterDial: false,
      columnDial: false,
      rowDial: false,
      floorDial: false,
      submit: false,
      [to]: true,
    })
  }

  const validateAndStore = () => {
    if (coordinates.sectorLetterCount !== 0 && !storeInputParam.sectorLetter) {
      setValidationError(true)
    } else {
      onStore(storeInputParam)
    }
  }

  useEffect(() => {
    if (coordinates.sectorLetterCount !== 0) {
      switchDials('letterDial')
    } else {
      switchDials('columnDial')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Typography variant="h3" color="primary" className={classes.iluTitle}>
        {name}
      </Typography>
      <ConardHide condition={!validationError}>
        <Alert severity="error" className={classes.validationAlert}>
          {t('pages.containerStoreForm.validations.sector')}
        </Alert>
      </ConardHide>
      <Grid container direction="row" className={classes.coordinates}>
        <Grid item sm={1} md={3} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={6} lg={6} xl={4}>
          <Grid container direction="row">
            {coordinates.sectorLetterCount !== 0 && (
              <Coordinate
                label={t('pages.gateIn.form.sector.label')}
                value={storeInputParam.sectorLetter}
                active={showDials.letterDial}
                switchDials={switchDials}
                dialName="letterDial"
              />
            )}
            {coordinates.maxSectorColumn !== 0 && (
              <Coordinate
                label={
                  coordinates.sectorLetterCount === 0
                    ? t('pages.gateIn.form.sector.label')
                    : t('pages.gateIn.form.sectorColumn.label')
                }
                value={storeInputParam.sectorColumn}
                active={showDials.columnDial}
                switchDials={switchDials}
                dialName="columnDial"
              />
            )}
            {coordinates.maxSectorRow !== 0 && (
              <Coordinate
                label={t('pages.gateIn.form.sectorRow.label')}
                value={storeInputParam.sectorRow}
                active={showDials.rowDial}
                switchDials={switchDials}
                dialName="rowDial"
              />
            )}
            {coordinates.maxSectorFloor !== 0 && (
              <Coordinate
                label={t('pages.gateIn.form.sectorFloor.label')}
                value={storeInputParam.sectorFloor}
                active={showDials.floorDial}
                switchDials={switchDials}
                dialName="floorDial"
              />
            )}
          </Grid>
        </Grid>
        <Grid item sm={1} md={3} lg={3} xl={4} />
      </Grid>
      <ConardHide condition={!showDials.letterDial}>
        <LetterDial
          sectorLetter={storeInputParam.sectorLetter}
          setStoreInputParam={setStoreInputParam}
          switchDials={switchDials}
        />
      </ConardHide>
      <ConardHide condition={!showDials.columnDial}>
        <ColumnDial
          column={storeInputParam.sectorColumn}
          setStoreInputParam={setStoreInputParam}
          switchDials={switchDials}
        />
      </ConardHide>
      <ConardHide condition={!showDials.rowDial}>
        <RowDial row={storeInputParam.sectorRow} setStoreInputParam={setStoreInputParam} switchDials={switchDials} />
      </ConardHide>
      <ConardHide condition={!showDials.floorDial}>
        <FloorDial
          floor={storeInputParam.sectorFloor}
          setStoreInputParam={setStoreInputParam}
          switchDials={switchDials}
        />
      </ConardHide>
      <ConardHide condition={!showDials.submit}>
        <Grid container style={{ marginTop: '10vh' }}>
          <Grid item sm={2} />
          <Grid item xs={12} sm={8}>
            <Button onClick={validateAndStore} variant="contained" color="primary" className={classes.submitButtonBig}>
              {t('any.buttons.save')}
            </Button>
          </Grid>
          <Grid item sm={2} />
        </Grid>
      </ConardHide>
      <div className={auth.getRole() === UserDtoRoleEnum.Operator ? classes.controlsOperator : classes.controls}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xl={5} lg={5} md={5} sm={10} xs={10}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={validateAndStore}>
                {t('any.buttons.save')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={10} xs={10}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                {t('any.buttons.back')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
export default StoreForm
