import React, { FC, useEffect, useState } from 'react'
import ConardButton from '../../../components/ConardButton'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardTextField from '../../../components/ConardTextField'
import ConardSelect from '../../../components/ConardSelect'
import { CompanyDto, CompanySearchDto } from '../../../api'
import { useForm } from 'react-hook-form'
import companyService from '../../../services/CompanyService'

interface CompanyFilterProps {
  onFilterSubmit: (values: CompanySearchDto) => void
  filterParentCompanies: boolean
}

const useStyles = makeStyles({
  container: {
    marginTop: '1vh',
    marginBottom: '2vh',
    margin: 'auto',
  },
  filterButton: {
    textAlign: 'center',
    margin: '20px',
    float: 'left',
  },
})

const CompanyFilter: FC<CompanyFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { onFilterSubmit, filterParentCompanies } = props

  const [parentCompanies, setParentCompanies] = useState<CompanyDto[] | undefined>(undefined)

  const emptyFilter: CompanySearchDto = {
    name: '',
    address: '',
    companyIdentificationNumber: '',
    companyTaxNumber: '',
    parentCompanyId: -1,
  }

  const { handleSubmit, control } = useForm<CompanySearchDto>({ defaultValues: emptyFilter })

  const getParentCompanies = () => {
    companyService.findAllParentCompanies().then((response) => {
      setParentCompanies(response.data.content)
    })
  }

  useEffect(() => {
    if (!filterParentCompanies) {
      getParentCompanies()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onFilterSubmit)}>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="name"
              name="name"
              type="text"
              label={filterParentCompanies ? t('entity.company.fields.parentName') : t('entity.company.fields.name')}
              control={control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="address"
              name="address"
              type="text"
              label={t('entity.company.fields.address')}
              control={control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="companyIdentificationNumber"
              name="companyIdentificationNumber"
              type="text"
              label={t('entity.company.fields.companyIdentificationNumber')}
              control={control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="companyTaxNumber"
              name="companyTaxNumber"
              type="text"
              label={t('entity.company.fields.companyTaxNumber')}
              control={control}
            />
          </Grid>
          {!filterParentCompanies && (
            <Grid item lg={2} md={6} xs={12}>
              <ConardSelect
                name="parentCompanyId"
                id="parentCompanyId"
                control={control}
                label={t('entity.company.fields.parentCompanyId')}
              >
                <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                {parentCompanies?.map((parentCompany) => (
                  <MenuItem key={parentCompany.id} value={parentCompany.id}>
                    {parentCompany.name}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
          )}
          {filterParentCompanies && <Grid item lg={2} sm={6} xs={12} />}
          <Grid item lg={2} sm={6} xs={12}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default CompanyFilter
