import { Grid, MenuItem, makeStyles, createStyles } from '@material-ui/core'
import React, { FC } from 'react'
import ConardTextField from '../ConardTextField'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ConardSelect from '../ConardSelect'
import { CompanyDto, ShipownerDto } from '../../api'
import ConardButton from '../ConardButton'

const useStyles = makeStyles(() =>
  createStyles({
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
  })
)

interface GateOutFilterProps {
  iluCode: string | undefined
  companyId: number | undefined
  shipownerId: number | undefined
  companies: CompanyDto[] | undefined
  shipowners: ShipownerDto[] | undefined
  disabled: boolean
  onChangeIluCode: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeCompanyId: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeShipOwner: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onChangeRepleted: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onLoadData: () => void
}

export const GateOutFilter: FC<GateOutFilterProps> = ({
  shipownerId,
  iluCode,
  onChangeIluCode,
  onChangeCompanyId,
  companyId,
  onChangeShipOwner,
  companies,
  shipowners,
  onChangeRepleted,
  onLoadData,
  disabled,
}) => {
  const { t } = useTranslation()
  const { control } = useForm()
  const classes = useStyles()

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <ConardTextField
          id="container.iluCode"
          name="container.iluCode"
          label={t('pages.gateIn.form.iluCode.label')}
          defaultValue={iluCode ?? ''}
          onChange={onChangeIluCode}
          control={control}
        />
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <ConardSelect
          id="container.company.id"
          name="container.company.id"
          label={t('entity.company.fields.name')}
          defaultValue={companyId ?? ''}
          onChange={onChangeCompanyId}
          control={control}
        >
          <MenuItem value={undefined}>{t('pages.common.select.all')}</MenuItem>
          {companies?.map((company) => (
            <MenuItem key={company.id} value={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </ConardSelect>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <ConardSelect
          id="shipowner.id"
          name="shipowner.id"
          label={t('pages.gateIn.form.shipowner.label')}
          defaultValue={shipownerId ?? ''}
          onChange={onChangeShipOwner}
          control={control}
        >
          <MenuItem value={undefined}>{t('pages.common.select.all')}</MenuItem>
          {shipowners?.map((shipowner) => (
            <MenuItem key={shipowner.id} value={shipowner.id}>
              {shipowner.name}
            </MenuItem>
          ))}
        </ConardSelect>
      </Grid>
      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <ConardSelect
          id="repleted"
          name="repleted"
          label={t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked')}
          defaultValue={undefined}
          onChange={onChangeRepleted}
          control={control}
        >
          <MenuItem value={undefined}>{t('pages.common.select.all')}</MenuItem>
          <MenuItem value="true">{t('pages.gateIn.form.repleted.checked')}</MenuItem>
          <MenuItem value="false">{t('pages.gateIn.form.repleted.unchecked')}</MenuItem>
        </ConardSelect>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className={classes.searchButton}>
          <ConardButton conardVariant="light" type="submit" onClick={onLoadData} disabled={disabled}>
            {t('any.buttons.search')}
          </ConardButton>
        </div>
      </Grid>
    </Grid>
  )
}
