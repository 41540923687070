import { Button, Grid } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import React, { FC } from 'react'
import { UploadDownloadApi } from '../../api'
import myAxios from '../../config/axios/axios'

const filesApi = new UploadDownloadApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)

const UploadDownloadPage: FC = () => {
  const filesUploaded = (files: File[]) => {
    if (files.length > 0) {
      filesApi.uploadDownloadUploadFile(files[0])
    }
  }

  const downloadExampleFile = () => {
    filesApi.uploadDownloadDownloadExampleFile({ responseType: 'blob' }).then((response) => {
      // @ts-ignore
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'exampleFile.xlsx'
      a.click()
    })
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
      <Grid item>
        <DropzoneArea
          dropzoneText="Vyberte soubor k nahrání"
          showAlerts={false}
          showPreviewsInDropzone={false}
          onChange={filesUploaded}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={() => downloadExampleFile()}>
          Download example excel file
        </Button>
      </Grid>
    </Grid>
  )
}

export default UploadDownloadPage
