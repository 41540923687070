import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import { cleanObject } from '../../utils/utils'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ConardHide from '../../components/ConardHide'
import { format } from 'date-fns'
import EditIcon from '@material-ui/icons/Edit'
import { AsparkingTicketDto, AsparkingTicketSearchDto, PageAsparkingTicketDto } from '../../api'
import ticketService from '../../services/TicketService'
import TicketFilter from './TicketFilter'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof AsparkingTicketDto
  label: string
}

const TicketListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [pageTickets, setPageTickets] = useState<PageAsparkingTicketDto | null>()
  const [tickets, setTickets] = useState<AsparkingTicketSearchDto | undefined>(undefined)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof AsparkingTicketDto>('arrivedAt')
  const [showFilter, setShowFilter] = useState(true)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'licencePlate', label: t('entity.ticket.fields.licencePlate') },
    { id: 'barcode', label: t('entity.ticket.fields.barcode') },
    { id: 'company', label: t('entity.ticket.fields.company') },
    { id: 'departurePermission', label: t('entity.ticket.fields.departurePermission') },
    { id: 'arrivedAt', label: t('entity.ticket.fields.arrivedAt') },
    { id: 'leaveAt', label: t('entity.ticket.fields.leaveAt') },
  ]

  useEffect(() => {
    search(tickets, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof AsparkingTicketDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: AsparkingTicketSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: AsparkingTicketSearchDto | undefined, fromFilter: boolean) => {
    setTickets(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    ticketService
      .ticketSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTickets(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.ticket.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <ConardHide condition={!showFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TicketFilter ticketsFilter={searchFiltered} />
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={pageTickets === undefined}>
                    {pageTickets?.content?.map((ticket) => (
                      <TableRow className={ticket?.leaveAt !== null ? classes.disabledTableRow : ''} key={ticket.id}>
                        <TableCell component="th" scope="row">
                          {ticket.licencePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket.barcode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket.company?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket.departurePermission ? t('pages.common.select.true') : t('pages.common.select.false')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket.arrivedAt
                            ? format(new Date(ticket.arrivedAt ?? new Date()), 'dd.MM.yyyy, HH:mm')
                            : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket.leaveAt ? format(new Date(ticket.leaveAt ?? new Date()), 'dd.MM.yyyy, HH:mm') : ''}
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<EditIcon />}
                            onClick={() => history.push('/entry-and-parking/ticket/update/' + ticket.id)}
                          >
                            {t('any.buttons.edit')}
                          </ConardButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {pageTickets?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTickets} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTickets?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default TicketListPage
