import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, FormHelperText, Hidden, InputAdornment, Theme } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { Control } from 'react-hook-form/dist/types/form'
import ConardHide from './ConardHide'
import { DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles<Theme, { error?: boolean }>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '58.5px',
    },
    datePickerRoot: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    datePickerInputRoot: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
  })
)

interface ConardDatePickerProps {
  name: string
  defaultValue?: Date
  onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void
  control: Control
  disabled?: boolean
  helperText?: string
  error?: boolean
  clearable?: boolean
  label?: string
  shouldDisableDate?: ((day: MaterialUiPickersDate) => boolean) | undefined
  minDate?: string
}

const ConardDatePicker: FC<ConardDatePickerProps> = (props) => {
  const {
    name,
    defaultValue = new Date(),
    onChange,
    control,
    disabled,
    helperText,
    error,
    clearable,
    label,
    shouldDisableDate,
    minDate,
  } = props

  const classes = useStyles({ error })

  return (
    <>
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          clearable={clearable}
          render={(props) => (
            <DatePicker
              format="dd.MM.yyyy"
              clearable
              value={props.value}
              disabled={disabled}
              error={error}
              onChange={(value) => {
                if (!!onChange) {
                  onChange(value)
                }
                props.onChange(value)
              }}
              label={label}
              inputVariant="outlined"
              minDate={minDate}
              shouldDisableDate={shouldDisableDate}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <Hidden mdDown>
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  </Hidden>
                ),
                classes: {
                  root: classes.datePickerInputRoot,
                  input: classes.datePickerInputInput,
                },
              }}
              className={classes.datePickerRoot}
            />
          )}
        />
      </div>
      <ConardHide condition={!helperText?.length}>
        <FormHelperText error>{helperText}</FormHelperText>
      </ConardHide>
    </>
  )
}

export default ConardDatePicker
