import React, { FC } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { TrainSearchDto } from '../../api'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles({
  searchButton: {
    width: '15vw',
    minWidth: '250px',
    height: '1vh',
    minHeight: '40px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '25px',
    marginBottom: '20px',
    alignItems: 'center',
  },
})

interface SearchFilterProps {
  searchFilter: (data: TrainSearchDto) => void
}

const TrainM2mSearchFilter: FC<SearchFilterProps> = ({ searchFilter }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { handleSubmit, control } = useForm<TrainSearchDto>({})

  return (
    <form onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={10} lg={10} md={12} sm={12} xs={12} className={classes.form}>
          <ConardTextField id="name" name="name" label={t('pages.train.labels.trainName')} control={control} />
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default TrainM2mSearchFilter
