import { createStyles, Grid, Hidden, makeStyles, MenuItem } from '@material-ui/core'
import React, { FC, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FieldName } from 'react-hook-form/dist/types/fields'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TransitionDto, TransitionSearchDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import ConardSelect from '../../components/ConardSelect'
import ConardTextField from '../../components/ConardTextField'
import { RootState } from '../../redux/store'
import { ConfigContext } from '../../context/ConfigContext'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    searchButton: {
      minHeight: '50px',
      textAlign: 'center',
      minWidth: '150px',
    },
    backButton: {
      minHeight: '50px',
      textAlign: 'center',
      minWidth: '150px',
    },
    container: {
      width: '90vw',
    },
  })
)

interface OperatorPositioningFilterProps {
  searchFilter: (data: TransitionSearchDto | undefined) => void
  resetTableData: (data: TransitionSearchDto | undefined) => void
}

interface Sector {
  sectorName: string
  sectorTranslation: string
  menuValues: number[] | string[]
}

const OperatorPositioningFilter: FC<OperatorPositioningFilterProps> = ({ searchFilter, resetTableData }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { coordinates } = useContext(ConfigContext)

  const { handleSubmit, control } = useForm<TransitionDto>({})

  const transitionSearch = useSelector<RootState, TransitionSearchDto | undefined>(
    (state) => state.transitionSearch.transitionSearch
  )

  const [sectorPlace, setSectorPlace] = useState<string | undefined>(transitionSearch?.sectorLetter ?? undefined)
  const [sectorColumn, setSectorColumn] = useState<number | undefined>(transitionSearch?.sectorColumn ?? undefined)
  const [sectorRow, setSectorRow] = useState<number | undefined>(transitionSearch?.sectorRow ?? undefined)
  const [sectorFloor, setSectorFloor] = useState<number | undefined>(transitionSearch?.sectorFloor ?? undefined)

  const sectors: Sector[] = [
    {
      sectorName: 'sectorLetter',
      sectorTranslation: 'pages.gateIn.form.sector.label',
      menuValues: Array.from(Array(coordinates.sectorLetterCount))
        .map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x)),
    },
    {
      sectorName: 'sectorColumn',
      sectorTranslation:
        coordinates.sectorLetterCount === 0 ? 'pages.gateIn.form.sector.label' : 'pages.gateIn.form.sectorColumn.label',
      menuValues: Array.from({ length: coordinates.maxSectorColumn }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorRow',
      sectorTranslation: 'pages.gateIn.form.sectorRow.label',
      menuValues: Array.from({ length: coordinates.maxSectorRow }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorFloor',
      sectorTranslation: 'pages.gateIn.form.sectorFloor.label',
      menuValues: Array.from({ length: coordinates.maxSectorFloor }, (_, i) => i + 1),
    },
  ]

  const onChangeSector = (
    field: FieldName<TransitionSearchDto>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (field) {
      case 'sectorLetter':
        setSectorPlace(event.target.value)
        break
      case 'sectorColumn':
        setSectorColumn(event.target.value as unknown as number)
        break
      case 'sectorRow':
        setSectorRow(event.target.value as unknown as number)
        break
      case 'sectorFloor':
        setSectorFloor(event.target.value as unknown as number)
        break
    }
  }

  const handleReset = () => {
    control.setValue('container.iluCode', '')
    setSectorPlace(undefined)
    setSectorColumn(undefined)
    setSectorRow(undefined)
    setSectorFloor(undefined)
    resetTableData(undefined)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(searchFilter)}
        onReset={() => {
          handleReset()
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          spacing={2}
          className={classes.container}
        >
          <Grid item xl={1} lg={1} md={6} sm={6} xs={12}>
            <ConardButton
              conardVariant="light"
              startIcon={<ArrowBackIcon fontSize="small" color="secondary" />}
              className={classes.backButton}
              onClick={() => {
                history.goBack()
              }}
            >
              {t('any.buttons.back')}
            </ConardButton>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardTextField
              id="container.iluCode"
              name="container.iluCode"
              label={t('pages.gateIn.form.iluCode.label')}
              control={control}
              autoFocus
            />
          </Grid>
          <Hidden lgUp xsDown>
            <Grid item md={6} sm={6} />
          </Hidden>
          {sectors
            .filter((sector) => coordinates.sectorLetterCount !== 0 || sector.sectorName !== 'sectorLetter')
            .map((sector) => {
              return (
                <Grid item key={sector.sectorName} xl={2} lg={1} md={6} sm={6} xs={12}>
                  <ConardSelect
                    id={sector.sectorName}
                    name={sector.sectorName}
                    defaultValue=""
                    label={t(sector.sectorTranslation)}
                    value={
                      sector.sectorName === 'sectorLetter'
                        ? sectorPlace ?? -1
                        : sector.sectorName === 'sectorColumn'
                        ? sectorColumn ?? -1
                        : sector.sectorName === 'sectorRow'
                        ? sectorRow ?? -1
                        : sectorFloor ?? -1
                    }
                    onChange={(event) => onChangeSector(sector.sectorName, event)}
                    control={control}
                  >
                    <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                    {sector.menuValues.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </ConardSelect>
                </Grid>
              )
            })}

          {coordinates.sectorLetterCount === 0 && <Grid item xl={2} lg={2} md={6} sm={6} xs={12} />}
          <Grid item xl={1} lg={2} md={6} sm={6} xs={12} style={{ display: 'flex', gap: 5 }}>
            <ConardButton
              className={classes.searchButton}
              conardVariant="transparent"
              type="reset"
              onClick={handleReset}
            >
              {t('any.buttons.reset')}
            </ConardButton>
            <ConardButton className={classes.searchButton} conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default OperatorPositioningFilter
