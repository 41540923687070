import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../enums/Mode'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import ConardHide from '../../components/ConardHide'
import licensePlateService from '../../services/LicensePlateService'
import ConardSwitch from '../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'
import { AsparkingLicensePlateDto, CarrierDto } from '../../api'
import ConardDatePicker from '../../components/ConardDatePicker'

const useStyles = makeStyles((theme) =>
  createStyles({
    switchText: {
      marginLeft: 35,
      fontSize: 12,
    },
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface LicensePlateFormProps {
  mode: Mode
  carrier?: CarrierDto
  licensePlate?: AsparkingLicensePlateDto
}

const LicensePlateEditForm: FC<LicensePlateFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { carrier, licensePlate, mode } = props

  const [valid, setValid] = useState<boolean>(false)
  const [checkpointOneAllowed, setCheckpointOneAllowed] = useState<boolean>(false)
  const [checkpointTwoAllowed, setCheckpointTwoAllowed] = useState<boolean>(false)
  const [checkpointThreeAllowed, setCheckpointThreeAllowed] = useState<boolean>(false)

  const yupSchema = yup.object().shape({
    licensePlate: yup
      .string()
      .required(t('pages.licensePlate.validation.licensePlate.required'))
      .min(5, t('pages.gateIn.validations.semitrailerLicencePlate.min', { min: 5 }))
      .max(10, t('pages.gateIn.validations.semitrailerLicencePlate.max', { max: 10 })),
  })

  const { handleSubmit, control, errors } = useForm<AsparkingLicensePlateDto>({
    resolver: yupResolver(yupSchema),
  })

  const changeValidSwitch = (field: FieldName<AsparkingLicensePlateDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setValid(newValue)
  }

  const changeCheckpointOneAllowedSwitch = (field: FieldName<AsparkingLicensePlateDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointOneAllowed(newValue)
  }

  const changeCheckpointTwoAllowedSwitch = (field: FieldName<AsparkingLicensePlateDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointTwoAllowed(newValue)
  }

  const changeCheckpointThreeAllowedSwitch = (field: FieldName<AsparkingLicensePlateDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointThreeAllowed(newValue)
  }

  const save = (data: AsparkingLicensePlateDto) => {
    data = {
      ...data,
      valid: data.valid ?? false,
      checkpointOneAllowed: data.checkpointOneAllowed ?? false,
      checkpointTwoAllowed: data.checkpointTwoAllowed ?? false,
      checkpointThreeAllowed: data.checkpointThreeAllowed ?? false,
      carrier: carrier,
    }

    if (mode === Mode.Create) {
      licensePlateService
        .licensePlateCreate(cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier/' + carrier?.id + '/license-plate')
        })
        .catch()
    } else {
      licensePlateService
        .licensePlateUpdate(licensePlate?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier/' + carrier?.id + '/license-plate')
        })
        .catch()
    }
  }

  useEffect(() => {
    setValid(licensePlate?.valid ?? false)
    control.setValue('valid', licensePlate?.valid)
    setCheckpointOneAllowed(licensePlate?.checkpointOneAllowed ?? false)
    control.setValue('checkpointOneAllowed', licensePlate?.checkpointOneAllowed)
    setCheckpointTwoAllowed(licensePlate?.checkpointTwoAllowed ?? false)
    control.setValue('checkpointTwoAllowed', licensePlate?.checkpointTwoAllowed)
    setCheckpointThreeAllowed(licensePlate?.checkpointThreeAllowed ?? false)
    control.setValue('checkpointThreeAllowed', licensePlate?.checkpointThreeAllowed)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.licensePlate.create.title') : t('pages.licensePlate.update.title')}
      </Typography>
      <ConardHide condition={mode === Mode.Create || licensePlate !== undefined}>
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      </ConardHide>
      <ConardHide condition={mode === Mode.Update && licensePlate === undefined}>
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="firstName"
                name="firstName"
                type="text"
                label={t('entity.licensePlate.fields.firstName')}
                defaultValue={licensePlate?.firstName}
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="surname"
                name="surname"
                type="text"
                label={t('entity.licensePlate.fields.surname')}
                defaultValue={licensePlate?.surname}
                fullWidth
                error={!!errors.surname}
                helperText={errors.surname ? errors.surname.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licensePlate"
                name="licensePlate"
                type="text"
                label={t('entity.licensePlate.fields.licensePlate')}
                defaultValue={licensePlate?.licensePlate}
                fullWidth
                error={!!errors.licensePlate}
                helperText={errors.licensePlate ? errors.licensePlate.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="phone"
                name="phone"
                type="text"
                label={t('entity.licensePlate.fields.phone')}
                defaultValue={licensePlate?.phone}
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validFrom"
                defaultValue={new Date(licensePlate?.validFrom ?? new Date().setHours(0, 0, 0, 0))}
                control={control}
                label={t('entity.licensePlate.fields.validFrom')}
                error={!!errors.validFrom}
                helperText={errors.validFrom ? errors.validFrom.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validTo"
                defaultValue={new Date(licensePlate?.validTo ?? new Date().setHours(23, 59, 59, 0))}
                control={control}
                label={t('entity.licensePlate.fields.validTo')}
                error={!!errors.validTo}
                helperText={errors.validTo ? errors.validTo.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="note"
                name="note"
                type="text"
                label={t('entity.licensePlate.fields.note')}
                defaultValue={licensePlate?.note}
                fullWidth
                error={!!errors.note}
                helperText={errors.note ? errors.note.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="flag"
                name="flag"
                type="text"
                label={t('entity.licensePlate.fields.flag')}
                defaultValue={licensePlate?.flag}
                fullWidth
                error={!!errors.flag}
                helperText={errors.flag ? errors.flag.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="valid"
                name="valid"
                checked={valid}
                onChange={(newValue) => changeValidSwitch('valid', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.valid')}
                uncheckedLabel={t('pages.licensePlate.table.invalid')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointOne')}</Typography>
              <ConardSwitch
                id="checkpointOneAllowed"
                name="checkpointOneAllowed"
                checked={checkpointOneAllowed}
                onChange={(newValue) => changeCheckpointOneAllowedSwitch('checkpointOneAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointTwo')}</Typography>
              <ConardSwitch
                id="checkpointTwoAllowed"
                name="checkpointTwoAllowed"
                checked={checkpointTwoAllowed}
                onChange={(newValue) => changeCheckpointTwoAllowedSwitch('checkpointTwoAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointThree')}</Typography>
              <ConardSwitch
                id="checkpointThreeAllowed"
                name="checkpointThreeAllowed"
                checked={checkpointThreeAllowed}
                onChange={(newValue) => changeCheckpointThreeAllowedSwitch('checkpointThreeAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={6} sm={12} xs={12} />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </ConardHide>
    </>
  )
}

export default LicensePlateEditForm
