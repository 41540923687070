import React, { FC, useState } from 'react'
import { AppBar, createStyles, Drawer, Grid, Hidden, IconButton, makeStyles, Theme, Toolbar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MainMenu from './MainMenu'
import { UserDtoRoleEnum } from '../../api'
import { useConardAuth } from '../../hooks/useConardAuth'
import { Language } from '@material-ui/icons'
import { LanguageMenu } from './LanguageMenu'

const drawerMinWidth = 120
const drawerWidth = '12vw'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      minWidth: drawerMinWidth,
      width: drawerWidth,
    },
  })
)

const MainMenuToolbar: FC = () => {
  const classes = useStyles()

  const [mobileOpen, setMobileOpen] = useState(false)
  const [languageOpen, setLanguageOpen] = useState(false)
  const auth = useConardAuth()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLanguageToggle = () => {
    setLanguageOpen(!languageOpen)
  }

  const drawer = <MainMenu onClick={() => setMobileOpen(false)} />

  return (
    <>
      {/* only show toolbar on small screen when menu isn't permanent */}
      {auth.hasOneOfRoles(
        new Set([
          UserDtoRoleEnum.Manager,
          UserDtoRoleEnum.Dispatcher,
          UserDtoRoleEnum.DispatcherRoad,
          UserDtoRoleEnum.DispatcherTrain,
          UserDtoRoleEnum.CustomsOfficer,
          UserDtoRoleEnum.Carrier,
        ])
      ) && (
        <>
          <Hidden smUp>
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Hidden>

          <nav className={classes.drawer}>
            {/* temporary drawer for small screens */}
            <Hidden smUp>
              <Drawer
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>

            {/* permanent drawer for large screens */}
            <Hidden xsDown>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </>
      )}

      {auth.hasOneOfRoles(new Set([UserDtoRoleEnum.Operator, UserDtoRoleEnum.Driver])) && (
        <>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
                {auth.getRole() === UserDtoRoleEnum.Driver && (
                  <Grid item>
                    <IconButton color="inherit" edge="end" onClick={handleLanguageToggle}>
                      <Language />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>

          {auth.getRole() === UserDtoRoleEnum.Driver && (
            <Drawer
              variant="temporary"
              anchor="right"
              open={languageOpen}
              onClose={handleLanguageToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <LanguageMenu handleCloseMenu={handleLanguageToggle} />
            </Drawer>
          )}

          <nav>
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </>
      )}
    </>
  )
}

export default MainMenuToolbar
