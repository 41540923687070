import React, { FC, useContext, useEffect, useState } from 'react'
import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AutocompleteDto, UserDto, UserDtoRoleEnum } from '../../api'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../enums/Mode'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConardSelect from '../../components/ConardSelect'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import ConardSwitch from '../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'
import ConardTextAutocomplete from '../../components/ConardTextAutocomplete'
import userService from '../../services/UserService'
import { ConfigContext } from '../../context/ConfigContext'
import { UsersMosnov } from '../../enums/UsersMosnov'
import { UsersBrno } from '../../enums/UsersBrno'

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface UserFormProps {
  mode: Mode
  user?: UserDto
}

const UserEditForm: FC<UserFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { user, mode } = props
  const { usersMosnov } = useContext(ConfigContext)

  const [disabled, setDisabled] = useState<boolean>(false)
  const [driver, setDriver] = useState<AutocompleteDto>({})
  const [carrier, setCarrier] = useState<AutocompleteDto>({})

  let passwordValidation
  if (mode === Mode.Create) {
    passwordValidation = yup
      .string()
      .required(t('pages.userManagement.validation.password'))
      .min(3, t('pages.login.password.min', { min: 3 }))
  } else {
    passwordValidation = yup
      .string()
      .test(
        'empty-or-3-characters-check',
        t('pages.login.password.min', { min: 3 }),
        (password) => !password || password.length >= 3
      )
  }

  const yupSchema = yup.object().shape({
    username: yup
      .string()
      .required(t('pages.userManagement.validation.username'))
      .min(3, t('pages.login.username.min', { min: 3 })),
    password: passwordValidation,
    firstName: yup.string().required(t('pages.userManagement.validation.firstName')),
    surname: yup.string().required(t('pages.userManagement.validation.surname')),
    email: yup.string().email(t('pages.userManagement.validation.email')).nullable(),
    driver: yup.object().when('role', {
      is: UserDtoRoleEnum.Driver,
      then: yup.object().shape({
        id: yup.number().required(t('pages.userManagement.validation.driver')),
      }),
      otherwise: yup.object().notRequired(),
    }),
    carrier: yup.object().when('role', {
      is: UserDtoRoleEnum.Carrier,
      then: yup.object().shape({
        id: yup.number().required(t('pages.userManagement.validation.carrier')),
      }),
      otherwise: yup.object().notRequired(),
    }),
  })

  const { handleSubmit, control, errors } = useForm<UserDto>({
    defaultValues: user,
    resolver: yupResolver(yupSchema),
  })

  const changeSwitch = (field: FieldName<UserDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setDisabled(newValue)
  }

  const onChangeDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto)
  }

  const onChangeCarrier = (autocompleteDto: AutocompleteDto) => {
    setCarrier(autocompleteDto)
  }

  useEffect(() => {
    setDisabled(user?.disabled ?? false)
    control.setValue('disabled', user?.disabled)

    if (!!user?.driver) {
      setDriver(user.driver)
    }
    if (!!user?.carrier) {
      setCarrier(user.carrier)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const role = useWatch({
    control,
    name: 'role',
  })

  const save = (data: UserDto) => {
    const values: UserDto = {
      ...cleanObject(data),
      driver: role === UserDtoRoleEnum.Driver ? driver : undefined,
      carrier: role === UserDtoRoleEnum.Carrier ? carrier : undefined,
    }

    if (mode === Mode.Create) {
      userService
        .userCreate(values)
        .then(() => {
          history.push('/user')
        })
        .catch()
    } else {
      userService
        .userUpdate(user?.id || -1, values)
        .then(() => {
          history.push('/user')
        })
        .catch()
    }
  }

  useEffect(() => {
    control.setValue('driver', driver)
  }, [driver, control])

  useEffect(() => {
    control.setValue('carrier', carrier)
  }, [carrier, control])

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.userManagement.create.title') : t('pages.userManagement.update.title')}
      </Typography>
      {mode === Mode.Update && user === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}
      {(mode === Mode.Create || user) && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="username"
                name="username"
                type="text"
                label={t('pages.userManagement.header.username')}
                defaultValue=""
                fullWidth
                error={!!errors.username}
                helperText={errors.username ? errors.username.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="password"
                name="password"
                type="password"
                label={t('pages.userManagement.header.password')}
                fullWidth
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="firstName"
                name="firstName"
                type="text"
                label={t('pages.userManagement.header.firstName')}
                defaultValue=""
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="surname"
                name="surname"
                type="text"
                label={t('pages.userManagement.header.surname')}
                defaultValue=""
                fullWidth
                error={!!errors.surname}
                helperText={errors.surname ? errors.surname.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="email"
                name="email"
                type="text"
                label={t('pages.userManagement.header.email')}
                defaultValue=""
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="phoneNumber"
                name="phoneNumber"
                label={t('pages.userManagement.header.phoneNumber')}
                defaultValue=""
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber ? errors.phoneNumber.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSelect
                label="Role"
                name="role"
                defaultValue={mode === Mode.Create ? UserDtoRoleEnum.Operator : user?.role}
                control={control}
              >
                {usersMosnov &&
                  Object.values(UsersMosnov).map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(`enum.role.${value}`)}
                    </MenuItem>
                  ))}

                {!usersMosnov &&
                  Object.values(UsersBrno).map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(`enum.role.${value}`)}
                    </MenuItem>
                  ))}
              </ConardSelect>
            </Grid>
            {role !== UserDtoRoleEnum.Driver && role !== UserDtoRoleEnum.Carrier && <Grid item md={6} xs={12} />}
            {role === UserDtoRoleEnum.Driver && (
              <>
                <Grid item md={6} xs={12}>
                  <ConardTextAutocomplete
                    optionType="DRIVER"
                    control={control}
                    name="driver"
                    value={driver}
                    setValue={onChangeDriver}
                    label={t('pages.userManagement.header.driver')}
                    error={!!errors.driver}
                    helperText={errors.driver?.id?.message ?? ' '}
                  />
                </Grid>
              </>
            )}
            {role === UserDtoRoleEnum.Carrier && (
              <>
                <Grid item md={6} xs={12}>
                  <ConardTextAutocomplete
                    optionType="CARRIER"
                    value={carrier}
                    setValue={onChangeCarrier}
                    name="carrier"
                    control={control}
                    label={t('pages.userManagement.header.carrier')}
                    error={!!errors.carrier}
                    helperText={errors.carrier?.id?.message ?? ' '}
                  />
                </Grid>
              </>
            )}
            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
              <ConardSwitch
                id="disabled"
                name="disabled"
                checked={disabled}
                onChange={(newValue) => changeSwitch('disabled', newValue)}
                control={control}
                checkedLabel={t('pages.userManagement.state.disabled')}
                uncheckedLabel={t('pages.userManagement.state.enabled')}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default UserEditForm
