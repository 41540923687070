import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { CdCargoTemplateDto, PageCdCargoTemplateDto } from '../../../api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../../enums/Order'
import ConardButton from '../../../components/ConardButton'
import ConardHide from '../../../components/ConardHide'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import cdCargoExportConfigurationService from '../../../services/CdCargoExportConfigurationService'
import ConardProgressBar from '../../../components/ConardProgressBar'

const useStyles = makeStyles({
  editTable: {
    width: '7vw',
  },
  typography: {
    width: '60vw',
  },
  newExportConfigButton: {
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    textAlign: 'center',
    margin: '20px',
    float: 'right',
  },
  gridContainer: {
    marginTop: '8vh',
  },
})

interface HeadCell {
  id: keyof CdCargoTemplateDto
  label: string
}

const CdCargoExportConfigurationListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [configs, setConfigs] = useState<PageCdCargoTemplateDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof CdCargoTemplateDto>('name')

  const classes = useStyles()

  const headCells: HeadCell[] = [{ id: 'name', label: t('pages.cdCargoExportConfig.form.name') }]

  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, orderBy, order])

  const handleSort = (property: keyof CdCargoTemplateDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const search = () => {
    cdCargoExportConfigurationService
      .findAll(page, pageSize, orderBy, order)
      .then((response) => {
        setConfigs(response.data)
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.cdCargoExportConfig.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ float: 'right' }}>
          <div className={classes.newExportConfigButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/export-config/cd-cargo/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={configs === undefined}>
                    {configs?.content?.map((config) => (
                      <TableRow key={config.id}>
                        <TableCell component="td" scope="row">
                          {config.name}
                        </TableCell>
                        <TableCell className={classes.editTable}>
                          <ConardButton
                            conardVariant="light"
                            startIcon={<EditIcon />}
                            onClick={() => history.push('/export-config/cd-cargo/update/' + config.id)}
                          >
                            {t('any.buttons.edit')}
                          </ConardButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {configs?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!configs} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={configs?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newExportConfigButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/export-config/cd-cargo/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CdCargoExportConfigurationListPage
