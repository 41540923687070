import React, { FC } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import { AsparkingLicensePlateSearchDto } from '../../api'

interface LicensePlateFilterProps {
  licensePlatesFilter: (data: AsparkingLicensePlateSearchDto) => void
}

const LicensePlateFilter: FC<LicensePlateFilterProps> = (props) => {
  const { t } = useTranslation()

  const { licensePlatesFilter } = props

  const { handleSubmit, control } = useForm<AsparkingLicensePlateSearchDto>({})

  return (
    <form onSubmit={handleSubmit(licensePlatesFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="licensePlate"
            name="licensePlate"
            type="text"
            label={t('entity.licensePlate.fields.licensePlate')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="firstName"
            name="firstName"
            type="text"
            label={t('entity.licensePlate.fields.firstName')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="surname"
            name="surname"
            type="text"
            label={t('entity.licensePlate.fields.surname')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect id="valid" name="valid" label={t('entity.licensePlate.fields.valid')} control={control}>
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.licensePlate.table.valid')}</MenuItem>
            <MenuItem value="false">{t('pages.licensePlate.table.invalid')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointOneAllowed"
            name="checkpointOneAllowed"
            label={t('pages.licensePlate.table.checkpointOne')}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
            <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointTwoAllowed"
            name="checkpointTwoAllowed"
            label={t('pages.licensePlate.table.checkpointTwo')}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
            <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointThreeAllowed"
            name="checkpointThreeAllowed"
            label={t('pages.licensePlate.table.checkpointThree')}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
            <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12} />
        <Grid item xl={6} lg={6} />
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default LicensePlateFilter
