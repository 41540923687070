import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import ConardHide from '../../components/ConardHide'
import {
  PageTransitionSearchListDto,
  TransitionCustomsInfoSearchListDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionSearchDto,
  TransitionSearchDtoContainerStatesEnum,
  TransitionSearchListDto,
  TransitionSearchListDtoDirectionEnum,
} from '../../api'
import { cleanObject } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { Order } from '../../enums/Order'
import { setSearchTransition } from '../../redux/transition/TransitionSearchSlice'
import transitionService from '../../services/TransitionService'
import OperatorPositioningFilter from './OperatorPositioningFilter'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'
import { ConardCoordinates } from '../../components/ConardCoordinates'
import OperatorContainerIcon from '../../components/operator/OperatorContainerIcon'
import OperatorContainerQuality from '../../components/operator/OperatorContainerQuality'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '4vh',
    position: 'absolute',
    width: '99vw',
    left: '1vw',
  },
  tableCell: {
    fontSize: '20px',
    padding: '4px',
  },
  tableHeadCell: {
    fontSize: '20px',
  },
})

interface HeadCell {
  id: string
  label: string | ReactElement
}

const OperatorPositioningListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const [pageTransitionSearchListDtos, setPageTransitionSearchListDtos] = useState<PageTransitionSearchListDto | null>()
  const transitions = useSelector<RootState, TransitionSearchDto | undefined>(
    (state) => state.transitionSearch.transitionSearch
  )

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('transitionDateTime')

  const headCells: HeadCell[] = [
    { id: 'transitionCustomsInfo.state', label: '' },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    {
      id: 'containerType,semitrailerType,shippingContainerType',
      label: '',
    },
    { id: 'qualityType', label: '' },
    { id: 'importExport', label: t('pages.operator.table.importExport') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    { id: 'sectorLetter,sectorColumn,sectorRow,sectorFloor', label: t('pages.gateIn.form.location.label') },
    { id: 'trainName', label: t('pages.operator.table.gateOutTrain') },
    { id: 'note', label: t('pages.gateIn.form.note.label') },
  ]

  useEffect(() => {
    search(transitions, false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    search(transitions, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: TransitionSearchDto | undefined) => {
    search(data, true)
  }

  const handleReset = (data: TransitionSearchDto | undefined) => {
    search(data, false)
  }

  const search = (data: TransitionSearchDto | undefined, fromFilter: boolean) => {
    dispatch(setSearchTransition(data))

    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
      },
      onSite: true,
      containerStates: [
        TransitionSearchDtoContainerStatesEnum.ProcessingGateOut,
        TransitionSearchDtoContainerStatesEnum.FinishedGateIn,
      ],
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    transitionService
      .searchForList(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransitionSearchListDtos(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  const handlePositioning = (transition: TransitionSearchListDto) => {
    history.push('/gate-in/transition/store/' + transition.id)
  }

  const getCustomsStateIcon = (customsInfo: TransitionCustomsInfoSearchListDto | undefined) => {
    switch (customsInfo?.state) {
      case undefined: {
        return ''
      }
      case TransitionCustomsInfoSearchListDtoStateEnum.Stop: {
        return <PolicyOutlinedIcon color="error" />
      }
      default: {
        return <PolicyOutlinedIcon color="primary" />
      }
    }
  }

  return (
    <>
      <Grid container className={classes.gridContainer} direction="row" alignItems="center" spacing={3}>
        <Grid item xl={12}>
          <OperatorPositioningFilter searchFilter={searchFiltered} resetTableData={handleReset} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                          className={classes.tableHeadCell}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={pageTransitionSearchListDtos === undefined}>
                    {pageTransitionSearchListDtos?.content?.map((transition) => (
                      <TableRow key={transition.id} onClick={() => handlePositioning(transition)}>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {getCustomsStateIcon(transition.transitionCustomsInfo)}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {transition.iluCode}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <OperatorContainerIcon transition={transition} />
                        </TableCell>
                        <TableCell>
                          <OperatorContainerQuality transition={transition} />
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {transition.importExport
                            ? t('pages.gateIn.form.importExport.' + transition.importExport?.toLowerCase())
                            : ''}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {transition.semitrailerLicencePlate}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          <ConardCoordinates transition={transition} />
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {transition.direction === TransitionSearchListDtoDirectionEnum.Out
                            ? transition.trainName
                            : ''}
                        </TableCell>
                        <TableCell component="td" scope="row" className={classes.tableCell}>
                          {transition.note}
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  <ConardHide
                    condition={
                      !pageTransitionSearchListDtos?.content || pageTransitionSearchListDtos?.totalElements !== 0
                    }
                  >
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" align="center" colSpan={12} className={classes.tableCell}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  </ConardHide>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTransitionSearchListDtos?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default OperatorPositioningListPage
