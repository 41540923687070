import React, { FC, useContext, useState } from 'react'
import { StoreInputParams } from './StoreForm'
import { Button, createStyles, alpha, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ConardHide from '../../../components/ConardHide'
import { Error } from '@material-ui/icons'
import { ConfigContext } from '../../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 4vw 2vh 4vw',
      marginBottom: '2vh',
    },
    selectorWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginLeft: '5vw',
      marginRight: '5vw',
    },
    selectorItemContent: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    selectorItem: {
      width: '20%',
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      border: `1px solid ${theme.palette.secondary.light}`,
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    invalidColumn: {
      justifyContent: 'center',
      justifyItems: 'center',
      color: theme.palette.error.main,
      marginBottom: '1vh',
    },
    deleteButton: {
      borderRadius: 20,
      width: '100%',
      height: '100%',
      marginLeft: '1vw',
      fontSize: 'large',
    },
  })
)

interface ColumnDialProps {
  column?: number
  setStoreInputParam: React.Dispatch<React.SetStateAction<StoreInputParams>>
  switchDials: (to: string) => void
}

export const ColumnDial: FC<ColumnDialProps> = (props) => {
  const { column, setStoreInputParam, switchDials } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { coordinates } = useContext(ConfigContext)

  const [invalidColumn, setInvalidColumn] = useState(false)
  const [clicks, setClicks] = useState(0)
  const maximumColumn = coordinates.maxSectorColumn

  const onAddNumber = (selectedNumber: number) => {
    if (coordinates.maxSectorColumn <= 10) {
      setStoreInputParam((prevState) => ({
        ...prevState,
        sectorColumn: selectedNumber,
      }))
      switchDials('rowDial')
      return
    }

    const newColumn = (column ?? 0) * 10 + selectedNumber
    if (newColumn === 0) {
      setInvalidColumn(true)
    } else {
      setInvalidColumn(false)
      setStoreInputParam((prevState) => ({
        ...prevState,
        sectorColumn: newColumn > maximumColumn ? column : newColumn,
      }))
    }

    if (clicks >= 1) {
      setClicks(0)
      switchDials('rowDial')
    } else {
      setClicks((prevState) => prevState + 1)
    }
  }

  const onDelete = () => {
    setClicks(0)
    setInvalidColumn(false)
    setStoreInputParam((prevState) => ({
      ...prevState,
      sectorColumn: undefined,
    }))
  }

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h5" align="center" color="primary" style={{ marginBottom: '1vh' }}>
          {coordinates.sectorLetterCount === 0
            ? t('pages.containerStoreForm.labels.sector')
            : t('pages.containerStoreForm.labels.sectorColumn')}
        </Typography>
        <ConardHide condition={!invalidColumn}>
          <Typography variant="h6" align="center" className={classes.invalidColumn}>
            <Error fontSize="small" />
            {' ' + t('pages.containerStoreForm.validations.column')}
          </Typography>
        </ConardHide>
        <Grid container spacing={1}>
          <Grid item md={2} />
          <Grid item xs={12} md={8}>
            <div className={classes.selectorWrapper}>
              {Array(10)
                .fill(1)
                .map((value, index) => (
                  <div
                    key={index}
                    onClick={() => onAddNumber(coordinates.maxSectorColumn <= 10 ? index + 1 : index)}
                    className={classes.selectorItem}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      className={classes.selectorItemContent}
                    >
                      <Grid item>
                        <Typography variant="h3">{coordinates.maxSectorColumn <= 10 ? index + 1 : index}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button onClick={onDelete} color="primary" variant="contained" className={classes.deleteButton}>
              {t('any.buttons.delete')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
