import React, { FC, useEffect, useState } from 'react'
import {
  JoinCoparnTruckContainerDto,
  OperatorTransitionDto,
  OperatorTransitionDtoDirectionEnum,
  OperatorTransitionDtoReceiptMethodEnum,
  OperatorTransitionSearchDto,
  TransitionDto,
} from '../../api'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OperatorCoparnContainerSelectionModal } from './OperatorCoparnContainerSelectionModal'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import ConardButton from '../../components/ConardButton'
import gateOutService from '../../services/GateOutService'
import transitionService from '../../services/TransitionService'
import manipulationRequestService from '../../services/ManipulationRequestService'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContentWrapper: {
      textAlign: 'center',
    },
    dialogIcon: {
      fontSize: 100,
    },
    dialogTitle: {
      textAlign: 'center',
    },
    typography: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    dialogText: {
      fontSize: '20px',
    },
  })
)

interface OperatorClickableRowProps {
  operatorTransition: OperatorTransitionDto
  handleTrainStyle: (operatorTransition: OperatorTransitionDto) => string
  search: (data: OperatorTransitionSearchDto | undefined, fromFilter: boolean) => void
  setEnableIntervalSearch: (enable: boolean) => void
}

const OperatorClickableRow: FC<OperatorClickableRowProps> = ({
  operatorTransition,
  handleTrainStyle,
  search,
  children,
  setEnableIntervalSearch,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [showConfirmGateOutDialog, setShowConfirmGateOutDialog] = useState(false)
  const [showConfirmLoadDialog, setShowConfirmLoadDialog] = useState(false)
  const [showCoparnDialog, setShowCoparnDialog] = useState(false)
  const [showManipulationRequestDialog, setShowManipulationRequestDialog] = useState(false)

  const confirmCoparn = (transitionDto: TransitionDto, coparnContainerId: number) => {
    const data: JoinCoparnTruckContainerDto = {
      coparnContainerDto: {
        id: coparnContainerId,
        selectedTransition: { id: transitionDto.id, container: transitionDto.container },
      },
      coparnTruckDto: { id: operatorTransition.coparnTruckId },
    }

    gateOutService
      .gateOutCreateGoFromSelectedContainer(data)
      .then(() => {
        setShowCoparnDialog(false)
        search(undefined, false)
      })
      .catch()

    setShowCoparnDialog(false)
  }

  const confirmGateOut = () => {
    gateOutService
      .releaseContainer(operatorTransition.transitionId)
      .then(() => {
        setShowConfirmGateOutDialog(false)
        search(undefined, false)
      })
      .catch()
    setShowConfirmGateOutDialog(false)
  }

  const finishManipulationRequest = () => {
    if (operatorTransition.manipulationRequestId) {
      manipulationRequestService
        .store(operatorTransition.manipulationRequestId)
        .then(() => {
          setShowManipulationRequestDialog(false)
          search(undefined, false)
        })
        .catch()
    }
    setShowManipulationRequestDialog(false)
  }

  const confirmLoad = () => {
    transitionService.changeLoadedOnTrain(operatorTransition.transitionId ?? -1, true).then(() => {
      setShowConfirmLoadDialog(false)
      search(undefined, false)
    })

    setShowConfirmLoadDialog(false)
  }

  const handleClick = () => {
    if (operatorTransition.coparnTruckId) {
      setShowCoparnDialog(true)
    } else if (operatorTransition.manipulationRequestId) {
      setShowManipulationRequestDialog(true)
    } else if (operatorTransition.direction === OperatorTransitionDtoDirectionEnum.In) {
      history.push('/gate-in/transition/store/' + operatorTransition.transitionId)
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road
    ) {
      setShowConfirmGateOutDialog(true)
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train
    ) {
      setShowConfirmLoadDialog(true)
    }
  }

  useEffect(() => {
    setEnableIntervalSearch(!showConfirmGateOutDialog && !showConfirmLoadDialog && !showCoparnDialog)
  }, [showConfirmGateOutDialog, showConfirmLoadDialog, showCoparnDialog]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TableRow
        className={
          operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train
            ? handleTrainStyle(operatorTransition)
            : ''
        }
        key={operatorTransition.id}
        onClick={handleClick}
      >
        <TableCell>
          <Typography variant="body1" className={classes.typography}>
            {operatorTransition.coparnTruckId && t('any.buttons.select')}
            {operatorTransition.direction === OperatorTransitionDtoDirectionEnum.In &&
              t('pages.operator.buttons.gateIn')}
            {operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
              operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road &&
              t('pages.operator.buttons.gateOut')}
            {operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
              operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train &&
              t('pages.operator.buttons.load')}
          </Typography>
        </TableCell>
        {children}
      </TableRow>
      {showCoparnDialog && (
        <OperatorCoparnContainerSelectionModal
          driverInfo={{ frontLicensePlate: operatorTransition.truckLicencePlate, driver: operatorTransition.driver }}
          coparnContainerId={operatorTransition.coparnId!}
          open={showCoparnDialog}
          onSave={confirmCoparn}
          onClose={() => setShowCoparnDialog(false)}
        />
      )}
      <Dialog open={showConfirmGateOutDialog}>
        <DialogTitle>
          <Typography variant="h2" className={classes.dialogTitle}>
            {operatorTransition.iluCode}
          </Typography>
          <Typography variant="h2" className={classes.dialogTitle}>
            {operatorTransition.truckLicencePlate ?? ''}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentWrapper}>
            <LiveHelpIcon className={classes.dialogIcon} />
            <Typography className={classes.dialogText}>
              {t('pages.gateOut.dialog.confirmGateOut.content', {
                ilu: operatorTransition.iluCode,
                licencePlate: operatorTransition.truckLicencePlate,
              })}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={confirmGateOut}>
            {t('any.buttons.ok')}
          </ConardButton>
          <ConardButton conardVariant="light" onClick={() => setShowConfirmGateOutDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </DialogActions>
      </Dialog>
      <Dialog open={showConfirmLoadDialog}>
        <DialogTitle>
          <Typography variant="h2" className={classes.dialogTitle}>
            {operatorTransition.iluCode}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentWrapper}>
            <LiveHelpIcon className={classes.dialogIcon} />
            <Typography>
              {t('pages.gateOut.dialog.confirmLoad.content', {
                ilu: operatorTransition.iluCode,
                train: operatorTransition.trainName,
              })}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={confirmLoad}>
            {t('any.buttons.ok')}
          </ConardButton>
          <ConardButton conardVariant="light" onClick={() => setShowConfirmLoadDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </DialogActions>
      </Dialog>
      <Dialog open={showManipulationRequestDialog}>
        <DialogTitle>
          <Typography variant="h2" className={classes.dialogTitle}>
            {operatorTransition.iluCode}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentWrapper}>
            <LiveHelpIcon className={classes.dialogIcon} />
            <Typography>
              {t('pages.gateOut.dialog.confirmManipulation.content', { ilu: operatorTransition.iluCode })}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={finishManipulationRequest}>
            {t('any.buttons.move')}
          </ConardButton>
          <ConardButton conardVariant="light" onClick={() => setShowManipulationRequestDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OperatorClickableRow
