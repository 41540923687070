import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  alpha,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useTranslation } from 'react-i18next'
import {
  PageTransitionSearchListDto,
  TransitionSearchDto,
  TransitionSearchDtoContainerStateEnum,
  TransitionSearchDtoReceiptMethodEnum,
  TransitionSearchListDto,
  TransitionSearchListDtoDriverPassEnum,
} from '../../api'
import { cleanObject } from '../../utils/utils'
import transitionService from '../../services/TransitionService'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useAppDispatch } from '../../redux/store'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import GateInTransitionPendingFilter from './GateInTransitionPendingFilter'
import ConardProgressBar from '../../components/ConardProgressBar'
import ConardUploadButton from '../../components/ConardUploadButton'
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo'
import TransitionDeleteDialog from '../../components/dialogs/TransitionDeleteDialog'
import ConardTransitionSearchIcon from '../../components/ConardTransitionSearchIcon'
import {
  ContentPaste,
  EmojiTransportation,
  Mail,
  Person,
  Spellcheck,
  StickyNote2Outlined,
  TrafficOutlined,
} from '@mui/icons-material'
import ConardTooltip from '../../components/ConardTooltip'
import { format } from 'date-fns'
import MedlogBoardDialog from '../../components/dialogs/MedlogBoardDialog'
import gateInService from '../../services/GateInService'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    circle: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      margin: '5px',
    },
    detailButton: {
      paddingRight: '1vw',
      paddingLeft: '1vw',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
    },
    input: {
      display: 'none',
    },
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 2vw 2vh 2vw',
      width: '100%',
      marginTop: '10vh',
    },
    medlogBoardButton: {
      height: '70px',
    },
    icon: {
      color: theme.palette.primary.main,
    },
    table: {
      marginTop: '5vh',
    },
    green: {
      backgroundColor: 'green',
    },
    orange: {
      backgroundColor: 'orange',
    },
    red: {
      backgroundColor: 'red',
    },
    instantGreenPass: {
      color: 'white',
    },
    temporaryCustomer: {
      color: theme.palette.warning.main,
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

const GateInTransitionPendingListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { medlogBoard, hasDriverArrivalSemaphore, englishExcelExport } = useContext(ConfigContext)

  const dispatch = useAppDispatch()

  const [pageTransitions, setPageTransitions] = useState<PageTransitionSearchListDto | null>()
  const [transitions, setTransitions] = useState<TransitionSearchDto | undefined>({
    containerState: TransitionSearchDtoContainerStateEnum.PlannedGateIn,
    receiptMethod: TransitionSearchDtoReceiptMethodEnum.Road,
  })
  const [blockSearch, setBlockSearch] = useState(true)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<string>(hasDriverArrivalSemaphore ? 'driverArrivalId,' : 'transitionDateTime')
  const [showFilter, setShowFilter] = useState(true)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showBoardDialog, setShowBoardDialog] = useState(false)
  const [successBoardImport, setSuccessBoardImport] = useState(false)
  const [selectedTransition, setSelectedTransition] = useState<TransitionSearchListDto | undefined>(undefined)

  const headCells: (HeadCell | null)[] = [
    hasDriverArrivalSemaphore ? { id: 'driverArrivalId', label: <TrafficOutlined fontSize="small" /> } : null,
    { id: 'note', label: <StickyNote2Outlined fontSize="small" /> },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'companyName', label: t('entity.company.fields.name') },
    { id: 'shipownerName', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'driverName', label: t('pages.gateIn.form.driver.label') },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'transitionDateTime', label: t('pages.gateIn.headers.plannedDateTime') },
    { id: 'createdAt', label: t('pages.gateIn.headers.created') },
    { id: 'damaged', label: '' },
  ].filter(Boolean)

  useEffect(() => {
    search(transitions, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: TransitionSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TransitionSearchDto | undefined, fromFilter: boolean) => {
    setBlockSearch(true)
    setTransitions(data)

    const dateFrom: Date | undefined = data?.transitionDateTimeFrom ? new Date(data?.transitionDateTimeFrom) : undefined
    dateFrom?.setHours(0, 0, 0)

    const dateTo: Date | undefined = data?.transitionDateTimeTo ? new Date(data?.transitionDateTimeTo) : undefined
    dateTo?.setHours(23, 59, 59)

    data = {
      ...data,
      transitionDateTimeFrom: dateFrom?.toISOString(),
      transitionDateTimeTo: dateTo?.toISOString(),
      receiptMethod: TransitionSearchDtoReceiptMethodEnum.Road,
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',transitionDateTime,iluCode,' + currentOrder]
    }

    transitionService
      .searchForListPlanned(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const downloadExport = (language: 'ENGLISH' | 'CZECH') => {
    const exportType = 'STANDARD'
    transitionService.downloadExport(exportType, language, cleanObject(transitions)).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'transitionExport.xlsx'
      a.click()
    })
  }

  const onDetail = (id: number | undefined) => {
    dispatch(setTransitionFormDisabled(false))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(false))
    dispatch(setTransition(transitionInitialState.transition))

    history.push('/search/transition/detail/' + id)
  }

  const onDelete = (id: number) => {
    transitionService
      .delete(id)
      .then(() => {
        search(transitions, false)
      })
      .catch()
  }

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.[0]) {
      gateInService
        .importMedlogBoard(e.currentTarget.files?.[0])
        .then((response) => {
          setSuccessBoardImport(response.status === 200)
          setShowBoardDialog(true)
          search(transitions, false)
        })
        .catch()
    }
  }

  const chooseSemaphoreColor = (driverPass: TransitionSearchListDtoDriverPassEnum) => {
    switch (driverPass) {
      case TransitionSearchListDtoDriverPassEnum.Green:
        return classes.green
      case TransitionSearchListDtoDriverPassEnum.Orange:
        return classes.orange
      case TransitionSearchListDtoDriverPassEnum.Red:
        return classes.red
    }
  }

  const renderCreatedIcon = (transition: TransitionSearchListDto) => {
    if (!!transition.medlogBoardId) {
      return (
        <ConardTooltip
          title={
            t('pages.gateIn.headers.medlogBoard') +
            (transition.createdAt ? ', ' + format(new Date(transition.createdAt), 'dd.MM.yyyy HH:mm') : '')
          }
        >
          <ContentPaste className={classes.icon} />
        </ConardTooltip>
      )
    }

    if (!!transition.driverName) {
      return (
        <ConardTooltip
          title={
            transition.driverName +
            (transition.createdAt ? ', ' + format(new Date(transition.createdAt), 'dd.MM.yyyy HH:mm') : '')
          }
        >
          <Person className={classes.icon} />
        </ConardTooltip>
      )
    }

    if (!!transition.carrierName) {
      return (
        <ConardTooltip
          title={
            transition.carrierName +
            (transition.createdAt ? ', ' + format(new Date(transition.createdAt), 'dd.MM.yyyy HH:mm') : '')
          }
        >
          <EmojiTransportation className={classes.icon} />
        </ConardTooltip>
      )
    }

    return (
      <ConardTooltip
        title={
          t('enum.processedFileType.COPARN') +
          (transition.createdAt ? ', ' + format(new Date(transition.createdAt), 'dd.MM.yyyy HH:mm') : '')
        }
      >
        <Mail className={classes.icon} />
      </ConardTooltip>
    )
  }

  const transitionDeleteDialogData = {
    id: selectedTransition?.id,
    direction: selectedTransition?.direction,
    iluCode: selectedTransition?.iluCode ?? '',
    transitionDateTime: selectedTransition?.transitionDateTime ?? '',
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.gateIn.labels.dashboard.pendingContainer')}
      </Typography>
      {medlogBoard && (
        <Paper elevation={0} className={classes.paper}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" color="primary">
                {t('pages.gateIn.headers.medlogBoard')}
              </Typography>
            </Grid>
            <Grid item className={classes.medlogBoardButton}>
              <input
                accept=".csv"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={onUpload}
              />
              <label htmlFor="contained-button-file">
                <ConardUploadButton
                  conardVariant="transparent"
                  startIcon={<ContentPasteGoIcon />}
                  disabled={!medlogBoard}
                >
                  {t('pages.gateIn.buttons.uploadMedlogBoard')}
                </ConardUploadButton>
              </label>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3} className={classes.table}>
        <Grid container>
          <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
            <div className={classes.filterButton}>
              <ConardButton
                conardVariant="transparent"
                startIcon={<FilterListIcon />}
                endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                onClick={() => setShowFilter(!showFilter)}
              >
                {t('any.buttons.filter')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
            <div className={classes.filterButton}>
              <ConardButton
                conardVariant="transparent"
                startIcon={<GetAppIcon />}
                onClick={() => downloadExport('CZECH')}
              >
                {englishExcelExport ? t('any.buttons.exportCzech') : t('any.buttons.export')}
              </ConardButton>
            </div>
          </Grid>
          {englishExcelExport && (
            <Grid item xl={2} lg={3} xs={6}>
              <div className={classes.filterButton}>
                <ConardButton
                  conardVariant="transparent"
                  startIcon={<GetAppIcon />}
                  onClick={() => downloadExport('ENGLISH')}
                >
                  {t('any.buttons.exportEnglish')}
                </ConardButton>
              </div>
            </Grid>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item xl={8} lg={6}></Grid>
        </Hidden>
        {showFilter && <GateInTransitionPendingFilter searchFilter={searchFiltered} blockSearch={blockSearch} />}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell?.id} sortDirection={orderBy === headCell?.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell?.id}
                          direction={orderBy === headCell?.id ? order : 'asc'}
                          onClick={handleSort(headCell?.id ?? '')}
                        >
                          {headCell?.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell colSpan={2} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTransitions?.content?.map((transition) => (
                    <TableRow key={transition.id}>
                      {hasDriverArrivalSemaphore && (
                        <TableCell component="td" scope="row">
                          {!!transition.driverPass && (
                            <div className={`${chooseSemaphoreColor(transition.driverPass)} ${classes.circle}`}>
                              {!!transition.instantGreenPass && (
                                <ConardTooltip title={t('pages.gateIn.instantGreenPass.title')}>
                                  <Spellcheck fontSize="large" className={classes.instantGreenPass} />
                                </ConardTooltip>
                              )}
                            </div>
                          )}
                        </TableCell>
                      )}

                      <TableCell component="td" scope="row">
                        {transition.note ? (
                          <ConardTooltip title={transition.note}>
                            <StickyNote2Outlined className={classes.icon} />
                          </ConardTooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.iluCode}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        className={transition.customerName ? classes.temporaryCustomer : ''}
                      >
                        {transition.customerName ? (
                          <ConardTooltip title={t('pages.gateIn.form.temporaryCustomer.tooltip')}>
                            {transition.customerName}
                          </ConardTooltip>
                        ) : (
                          transition.companyName
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.shipownerName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.driverName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.truckLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.semitrailerLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.repleted
                          ? t('pages.gateIn.form.repleted.checked')
                          : t('pages.gateIn.form.repleted.unchecked')}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.transitionDateTime
                          ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {renderCreatedIcon(transition)}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <ConardTransitionSearchIcon
                          transitionSearchListDto={{
                            containerType: transition.containerType,
                            semitrailerType: transition.semitrailerType,
                            shippingContainerType: transition.shippingContainerType,
                          }}
                          damaged={transition.damaged ?? false}
                          containerType={transition.containerType}
                        />
                      </TableCell>
                      <TableCell>
                        <ConardButton
                          conardVariant="light"
                          className={classes.detailButton}
                          onClick={() => onDetail(transition.id)}
                        >
                          {t('any.buttons.detail')}
                        </ConardButton>
                      </TableCell>
                      <TableCell>
                        <ConardButton
                          conardVariant="red"
                          className={classes.detailButton}
                          onClick={() => {
                            setSelectedTransition(transition)
                            setShowDeleteDialog(true)
                          }}
                        >
                          {t('any.buttons.delete')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {pageTransitions?.content && pageTransitions?.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={10}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTransitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <TransitionDeleteDialog
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        data={transitionDeleteDialogData}
        onDelete={onDelete}
      />
      <MedlogBoardDialog showDialog={showBoardDialog} setShowDialog={setShowBoardDialog} success={successBoardImport} />
    </>
  )
}

export default GateInTransitionPendingListPage
