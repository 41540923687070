import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { PageTrainDto, TrainDto, TrainDtoTrainStateEnum, TrainSearchDto } from '../../api'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import trainService from '../../services/TrainService'
import { cleanObject } from '../../utils/utils'
import ConardHide from '../ConardHide'
import ConardButton from '../ConardButton'
import AddIcon from '@material-ui/icons/Add'
import { setInitialTrain, setSelectedUnseatedTransition } from '../../redux/train/trainSlice'
import { useAppDispatch } from '../../redux/store'
import ConardProgressBar from '../ConardProgressBar'
import { format } from 'date-fns'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
  addButton: {
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    textAlign: 'center',
    margin: '20px',
    float: 'right',
  },
  detailButton: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof TrainDto
  label: string
}

const GateInTrainPrepareList: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const dispatch = useAppDispatch()

  const [pageTrains, setPageTrains] = useState<PageTrainDto | null>()
  const [searchDto, setSearchDto] = useState<TrainSearchDto | undefined>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof TrainDto>('departureDateTime')

  const headCells: HeadCell[] = [
    { id: 'name', label: t('pages.train.labels.trainName') },
    { id: 'trainState', label: t('pages.train.labels.trainState') },
    {
      id: 'departureDateTime',
      label: t('pages.train.labels.trainPlannedArrivalTimeInBrackets'),
    },
    { id: 'realDepartureDateTime', label: t('pages.train.labels.realArrivalDate') },
  ]

  useEffect(() => {
    search(searchDto)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof TrainDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const search = (data: TrainSearchDto | undefined) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    trainService
      .search(
        page,
        pageSize,
        currentSort,
        cleanObject({
          ...data,
          requiredTrainStates: [
            TrainDtoTrainStateEnum.IncomingPlanned,
            TrainDtoTrainStateEnum.IncomingArrivalCustoms,
            TrainDtoTrainStateEnum.IncomingAccepted,
          ],
        })
      )
      .then((response) => {
        setPageTrains(response.data)
      })
      .catch()
  }

  const add = () => {
    dispatch(setInitialTrain())
    dispatch(setSelectedUnseatedTransition(null))

    history.push('/gate-in/train/create')
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.gateIn.headers.trainPreparingList')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.addButton}>
            <ConardButton conardVariant="light" startIcon={<AddIcon />} onClick={add}>
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={pageTrains === undefined}>
                    {pageTrains?.content?.map((train) => (
                      <TableRow key={train.id}>
                        <TableCell component="th" scope="row">
                          {train.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t(`enum.trainState.${train.trainState}`)}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {train.departureDateTime ? format(new Date(train.departureDateTime), 'dd.MM.yyyy HH:mm') : ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {train.realDepartureDateTime
                            ? format(new Date(train.realDepartureDateTime), 'dd.MM.yyyy HH:mm')
                            : ''}
                        </TableCell>
                        <TableCell className={classes.detailButton}>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => history.push('/gate-in/train/' + train.id)}
                          >
                            {t('any.buttons.detail')}
                          </ConardButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  <ConardHide condition={!pageTrains?.content || pageTrains?.totalElements !== 0}>
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  </ConardHide>
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTrains} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTrains?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.addButton}>
            <ConardButton conardVariant="light" startIcon={<AddIcon />} onClick={add}>
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default GateInTrainPrepareList
