import React, { FC, Fragment } from 'react'
import { createStyles, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import userService from '../../services/UserService'
import { useConardAuth } from '../../hooks/useConardAuth'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import { hardRefreshToken } from '../../config/axios/axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 650,
      margin: '3rem 20px',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    item: {
      display: 'flex',
      width: '100%',
      height: '70px',
      alignItems: 'center',
      justifyContent: 'center',
      '& :hover': {
        cursor: 'pointer',
      },
    },
    selectedItem: {
      backgroundColor: theme.palette.primary.dark,
    },
    flag: {
      marginRight: '10px',
    },
    divider: {
      width: '100%',
    },
  })
)

interface LanguageItem {
  language: string
  countryCode: string
  title: string
}

interface LanguageMenuProps {
  handleCloseMenu: () => void
}

export const LanguageMenu: FC<LanguageMenuProps> = ({ handleCloseMenu }) => {
  const classes = useStyles()
  const auth = useConardAuth()
  const { t } = useTranslation()

  const handleChangeLanguage = (language: string) => {
    userService.changeLanguage(auth.getUserId(), language).then(() => {
      hardRefreshToken()
      handleCloseMenu()
    })
  }

  const LanguageMenu: LanguageItem[] = [
    {
      language: 'cs',
      countryCode: 'CZ',
      title: 'Čeština',
    },
    {
      language: 'en',
      countryCode: 'GB',
      title: 'English',
    },
  ]

  return (
    <Grid container className={classes.container}>
      <Typography color="secondary" className={classes.title}>
        {t('any.language.chooseLanguage')}
      </Typography>
      <Divider light className={classes.divider} />

      {LanguageMenu.map((item) => (
        <Fragment key={item.language}>
          <Grid
            item
            className={`${classes.item} ${auth.getLanguage() === item.language ? classes.selectedItem : ''}`}
            onClick={() => handleChangeLanguage(item.language)}
          >
            <ReactCountryFlag countryCode={item.countryCode} svg className={classes.flag} />
            <Typography color="secondary">{item.title}</Typography>
          </Grid>
          <Divider light className={classes.divider} />
        </Fragment>
      ))}
    </Grid>
  )
}
