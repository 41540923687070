import React, { FC, useContext, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { AuditLogDto, AuditLogDtoAuditLogEventTypeEnum, AuditLogSearchDto } from '../../api'
import { Controller, useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DatePicker } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '95%',
    },
    searchButton: {
      width: '15vw',
      minWidth: '200px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main + '',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface AuditFilterProps {
  searchFilter: (data: AuditLogSearchDto) => void
}

const AuditFilter: FC<AuditFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const configContext = useContext(ConfigContext)

  const { searchFilter } = props

  const { handleSubmit, control, setValue } = useForm<AuditLogDto>({})

  const [createdAtFrom, setCreatedAtFrom] = useState<MaterialUiPickersDate | null>(null)
  const [createdAtTo, setCreatedAtTo] = useState<MaterialUiPickersDate | null>(null)

  const filterAuditLogs = (logType: AuditLogDtoAuditLogEventTypeEnum) => {
    if (!configContext.entryAndParking) {
      return !new Set([
        AuditLogDtoAuditLogEventTypeEnum.SemitrailerBefore1StCheckpointGo,
        AuditLogDtoAuditLogEventTypeEnum.SemitrailerBefore2NdCheckpointGo,
        AuditLogDtoAuditLogEventTypeEnum.SemitrailerBefore3RdCheckpointGo,
        AuditLogDtoAuditLogEventTypeEnum.ShippingContainerBefore1StCheckpointGo,
        AuditLogDtoAuditLogEventTypeEnum.ShippingContainerBefore2NdCheckpointGo,
        AuditLogDtoAuditLogEventTypeEnum.ShippingContainerBefore3RdCheckpointGo,
      ]).has(logType)
    }
    return true
  }

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setCreatedAtFrom(date || new Date())
    setValue('createdAtFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setCreatedAtTo(date || new Date())
    setValue('createdAtTo', date)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <ConardSelect
            label={t('pages.history.header.auditLogEventType')}
            name="auditLogEventType"
            defaultValue="NOT-SELECTED"
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {Object.values(AuditLogDtoAuditLogEventTypeEnum)
              .filter(filterAuditLogs)
              .map((auditLogEventType) => (
                <MenuItem key={auditLogEventType} value={auditLogEventType}>
                  {t('enum.auditLogEventType.' + auditLogEventType)}
                </MenuItem>
              ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <ConardTextField
            id="username"
            name="username"
            label={t('pages.history.header.createdBy')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Controller
            name="createdAtFrom"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
                format="dd.MM.yyyy"
                value={createdAtFrom}
                onChange={dateFromOnChange}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <Controller
            name="createdAtTo"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
                format="dd.MM.yyyy"
                value={createdAtTo}
                onChange={(value) => {
                  dateToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default AuditFilter
