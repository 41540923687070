import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import companyService from '../../services/CompanyService'
import { AsparkingTicketSearchDto, CompanyDto } from '../../api'
import { DatePicker } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '95%',
    },
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface TicketsFilterProps {
  ticketsFilter: (data: AsparkingTicketSearchDto) => void
}

const TicketFilter: FC<TicketsFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { ticketsFilter } = props

  const { handleSubmit, control, setValue } = useForm<AsparkingTicketSearchDto>({})

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [arriveAtFrom, setArriveAtFrom] = useState<MaterialUiPickersDate | null>(null)
  const [arriveAtTo, setArriveAtTo] = useState<MaterialUiPickersDate | null>(null)
  const [leaveAtFrom, setLeaveAtFrom] = useState<MaterialUiPickersDate | null>(null)
  const [leaveAtTo, setLeaveAtTo] = useState<MaterialUiPickersDate | null>(null)

  const dateOnChangeSwitch = (date: MaterialUiPickersDate | null, value: string) => {
    switch (value) {
      case 'arrivedAtFrom':
        setArriveAtFrom(date || new Date())
        setValue('arrivedAtFrom', date)
        break
      case 'arrivedAtTo':
        setArriveAtTo(date || new Date())
        setValue('arrivedAtTo', date)
        break
      case 'leaveAtFrom':
        setLeaveAtFrom(date || new Date())
        setValue('leaveAtFrom', date)
        break
      case 'leaveAtTo':
        setLeaveAtTo(date || new Date())
        setValue('leaveAtTo', date)
        break
    }
  }

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className={classes.form} onSubmit={handleSubmit(ticketsFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="licencePlate"
            name="licencePlate"
            label={t('entity.ticket.fields.licencePlate')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField id="barcode" name="barcode" label={t('entity.ticket.fields.barcode')} control={control} />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect id="companyId" name="companyId" label={t('entity.ticket.fields.company')} control={control}>
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {companies?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="departurePermission"
            name="departurePermission"
            label={t('entity.ticket.fields.departurePermission')}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
            <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={4} lg={4} />
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="arrivedAtFrom"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.ticket.fields.arrivedAt') + ' ' + t('any.from')}
                format="dd.MM.yyyy"
                value={arriveAtFrom}
                onChange={(value) => {
                  dateOnChangeSwitch(value, 'arrivedAtFrom')
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="arrivedAtTo"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.ticket.fields.arrivedAt') + ' ' + t('any.to')}
                format="dd.MM.yyyy"
                value={arriveAtTo}
                onChange={(value) => {
                  dateOnChangeSwitch(value, 'arrivedAtTo')
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="leaveAtFrom"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.ticket.fields.leaveAt') + ' ' + t('any.from')}
                format="dd.MM.yyyy"
                value={leaveAtFrom}
                onChange={(value) => {
                  dateOnChangeSwitch(value, 'leaveAtFrom')
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="leaveAtTo"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.ticket.fields.leaveAt') + ' ' + t('any.to')}
                format="dd.MM.yyyy"
                value={leaveAtTo}
                onChange={(value) => {
                  dateOnChangeSwitch(value, 'leaveAtTo')
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={4} lg={4} />
        <Grid item xl={6} lg={6} />
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default TicketFilter
