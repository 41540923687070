import React, { FC, useContext } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { UserSearchDto } from '../../api'
import { useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import { UsersMosnov } from '../../enums/UsersMosnov'
import { UsersBrno } from '../../enums/UsersBrno'
import { ConfigContext } from '../../context/ConfigContext'

interface UserFilterProps {
  usersFilter: (data: UserSearchDto) => void
}

const UserFilter: FC<UserFilterProps> = (props) => {
  const { t } = useTranslation()

  const { usersFilter } = props
  const { usersMosnov } = useContext(ConfigContext)
  const defaultUserSearchDto: UserSearchDto = {
    username: '',
    firstName: '',
    surname: '',
    email: '',
    role: undefined,
  }

  const { handleSubmit, control } = useForm<UserSearchDto>({
    defaultValues: defaultUserSearchDto,
  })

  return (
    <form onSubmit={handleSubmit(usersFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="username"
            name="username"
            type="text"
            label={t('pages.userManagement.header.username')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="firstName"
            name="firstName"
            type="text"
            label={t('pages.userManagement.header.firstName')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="surname"
            name="surname"
            type="text"
            label={t('pages.userManagement.header.surname')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="email"
            name="email"
            type="text"
            label={t('pages.userManagement.header.email')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <ConardSelect label="Role" name="role" defaultValue="NOT-SELECTED" control={control}>
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>

            {usersMosnov &&
              Object.values(UsersMosnov).map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`enum.role.${value}`)}
                </MenuItem>
              ))}

            {!usersMosnov &&
              Object.values(UsersBrno).map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`enum.role.${value}`)}
                </MenuItem>
              ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default UserFilter
