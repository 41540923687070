import React, { FC, useEffect, useState } from 'react'
import { TrainDto } from '../../api'
import { useParams } from 'react-router-dom'
import { createStyles } from '@material-ui/core'
import ConardHide from '../../components/ConardHide'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import trainService from '../../services/TrainService'
import ConardTrainForm from '../../components/forms/ConardTrainForm'
import { TrainMode } from '../../enums/TrainMode'
import { setInitialTrain, setTrain } from '../../redux/train/trainSlice'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface UrlParamTypes {
  id?: string
}

const TrainSearchDetailPage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const classes = useStyles()
  const { t } = useTranslation()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const dispatch = useAppDispatch()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    dispatch(setInitialTrain())

    if (id !== undefined) {
      trainService
        .findById(Number.parseInt(id))
        .then((response) => {
          dispatch(setTrain(response.data))
        })
        .catch()
        .finally(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <>
          <ConardHide condition={train.id !== undefined}>
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          </ConardHide>
          <ConardHide condition={train.id === undefined}>
            <ConardTrainForm mode={TrainMode.SEARCH} />
          </ConardHide>
        </>
      )}
    </>
  )
}

export default TrainSearchDetailPage
