import React, { FC } from 'react'
import { Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { AsparkingCardSearchDto } from '../../api'
import { useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'

interface CardFilterProps {
  cardsFilter: (data: AsparkingCardSearchDto) => void
}

const CardFilter: FC<CardFilterProps> = (props) => {
  const { t } = useTranslation()

  const { cardsFilter } = props

  const { handleSubmit, control } = useForm<AsparkingCardSearchDto>({})

  return (
    <form onSubmit={handleSubmit(cardsFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="cardNumber"
            name="cardNumber"
            type="number"
            label={t('pages.card.header.cardNumber')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="licensePlate"
            name="licensePlate"
            label={t('pages.card.header.licensePlate')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="firstName"
            name="firstName"
            label={t('entity.licensePlate.fields.firstName')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="surname"
            name="surname"
            label={t('entity.licensePlate.fields.surname')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect id="disabled" name="disabled" label={t('pages.card.header.state')} control={control}>
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.card.table.disabled')}</MenuItem>
            <MenuItem value="false">{t('pages.card.table.active')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} />
        <Grid item xl={8} lg={8} />
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default CardFilter
