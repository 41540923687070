import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PageProcessedFileDto, ProcessedFileDto, ProcessedFileSearchDto } from '../../api'
import { cleanObject } from '../../utils/utils'
import ConardHide from '../../components/ConardHide'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import processedFileService from '../../services/ProcessedFileService'
import GetAppIcon from '@material-ui/icons/GetApp'
import ConardProgressBar from '../../components/ConardProgressBar'
import ProcessedFileSearchFilter from './ProcessedFilesSearchFilter'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
  detailButton: {
    width: '7vw',
  },
  downloadLabel: {
    textAlign: 'center',
  },
})

interface HeadCell {
  id: string
  label: string
}

const ProcessedFileSearchListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [pageProcessedFiles, setPageProcessedFiles] = useState<PageProcessedFileDto | null>()
  const [processedFiles, setProcessedFiles] = useState<ProcessedFileSearchDto | undefined>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('processed')

  const headCells: HeadCell[] = [
    { id: 'processedFileType', label: t('pages.searchProcessedFiles.form.processedFileType') },
    { id: 'fileName', label: t('pages.searchProcessedFiles.form.fileName') },
    { id: 'received', label: t('pages.searchProcessedFiles.form.received') },
    { id: 'processed', label: t('pages.searchProcessedFiles.form.processed') },
  ]

  useEffect(() => {
    search(processedFiles, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: ProcessedFileSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: ProcessedFileSearchDto | undefined, fromFilter: boolean) => {
    setProcessedFiles(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
      // currentSort = [orderBy + ",container.iluCode" + "," + currentOrder ];
    }

    processedFileService
      .search(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageProcessedFiles(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  const handleDownload = (processedFile: ProcessedFileDto) => {
    processedFileService
      .download(processedFile.contentId!)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = processedFile.fileName ?? ''
        a.click()
      })
      .catch()
  }

  const getLocaleDateTime = (timeStamp?: string) => {
    if (!timeStamp) {
      return
    }
    const date = new Date(timeStamp)

    const localeDate = date.toLocaleDateString('cs')
    const localeTime = date.toLocaleTimeString('cs')

    return `${localeDate} ${localeTime}`
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.searchProcessedFiles.title')}
      </Typography>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <ProcessedFileSearchFilter searchFilter={searchFiltered} />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell className={classes.downloadLabel}>
                      <label id="contentId">{t('pages.searchProcessedFiles.form.contentId')}</label>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={pageProcessedFiles === undefined}>
                    {pageProcessedFiles?.content?.map((processedFile) => (
                      <TableRow key={processedFile.id}>
                        <TableCell component="th" scope="row">
                          {t('enum.processedFileType.' + processedFile.processedFileType)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {processedFile.fileName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getLocaleDateTime(processedFile.received)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getLocaleDateTime(processedFile.processed)}
                        </TableCell>
                        <TableCell className={classes.detailButton}>
                          {processedFile.contentId && (
                            <ConardButton
                              startIcon={<GetAppIcon />}
                              conardVariant="light"
                              onClick={() => handleDownload(processedFile)}
                            >
                              {t('pages.searchProcessedFiles.form.contentId')}
                            </ConardButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  <ConardHide condition={!pageProcessedFiles?.content || pageProcessedFiles?.totalElements !== 0}>
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={5}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  </ConardHide>
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageProcessedFiles} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageProcessedFiles?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default ProcessedFileSearchListPage
