import React, { FC } from 'react'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import ConardSelect from '../../components/ConardSelect'
import { TrainDtoTrainStateEnum, TrainSearchDto, TransitionDtoDirectionEnum } from '../../api'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ConardHide from '../../components/ConardHide'

const useStyles = makeStyles({
  form: {
    width: '95%',
  },
  searchButton: {
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    textAlign: 'center',
    margin: '20px',
    float: 'right',
  },
})

interface SearchFilterProps {
  searchFilter: (data: TrainSearchDto) => void
  hideFilterState?: boolean
}

const TrainSearchFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { searchFilter, hideFilterState = false } = props

  const { handleSubmit, control } = useForm<TrainSearchDto>({})

  return (
    <form className={classes.form} onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            label={t('pages.gateIn.form.direction.label')}
            name="direction"
            defaultValue="NOT-SELECTED"
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value={TransitionDtoDirectionEnum.In}>{t('pages.gateIn.form.direction.unchecked')}</MenuItem>
            <MenuItem value={TransitionDtoDirectionEnum.Out}>{t('pages.gateIn.form.direction.checked')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField id="name" name="name" label={t('pages.train.labels.trainName')} control={control} />
        </Grid>
        <ConardHide condition={hideFilterState}>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardSelect
              label={t('pages.gateIn.form.state.label')}
              name="requiredTrainStates[0]"
              defaultValue="NOT-SELECTED"
              control={control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              {Object.values(TrainDtoTrainStateEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`enum.trainState.${value}`)}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
        </ConardHide>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default TrainSearchFilter
