import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { CoparnDto, CoparnTruckDto, PageCoparnTruckDto } from '../../api'
import coparnService from '../../services/CoparnService'
import { Order } from '../../enums/Order'
import ConardHide from '../../components/ConardHide'
import EmptyCoparnDeleteDialog from '../../components/dialogs/EmptyCoparnDeleteDialog'
import ConardProgressBar from '../../components/ConardProgressBar'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles({
  typography: {
    width: '60vw',
  },
  container: {
    marginTop: '8vh',
    marginBottom: '10vh',
  },
  buttonCell: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  button: {
    width: '15vw',
    minWidth: '200px',
    right: 0,
  },
})

interface HeadCell {
  id: keyof CoparnDto | keyof CoparnTruckDto
  label: string
}

const GateOutCoparnEmptyDeletePage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const { generalCarrierReference } = useContext(ConfigContext)

  const [coparnTrucks, setCoparnTrucks] = useState<PageCoparnTruckDto | null>(null)
  const [coparnTruckDeleteId, setCoparnTruckDeleteId] = useState<number | undefined>(undefined)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof CoparnDto | keyof CoparnTruckDto>('medlogReference')

  const headCells: HeadCell[] = [
    {
      id: 'medlogReference',
      label: generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef'),
    },
    { id: 'licensePlateFront', label: t('pages.gateOut.coparn.delete.table.licensePlateFront') },
    { id: 'licensePlateRear', label: t('pages.gateOut.coparn.delete.table.licensePlateRear') },
    { id: 'carrier', label: t('pages.gateIn.form.carrier.label') },
    { id: 'driver', label: t('pages.gateIn.form.driver.label') },
  ]

  useEffect(() => {
    findAllUnassignedCoparnTrucks()
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof CoparnDto | keyof CoparnTruckDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const findAllUnassignedCoparnTrucks = () => {
    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      const currentOrderBy: string = orderBy === 'medlogReference' ? 'coparn.medlogReference' : orderBy
      currentSort = [currentOrderBy + ',' + currentOrder]
    }

    coparnService
      .coparnFindAllUnassignedCoparnTrucks(page, pageSize, currentSort)
      .then((response) => {
        setCoparnTrucks(response.data)
      })
      .catch()
  }

  const handleDeleteDialog = (id: number | undefined) => {
    setCoparnTruckDeleteId(id)
    setDeleteDialog(true)
  }

  const handleCloseDialog = () => {
    setCoparnTruckDeleteId(undefined)
    setDeleteDialog(false)
  }

  const handleDelete = () => {
    if (coparnTruckDeleteId) {
      coparnService
        .coparnDeleteCoparnTruck(coparnTruckDeleteId)
        .then(() => {
          history.push('/success/coparn-empty/delete')
        })
        .catch()
    }
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.gateOut.coparn.delete.title')}
      </Typography>
      <Grid
        container
        className={classes.container}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ConardHide condition={coparnTrucks === undefined}>
                    {coparnTrucks?.content?.map((coparnTruck) => (
                      <TableRow key={coparnTruck.id}>
                        <TableCell component="th" scope="row">
                          {coparnTruck?.coparn?.medlogReference}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparnTruck?.frontLicensePlate?.licensePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparnTruck?.rearLicensePlate?.licensePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparnTruck?.carrierShort?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparnTruck?.driverShort?.name}
                        </TableCell>
                        <TableCell className={classes.buttonCell} component="th" scope="row">
                          <div className={classes.button}>
                            <ConardButton conardVariant="dark" onClick={() => handleDeleteDialog(coparnTruck?.id)}>
                              {t('any.buttons.delete')}
                            </ConardButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </ConardHide>
                  {coparnTrucks?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={10}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!coparnTrucks} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={coparnTrucks?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <EmptyCoparnDeleteDialog showRemoveDialog={deleteDialog} onClose={handleCloseDialog} onRemove={handleDelete} />
    </>
  )
}
export default GateOutCoparnEmptyDeletePage
