import { Box, Grid, createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import {
  DriverArrivalDto,
  DriverArrivalDtoDriverPassGateInEnum,
  DriverArrivalDtoDriverPassGateOutEnum,
} from '../../../../api'
import { TransitionCardFirstRow } from './TransitionCardFirstRow'
import { TransitionCardSecondRow } from './TransitionCardSecondRow'
import { GateOutInfoBox } from '../GateOutInfoBox'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      gap: '20px',
      justifyContent: 'center',
    },
    gateInOutGridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    gateInOutGridItemCarrier: {
      width: '305px',
      [theme.breakpoints.up('xl')]: {
        width: '350px',
      },
    },
    gateInOutGridItemDriver: {
      width: '100%',
    },
    boxDriver: {
      display: 'flex',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: 12,
      height: '140px',
      width: '100%',
      boxShadow: '0 5px 10px lightGray',
      zIndex: 1,
    },
    boxCarrier: {
      display: 'flex',
      backgroundColor: 'white',
      border: '1px solid black',
      padding: '10px',
      borderRadius: 12,
      height: '120px',
      zIndex: 1,
    },
    disabledBox: {
      backgroundColor: '#d4d4d4',
      pointerEvents: 'none',
    },
    gateInOutWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    clickableBox: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.info.main,
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
        borderWidth: '2px',
      },
    },
    green: {
      border: '2px solid green',
    },
    orange: {
      border: '2px solid orange',
    },
    red: {
      border: '2px solid red',
    },
    gateOutInfoBox: {
      border: '1px solid grey',
      width: '90%',
      minHeight: '70px',
      borderRadius: 12,
      backgroundColor: 'white',
      boxShadow: '0 2px 10px lightGray',
      marginTop: '-25px',
      marginBottom: '20px',
      padding: '20px 10px 10px 10px',
      zIndex: 0,
    },
  })
)

interface TransitionCardProps {
  disabled: boolean
  data: DriverArrivalDto | undefined
  isCarrier: boolean

  onGateInNoCargo: () => void
  onGateOutNoCargo: () => void
  onGateIn: () => void
  onGateOUt: () => void
  onEditGateIn: () => void
  onEditGateOut: () => void
}

export const TransitionCard: FC<TransitionCardProps> = ({
  onGateInNoCargo,
  onGateOutNoCargo,
  disabled,
  onGateIn,
  onGateOUt,
  data,
  onEditGateIn,
  onEditGateOut,
  isCarrier,
}) => {
  const classes = useStyles()

  const canClickGI = data?.plannedTransition

  const canClickGO = data?.reference || data?.iluCode || data?.semitrailerLicencePlate

  const getSemaphoreGateIn = () => {
    if (data?.driverPassGateIn === DriverArrivalDtoDriverPassGateInEnum.Green) {
      return classes.green
    } else if (data?.driverPassGateIn === DriverArrivalDtoDriverPassGateInEnum.Orange) {
      return classes.orange
    } else if (data?.driverPassGateIn === DriverArrivalDtoDriverPassGateInEnum.Red) {
      return classes.red
    }
  }

  const getSemaphoreGateOut = () => {
    if (data?.driverPassGateOut === DriverArrivalDtoDriverPassGateOutEnum.Green) {
      return classes.green
    } else if (data?.driverPassGateOut === DriverArrivalDtoDriverPassGateOutEnum.Orange) {
      return classes.orange
    } else if (data?.driverPassGateOut === DriverArrivalDtoDriverPassGateOutEnum.Red) {
      return classes.red
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        className={`${classes.gateInOutGridItem} ${
          isCarrier ? classes.gateInOutGridItemCarrier : classes.gateInOutGridItemDriver
        }`}
      >
        <Box
          className={`${isCarrier ? classes.boxCarrier : classes.boxDriver} ${getSemaphoreGateIn()} ${
            data?.withoutGateIn ? classes.disabledBox : ''
          } ${canClickGI ? classes.clickableBox : ''} ${classes.gateInOutWrapper}`}
          onClick={canClickGI ? onEditGateIn : undefined}
        >
          <TransitionCardFirstRow
            isCarrier={isCarrier}
            isGateIn
            withoutGateIn={data?.withoutGateIn ?? false}
            withoutGateOut={data?.withoutGateOut ?? false}
            driverPassGateIn={data?.driverPassGateIn}
            driverPassGateOut={undefined}
          />
          <TransitionCardSecondRow
            isCarrier={isCarrier}
            plannedTransition={data?.plannedTransition ?? undefined}
            disabled={disabled}
            onAdd={onGateIn}
            onNoCargo={onGateInNoCargo}
            withoutGateInOut={data?.withoutGateIn ?? false}
            bookingReference={data?.plannedTransition?.bookingReference ?? ''}
            ilucode={data?.plannedTransition?.container.iluCode ?? ''}
            medlogReference={data?.plannedTransition?.transitionAdditionalInfo?.medlogReference ?? ''}
            driverArrivalDto={undefined}
            repleted={data?.plannedTransition?.repleted ?? false}
            isGateOut={false}
            sectorColumn={undefined}
            sectorLetter={undefined}
          />
        </Box>
      </Grid>

      <Grid
        item
        className={`${classes.gateInOutGridItem} ${
          isCarrier ? classes.gateInOutGridItemCarrier : classes.gateInOutGridItemDriver
        }`}
      >
        <Box
          className={`${isCarrier ? classes.boxCarrier : classes.boxDriver}  ${
            data?.withoutGateOut ? classes.disabledBox : ''
          } ${canClickGO ? classes.clickableBox : ''} ${getSemaphoreGateOut()} ${classes.gateInOutWrapper}`}
          onClick={canClickGO ? onEditGateOut : undefined}
        >
          <TransitionCardFirstRow
            isCarrier={isCarrier}
            isGateIn={false}
            withoutGateIn={data?.withoutGateIn ?? false}
            withoutGateOut={data?.withoutGateOut ?? false}
            driverPassGateOut={data?.driverPassGateOut}
            driverPassGateIn={undefined}
          />

          <TransitionCardSecondRow
            isCarrier={isCarrier}
            bookingReference={data?.reference ?? ''}
            disabled={disabled}
            ilucode={data?.iluCode ?? ''}
            medlogReference={data?.reference ?? ''}
            onAdd={onGateOUt}
            onNoCargo={onGateOutNoCargo}
            driverArrivalDto={data}
            plannedTransition={undefined}
            repleted={data?.repleted ?? false}
            withoutGateInOut={data?.withoutGateOut ?? false}
            semitrailerLicencePlate={data?.semitrailerLicencePlate ?? ''}
            isGateOut
            sectorColumn={data?.candidateForGateOutTransition?.sectorColumn}
            sectorLetter={data?.candidateForGateOutTransition?.sectorLetter}
          />
        </Box>
        {data?.driverPassCauseGateOut && data?.driverPassGateOut !== DriverArrivalDtoDriverPassGateOutEnum.Green && (
          <Box className={classes.gateOutInfoBox}>
            <GateOutInfoBox
              driverPassCauseGateOut={data.driverPassCauseGateOut}
              incomingTrainPlannedTime={data.incomingTrainPlannedTime}
              unloadingDateTime={data.unloadingDateTime}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
