import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import {
  CoparnDtoUniqueCoparnContainerQualityEnum,
  CoparnDtoUniqueShippingContainerTypeEnum,
  ShipownerDto,
} from '../../api'
import { RHFTextField } from '../../components/shared/rhf/RHFTextField'
import { ConardLabel } from '../../components/transition/DriverFields'
import ConardSelect from '../../components/ConardSelect'
import shipownerService from '../../services/ShipownerService'
import { cleanShippingContainerType } from '../../utils/utils'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import CoparnService from '../../services/CoparnService'
import { yupResolver } from '@hookform/resolvers/yup'
import { GateOutCoparnCreateValidationScheme } from './GateOutCoparnCreateValidationScheme'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: '10vh',
      width: '100%',
      minWidth: '300px',
      margin: 'auto',
      maxWidth: '950px',
    },
    button: {
      borderRadius: 12,
      height: '3rem',
      fontWeight: 600,
      marginTop: '1rem',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    backButton: {
      borderRadius: 12,
      height: '3rem',
      fontWeight: 600,
      marginTop: '1rem',
    },
    buttonsWrapper: {
      paddingTop: '50px',
      maxWidth: '600px',
      margin: 'auto',
    },
  })
)

type CreateCoparnValues = {
  medlogReference: string
  shipowner: {
    id: number
    name: string
  }
  uniqueCoparnContainerQuality: CoparnDtoUniqueCoparnContainerQualityEnum | undefined
  uniqueShippingContainerType: CoparnDtoUniqueShippingContainerTypeEnum | undefined
  numberOfContainers: number | undefined
}

const initialValues: CreateCoparnValues = {
  medlogReference: '',
  shipowner: {
    id: 0,
    name: '',
  },
  uniqueCoparnContainerQuality: undefined,
  uniqueShippingContainerType: undefined,
  numberOfContainers: undefined,
}

export const GateOutCoparnCreatePage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [shipowners, setShipowners] = useState<ShipownerDto[]>([])

  const { generalCarrierReference } = useContext(ConfigContext)

  const formMethods = useForm<CreateCoparnValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(GateOutCoparnCreateValidationScheme()),
  })

  const getShipowners = () => {
    shipownerService.findAll(0, 100, undefined).then((response) => {
      setShipowners(response.data.content ?? [])
    })
  }

  const onSubmit = async (formValue: CreateCoparnValues) => {
    await CoparnService.coparnCreate(formValue)
    history.push(`/gate-out/coparn`)
  }

  useEffect(() => {
    getShipowners()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.coparnCreate.header')}
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={2} className={classes.container} alignItems="center">
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>
                {generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef')}
              </ConardLabel>
              <RHFTextField
                id="medlogReference"
                name="medlogReference"
                label=""
                error={!!formMethods.errors.medlogReference}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.coparnCreate.label.shipowner')}</ConardLabel>
              <ConardSelect
                roundedSquare
                id="shipowner.id"
                name="shipowner.id"
                label=""
                control={formMethods.control}
                error={!!formMethods.errors.shipowner}
                helperText={formMethods.errors.shipowner ? formMethods.errors.shipowner.id?.message : ''}
              >
                {shipowners.map((shipowner) => (
                  <MenuItem key={shipowner.id} value={shipowner.id}>
                    {shipowner.name}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.coparnCreate.label.quality')}</ConardLabel>
              <ConardSelect
                roundedSquare
                id="uniqueCoparnContainerQuality"
                name="uniqueCoparnContainerQuality"
                label=""
                control={formMethods.control}
                error={!!formMethods.errors.uniqueCoparnContainerQuality}
                helperText={
                  formMethods.errors.uniqueCoparnContainerQuality
                    ? formMethods.errors.uniqueCoparnContainerQuality?.message
                    : ''
                }
              >
                {Object.values(CoparnDtoUniqueCoparnContainerQualityEnum)
                  .filter((quality) => quality !== CoparnDtoUniqueCoparnContainerQualityEnum.All)
                  .map((quality) => (
                    <MenuItem key={quality} value={quality}>
                      {t(`enum.coparnQuality.${quality}`)}
                    </MenuItem>
                  ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.coparnCreate.label.type')}</ConardLabel>
              <ConardSelect
                roundedSquare
                id="uniqueShippingContainerType"
                name="uniqueShippingContainerType"
                label=""
                control={formMethods.control}
                error={!!formMethods.errors.uniqueShippingContainerType}
                helperText={
                  formMethods.errors.uniqueShippingContainerType
                    ? formMethods.errors.uniqueShippingContainerType?.message
                    : ''
                }
              >
                {Object.values(CoparnDtoUniqueShippingContainerTypeEnum).map((type) => (
                  <MenuItem key={type} value={type}>
                    {cleanShippingContainerType(type)}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardLabel>{t('pages.coparnCreate.label.numberOfContainers')}</ConardLabel>
              <RHFTextField
                id="numberOfContainers"
                name="numberOfContainers"
                label=""
                error={!!formMethods.errors.numberOfContainers}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={2} className={classes.buttonsWrapper}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardButton className={classes.backButton} conardVariant="transparent" onClick={() => history.goBack()}>
                {t('any.buttons.back')}
              </ConardButton>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardButton conardVariant="dark" type="submit" className={classes.button}>
                {t('any.buttons.save')}
              </ConardButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
